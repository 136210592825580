import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterState } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AlertService } from './_services/alert.service';
import { PrintService } from './_services/print.service';
import * as myGlobals from './_helpers/globals';
import { VersionUpdateService } from './_services/version-update.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TrackEventsService } from './_services/track-events.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  public direction = 'rtl';
  public selectedLang = 'ar';
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];
  public connectionStatusMessage: string;
  public isConnected: boolean = true;
  constructor(public printService: PrintService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private alertService: AlertService,
    private router: Router,
    private updateService: VersionUpdateService) {
    translate.addLangs(['en', 'ar']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      if (browserLang === 'en') {
        this.direction = 'ltr';
      }
      else {
        this.direction = 'rtl';
      }

      this.selectedLang = browserLang;
      this.document.documentElement.lang = browserLang;
      this.document.documentElement.dir = this.direction;
      translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    }
    else {
      localStorage.setItem('locale', 'ar');
      translate.setDefaultLang('ar');
      this.direction = 'rtl';
      this.selectedLang = 'ar';
      this.document.documentElement.lang = 'ar';
      this.document.documentElement.dir = 'rtl';
    }
  }

  ngOnInit(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(event => {
      this.connectionStatusMessage = 'Connected to internet! You are online';
      this.isConnected = true;
      this.alertService.success(this.connectionStatusMessage);
      myGlobals.global.internetConnection = this.isConnected;
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are offline';
      this.isConnected = false;
      this.alertService.error(this.connectionStatusMessage);
      myGlobals.global.internetConnection = this.isConnected;
    }));
    this.router.events.subscribe(event => {
      if (!this.isConnected) {
        this.alertService.error('Connection lost! You are offline');
      }
    });
    this.load_GoogleAnalyticsScript();
    this.load_GoogleMaps();
    //this.load_Itercom();
  }
  load_GoogleAnalyticsScript() {
    const script_src = document.createElement('script');
    script_src.type = 'text/javascript';
    script_src.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.firebaseConfig.measurementId;
    script_src.async = true;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = 'window.dataLayer = window.dataLayer || [];\
    function gtag() { dataLayer.push(arguments); }\
    gtag(\'js\', new Date());\
    gtag(\'config\', \''+ environment.firebaseConfig.measurementId + '\');'

    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script_src);
      document.head.appendChild(script);
    }
  }
  load_GoogleMaps() {
    const script_src = document.createElement('script');
    script_src.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.googleMap.apiKey +'&callback=Function.prototype&libraries=places';
    script_src.async = true;

    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script_src);
    }
  }
  load_Itercom() {
    //if (environment.production) { }
    const script = document.createElement('script');
    script.innerHTML = '(function () {\
    var w = window;\
    var ic = w.Intercom;\
    if (typeof ic === \'function\') {\
      ic(\'reattach_activator\');\
      ic(\'update\', w.intercomSettings);\
    } else {\
      var d = document;\
      var i = function () {\
        i.c(arguments);\
      };\
      i.q = [];\
      i.c = function (args) {\
        i.q.push(args);\
      };\
      w.Intercom = i;\
      var l = function () {\
        var s = d.createElement(\'script\');\
        s.type = \'text/javascript\';\
        s.async = true;\
        s.src = \'https://widget.intercom.io/widget/'+environment.intercom.appId+'\';\
        var x = d.getElementsByTagName(\'script\')[0];\
        x.parentNode.insertBefore(s, x);\
      };\
      if (document.readyState === \'complete\') {\
        l();\
      } else if (w.attachEvent) {\
        w.attachEvent(\'onload\', l);\
      } else {\
        w.addEventListener(\'load\', l, false);\
      }\
    }\
  })();'

    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}


