import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { PurchaseorderService } from 'src/app/_services/purchaseorder.service';
import { UnitService } from 'src/app/_services/unit.service';
import { InvLogs, poDetails, taxes } from 'src/app/_models/Purchaseorder';
import { NgbdDatepickerAdapter } from 'src/app/datepicker/datepicker-adapter';
import { ConversionCalculatorComponent } from '../../conversion-calculator/conversion-calculator.component';
import * as myGlobals from '../../../_helpers/globals';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-create-purchase-order',
  templateUrl: './create-purchase-order.component.html',
  styleUrls: ['./create-purchase-order.component.css']
})
export class CreatePurchaseOrderComponent implements OnInit {

  @Input() SelectedID = 0;
  @Input() SelectedSupplier = 0;
  @Input() SelectedLocation = 0;
  @Input() SelectedItems = [];
  submitted = false;
  conversionBoxOpen = false;
  purchaseorderForm: UntypedFormGroup;
  loading = false;
  loadingPurchaseorder = false;
  ButtonText = "Create";
  Locations = [];
  Suppliers = [];
  Items = [];
  Units = [];
  taxPercent = 15;
  arrItems: poDetails[] = [];
  arrtaxes: taxes[] = [];
  logs: InvLogs = null;
  taxTypes = null;
  selectedLang = 'en';
  @ViewChild(NgbdDatepickerAdapter, { static: true }) dateComp;
  public poDetails = new poDetails();
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private ls: LocalStorageService,
    private alertService: AlertService,
    private unitService: UnitService,
    private purchaseorderService: PurchaseorderService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService
  ) {
    this.createForm();
    this.selectedLang = this.localStorageService.getSelectedLanguage();
  }

  ngOnInit() {
    this.GetPOTaxType();
    this.setSelectedPurchaseorder();
    this.loadLocations();
    this.loadUnits();
    this.refreshDetailObj();

    if (this.f.poid.value == 0)
      this.generatePO();
    if (this.f.poid.value == 0)
      this.getTaxesForPO(this.f.brandID.value);

  }

  get f() { return this.purchaseorderForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.ls);
  }

  private createForm() {

    this.purchaseorderForm = this.formBuilder.group({
      poCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      poid: 0,
      supplierID: 0,
      subTotal: 0,
      taxAmount: 0,
      grandTotal: 0,
      expectedDate: [],
      date: [],
      locationID: 0,
      rowVersion: 0,
      poDetails: [],
      taxes: []
    });
  }

  private editForm(purchaseorder) {

    this.f.poCode.setValue(purchaseorder.poCode);
    this.f.date.setValue(purchaseorder.date);
    this.dateComp.setDate(purchaseorder.expectedDate);
    this.f.expectedDate.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day));
    this.f.referenceNo.setValue(purchaseorder.referenceNo);
    this.f.poDetails.setValue(purchaseorder.poDetails);
    this.arrItems = purchaseorder.poDetails;
    this.f.taxes.setValue(purchaseorder.taxes);
    this.arrtaxes = purchaseorder.taxes;
    //this.taxPercent = this.arrtaxes[0]["percentage"];
    this.f.subTotal.setValue(purchaseorder.subTotal);
    this.f.taxAmount.setValue(purchaseorder.taxAmount);
    this.f.grandTotal.setValue(purchaseorder.grandTotal);
    this.f.notes.setValue(purchaseorder.notes);
    this.f.rowVersion.setValue(purchaseorder.rowVersion);
    this.f.statusID.setValue(purchaseorder.statusID);
    this.f.poid.setValue(purchaseorder.poid);
    this.f.locationID.setValue(purchaseorder.locationID);
    this.f.supplierID.setValue(purchaseorder.supplierID);
    this.f.locationID.disable();
  }

  setSelectedPurchaseorder() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingPurchaseorder = true;
      this.f.poid.setValue(this.SelectedID);
      this.purchaseorderService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.CalculateSummary();
        this.getLogs(this.SelectedID);
        this.loadingPurchaseorder = false;
      });
    }
  }

  onSubmit(status) {

    this.purchaseorderForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.arrItems.length == 0) {
      this.alertService.error("Items cannot be empty.");
      return;
    }
    let error;
    this.arrItems.forEach(e => {
      if (e.cost <= 0) {
        error = true;
      }
      if (e.quantity <= 0) {
        error = true;
      }
    });
    if (error === true) {
      return;
    }

    if (this.purchaseorderForm.invalid) { return; }
    this.loading = true;


    if (status === "create") {
      this.f.statusID.setValue(600);
    }
    else if (status === "approve") {
      this.f.statusID.setValue(601);
    }
    else if (status === "receive") {
      this.f.statusID.setValue(605);
    }
    else {
      this.f.statusID.setValue(600);
    }
    this.f.statusID.setValue(this.f.statusID.value);
    this.f.expectedDate.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day + ", " + new Date().toTimeString()));
    this.f.date.setValue(new Date());
    this.f.poDetails.setValue(this.arrItems);
    this.f.taxes.setValue(this.arrtaxes);
    if (parseInt(this.f.poid.value) === 0) {

      //Insert purchaseorder
      this.purchaseorderService.InsertAndApprove(this.purchaseorderForm.value).subscribe(data => {
        this.alertService.success("Purchaseorder has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update purchaseorder
      this.purchaseorderService.UpdateAndApprove(this.purchaseorderForm.value).subscribe(data => {
        this.alertService.success("Purchaseorder has been updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }
  loadSuppliers() {
    this.purchaseorderService.getSuppliers(this.f.brandID.value).subscribe((res: any) => {
      this.Suppliers = res;
      if (this.Suppliers.length > 0) {

        if (this.f.poid.value == 0)
          this.f.supplierID.setValue(res[0].supplierID);


        if (this.SelectedSupplier !== 0 && this.SelectedLocation !== 0) {
          this.f.supplierID.setValue(this.SelectedSupplier);
          this.f.locationID.setValue(this.SelectedLocation);
          this.loadItems(this.SelectedLocation, this.SelectedSupplier);
        }
        else {
          this.loadItems(this.f.locationID.value, this.f.supplierID.value);
        }
      }
    });
  }

  loadLocations() {
    this.purchaseorderService.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
      if (this.Locations.length > 0) {

        if (this.f.poid.value == 0)
          this.f.locationID.setValue(res[0].locationID);

        this.loadSuppliers();
      }
    });
  }

  loadItems(locationID, supplierID) {
    this.purchaseorderService.getItems(locationID, supplierID, this.f.brandID.value).subscribe((res: any) => {
      this.Items = res;
      if (this.SelectedSupplier !== 0 && this.SelectedLocation !== 0 && this.SelectedItems.length > 0) {
        this.Items = this.Items.filter(item => this.SelectedItems.includes(item.supplyID));
        this.Items.forEach(item => {
          this.selectedInvItem(item);
        });
      }
    });
  }

  loadUnits() {
    this.unitService.getUnits().subscribe((res: any) => {
      this.Units = res;
      //this.f.unitID.setValue(res[0].unitID);
    });
  }
  close() {
    this.activeModal.dismiss();
  }
  selectedInvItem(obj) {
    
    this.conversionBoxOpen = true;
    if (obj.type !== null && obj.type !== undefined) {
      this.poDetails.type = obj.type;
    }
    this.poDetails.name = obj.name;
    this.poDetails.alternateName = obj.alternateName;
    this.poDetails.cost = obj.cost;
    this.poDetails.itemImage = obj.itemImage;
    this.poDetails.minStockLevel = obj.minStockLevel;
    var ele = this.Units.find(element => element.unit == obj.unit);
    if (ele) {
      this.poDetails.conversionUnitID = ele.unitID;
      this.poDetails.conversionUnit = ele.unit;
      this.poDetails.unitID = ele.unitID;
    }

    if (obj.supplyID === this.poDetails.supplyID) {

      this.addRow(this.poDetails);
    }
    else if (this.SelectedSupplier !== 0 && this.SelectedLocation !== 0 && this.SelectedItems.length > 0) {
      this.poDetails.supplyID = obj.supplyID;
      this.addRow(this.poDetails);

      
    }


  }
  deleteRow(obj) {

    const index = this.arrItems.indexOf(obj);
    this.arrItems.splice(index, 1);

    this.CalculateSummary();

    this.isSelectAll = false;
  }

  addRow(obj) {
    if (obj.name != undefined && obj.name != '' && obj.name != null && obj.quantity > 0 && obj.conversionRate > 0) {
      if (!this.arrItems.find(element => element.supplyID == obj.supplyID)) {
        this.arrItems.push({
          supplyID: obj.supplyID,
          cost: obj.cost,
          totalCost: obj.quantity * obj.cost,
          name: obj.name,
          alternateName: obj.alternateName,
          type: obj.type,
          statusID: 600,
          quantity: (obj.minStockLevel !== null && obj.minStockLevel !== undefined && obj.minStockLevel !== 0) ? ((3 * obj.minStockLevel) * (obj.conversionRate == null ? 1 : obj.conversionRate)) : (obj.quantity == null ? 1 : obj.quantity) * (obj.conversionRate == null ? 1 : obj.conversionRate),
          unitID: obj.unitID,
          conversionRate: obj.conversionRate == null ? 1 : obj.conversionRate,
          conversionUnitID: obj.conversionUnitID,
          conversionUnit: obj.conversionUnit,
          approvedQty: 0,
          receivedQty: 0,
          itemImage: obj.itemImage,
          minStockLevel: obj.minStockLevel,
          unit: obj.conversionUnit
        });

        console.log(this.arrItems);
        this.CalculateSummary();


      }
      else {
        this.alertService.error("Item already added in list");
      }
    }
    else {
      if (obj.name == undefined || obj.name == '' || obj.name == null) {
        this.alertService.error("Please select item.");
        return;
      }
      else if (obj.quantity <= 0) {
        this.alertService.error("Item quantity must be greater than 0.");
        return;
      }
      else if (obj.conversionRate <= 0) {
        this.alertService.error("Item conversion rate must be greater than 0.");
        return;
      }
    }
  }
  CalculateSummary() {

    this.f.subTotal.setValue(0);
    this.f.grandTotal.setValue(0);
    this.f.taxAmount.setValue(0);
    var subTotal = 0;
    var taxAmount = 0;
    var itemTotal = 0;
    if (this.arrItems.length > 0) {
      this.arrItems.forEach(element => {
        subTotal += Number(element["quantity"] * element["cost"]);
        itemTotal += Number(element["quantity"] * element["cost"]);
      });
      this.arrtaxes.forEach(element => {
        if (element.type === 2) {
          var _itemTotal = Number(itemTotal / (1 + (element.percentage / 100)))
          var tax = Number((element.percentage / 100) * _itemTotal);
          subTotal -= tax;
          element.amount = tax;
          taxAmount += tax;
        }
        else if (element.type === 3) {
          element.amount = Number((element.percentage / 100) * itemTotal);
          taxAmount += Number((element.percentage / 100) * itemTotal);
        }
        else {
          element.amount = Number((element.percentage / 100) * itemTotal);
        }

      });
      this.f.subTotal.setValue(subTotal);
      this.f.taxAmount.setValue(taxAmount);
      this.f.grandTotal.setValue(subTotal + taxAmount);
    }
  }


  UpdateQty(newItem) {
    if (newItem.cost > 0) {
      let updateItem = this.arrItems.find(this.findIndexToUpdate, newItem.Cost);
      let index = this.arrItems.indexOf(updateItem);

      this.arrItems[index] = newItem;

      this.CalculateSummary();
    }
    if (newItem.cost <= 0) {
      this.alertService.error("Item rate must be greater than 0.");
      //newItem.cost = newItem.cost * -1;
      return;
    }
  }

  findIndexToUpdate(newItem) {
    return newItem.cost === this;
  }

  generatePO() {
    this.purchaseorderService.generatePOno(this.f.brandID.value).subscribe((res: any) => {
      this.f.poCode.setValue(res.code);
      this.f.referenceNo.setValue(res.code);
    });
  }

  refreshDetailObj() {
    this.poDetails.cost = 0;
    this.poDetails.quantity = 1;
    this.poDetails.conversionRate = 1;
  }
  isApplyTax(obj) {
    if (obj.target.checked == true) {
      this.taxPercent = 15;
    }
    else {
      this.taxPercent = 0;
    }
    this.CalculateSummary();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      const search = item['name'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) !== -1);
    });

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      const search = item['SKU'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) !== -1);
    });


    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

  SearchItem(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.barcode.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.alternateName.toLocaleLowerCase().indexOf(term) > -1 || item.sku.toLocaleLowerCase().indexOf(term) > -1;
  }
  open(item) {
    const modalRef = this.modalService.open(ConversionCalculatorComponent, {
      size: 'lg', windowClass: 'small-modal', backdropClass: 'modal-custom-class', centered: true,animation: false });
    modalRef.componentInstance.selectedItem = item;
    modalRef.result.then((result) => {
      this.CalculateSummary();
    }, (reason) => {
      this.CalculateSummary();
    });
  }
  getLogs(id) {
    this.purchaseorderService.getLogs(id).subscribe((res: any) => {
      this.logs = res;
    });
  }
  getTaxesForPO(brandID) {
    this.purchaseorderService.getTaxesForPO(brandID).subscribe((res: any) => {
      this.arrtaxes = res;
      var len = this.arrtaxes.length;
      this.arrtaxes.forEach(e => {
        if (len > 1)
          e.type = 1;
        else if (len === 1)
          e.type = 3;
      });
    });
  }
  GetPOTaxType() {
    this.purchaseorderService.GetPOTaxType().subscribe((res: any) => {
      this.taxTypes = res;
    });
  }
  changeTax(tax, $event) {
    tax.type = parseInt($event.target.value);
    this.CalculateSummary();
  }
  updateStatus(status) {
    switch (status) {
      case 'approve': {
        this.purchaseorderService.approve(this.f.brandID.value, this.SelectedID, this.f.rowVersion.value).subscribe(data => {
          this.alertService.success("Purchaseorder has been updated");
          this.loading = false;
          this.SelectedID = 0;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
          //this.activeModal.dismiss();
        });
        break;
      }
      case 'receive': {
        this.purchaseorderService.markasreceived(this.f.brandID.value, this.SelectedID, this.f.rowVersion.value).subscribe(data => {
          this.alertService.success("Purchaseorder has been updated");
          this.loading = false;
          this.SelectedID = 0;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
          //this.activeModal.dismiss();
        });
        break;
      }

      case 'hold': {
        this.purchaseorderService.hold(this.f.brandID.value, this.SelectedID, this.f.rowVersion.value).subscribe(data => {
          this.alertService.success("Purchaseorder has been updated");
          this.loading = false;
          this.SelectedID = 0;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
          //this.activeModal.dismiss();
        });
        break;
      }
      case 'decline': {
        this.purchaseorderService.decline(this.f.brandID.value, this.SelectedID, this.f.rowVersion.value).subscribe(data => {
          this.alertService.success("Purchaseorder has been updated");
          this.loading = false;
          this.SelectedID = 0;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
          //this.activeModal.dismiss();
        });
        break;
      }
      default:
    }
  }
  renderStatusClass(obj) {
    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"

    }
  }

  closeSelect(select: NgSelectComponent) { select.close(); }
  isSelectAll = false;
  public onSelectAll(select) {
    this.arrItems = [];
    this.isSelectAll = true;
    this.Items.forEach(item => {
      this.poDetails.supplyID = item.supplyID;
      this.selectedInvItem(item);
    });
    this.closeSelect(select);
  }

  public onDeSelectAll(select) {
    this.isSelectAll = false;
    this.arrItems = [];
    this.closeSelect(select);
    this.CalculateSummary();
  }
}
