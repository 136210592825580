import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Dayjs} from 'dayjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-range-picker-v2',
  templateUrl: './date-range-picker-v2.component.html',
  styleUrl: './date-range-picker-v2.component.css'
})
export class DateRangePickerV2Component {
  selected;
  fromDate;
  toDate;

  alwaysShowCalendars: boolean;

  ranges: any = [];
  locale = { displayFormat: 'DD MMM YYYY', customRangeLabel: this.translate.instant('Custom Range') };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  maxDate = moment().toISOString();
  minDate = moment().subtract(12, 'month').toISOString();
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }
  @Input() MinMonths: number=12;
  @Output() OnHit: EventEmitter<string> = new EventEmitter<string>();

  constructor(private translate: TranslateService) {
    this.alwaysShowCalendars = true;
    this.selected = { startDate: moment(), endDate: moment() };
    
  }

  openDatepicker() {
    this.pickerDirective.open();
  }
  ngOnInit() {
    this.minDate = moment().subtract(this.MinMonths, 'month').toISOString();
    let today = this.translate.instant('Today');
    let yesterday = this.translate.instant('Yesterday');
    let week = this.translate.instant('This Week');
    let month = this.translate.instant('This Month');
    let days = this.translate.instant('Last 30 Days');
    let quarter = this.translate.instant('This Quarter');
    let lastQuarter = this.translate.instant('Last Quarter');
    let year = this.translate.instant('This Year');
    


    this.ranges[today] = [moment(), moment()];
    this.ranges[yesterday] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
    this.ranges[week] = [moment().startOf('week'), moment().endOf('week')];
    this.ranges[month] = [moment().startOf('month'), moment().endOf('month')];
    this.ranges[days] = [moment().subtract(29, 'days'), moment()];



    if (this.MinMonths > 1) {
      this.ranges[quarter] = [moment().startOf('quarter'), moment().endOf('quarter')];
    }
    if (this.MinMonths > 2) {
      this.ranges[lastQuarter] = [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')];
    }
    if (this.MinMonths > 8) {
      this.ranges[year] = [moment().startOf('year'), moment().endOf('year')];
    }

    
  }

  datesUpdated(e) {
    if (this.selected.startDate.$d) {
      this.fromDate = this.selected.startDate.$d;
      var d = new Date(this.selected.endDate.$d.toString());
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      this.toDate = d;
    }
    else {
      this.fromDate = this.selected.startDate._d;
      this.toDate = this.selected.endDate._d;
    }
    this.OnHit.emit('Click');
  }
}
