
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { SalesDetail, SalesDetailsTaxes } from 'src/app/_models/GeneratedReport';
import { Router } from '@angular/router';
import { SalesDetailReportService } from '../../../_services/sales-detail-report.service';
import { ExcelService } from '../../../_services/excel.service';
import { SortColumn } from '../../../_directives/sortable.directive';
import { DataOperationsService } from '../../../_services/data-operations.service';

@Component({
  selector: 'app-salesdetail',
  templateUrl: './salesdetail.component.html',
  styleUrls: ['./salesdetail.component.css']
})
export class SalesdetailComponent implements OnInit {
  data$: Observable<SalesDetail[]>;
  allData$: Observable<SalesDetail[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  selectedLang = 'en';
  submit: boolean;
  selectedReport: any;
  selectAll: boolean = true;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  locations = [];
  checkAllLocations: boolean = false;
  allSelected: boolean = false;
  sortColumn: SortColumn;
  sortDirection: string;
  isTobaccoTaxExist: boolean;
  constructor(public service: SalesDetailReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private excelService: ExcelService, private dataOperationsService: DataOperationsService) {

    this.loading$ = service.loading$;
    this.submit = false;
    this.selectedLang = this.localStorageService.getSelectedLanguage();
    this.selectedReport = history.state;

  }

  ngOnInit() {

    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
      //this.getReportData("https://marnpossastorage.blob.core.windows.net/marnpos-admin-reports/2021-01-30/stock-20210130T103754521");
    }, 1000);
  }
  getReportData(dataURL) {
    this.service.getSalesDetailReport(dataURL);
    this.data$ = this.service.data$;
    this.allData$ = this.service.allData$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    this.data$.subscribe(e => {
      if (e[0])
        e[0].TaxColumns.forEach(item => {
          let name = (this.selectedLang !== 'en') ? item.ArabicName : item.Name;
          if (!this.ColumnList.some(el => el.name === name))
            this.ColumnList.push({
              name: name, value: name, isChecked: true
            })
        })
    });

    this.service.allData$.subscribe(res => {
      this.locations = [];
      if (res) {
        new Set(res.map(report => report.Location)).forEach(e => {
          this.locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();

  }
  export(type, data): void {
    if (data) {

      const dataRows = this.service.report;
      const hasSelected = dataRows.some(row => row.selected === true);

      if (!hasSelected) {
        this.allSelected = true;
        dataRows.forEach(row => row.selected = true);
      }

      if (!(dataRows[0].ReportVersion || dataRows[0].ReportVersion === 'V2')) {
        this.CustomizeReport(type, dataRows);
      }
      else {
        this.CustomizeReport_V2(type, dataRows);
      }
    }
    else
      (data != undefined) ? (data.length == 0 ? this.alertService.error("Data is not available.") : "") : "";

  }
  private getExportData() {
    let selected: any;

    selected = [];
    this.service.report.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.report : selected;
    return selected;
  }

  checkSelection(data): void {
    this.allSelected = data.source._value.every(item => item.selected);
  }

  public bulkSelection($event) {
    this.data$.forEach(i => {
      if (i)
        i.forEach(e => e.selected = $event.target.checked)
    });
    this.allData$.forEach(i => {
      if (i)
        i.forEach(e => e.selected = $event.target.checked)
    });
  }
  private getTotalRowData(data) {
    let selected: any[] = data.filter(d => d.selected);

    selected = selected.length == 0 ? this.service.report : selected;

    const totalForSelectedRows = this.service.getSalesDetailsTotals(selected);

    const totalRow = {
      RowType: `Total of ${selected.length} rows`
    };
    if (this.setHidden('TotalAmount'))
      totalRow['Sale'] = parseFloat(totalForSelectedRows.Price.toFixed(2));
    if (this.setHidden('Cost'))
      totalRow['Cost'] = parseFloat(totalForSelectedRows.Cost.toFixed(2));
    if (this.setHidden('DiscountAmount'))
      totalRow['Discount'] = parseFloat(totalForSelectedRows.Discount.toFixed(2));
    if (this.setHidden('RefundAmount'))
      totalRow['Refund'] = parseFloat(totalForSelectedRows.Refund.toFixed(2));
    if (this.setHidden('TaxAmount'))
      totalRow['TotalTax'] = parseFloat(totalForSelectedRows.TotalTaxes.toFixed(2));
    if (this.setHidden('GrandTotal'))
      totalRow['GrandTotal'] = parseFloat(totalForSelectedRows.GrandTotal.toFixed(2));
    if (this.setHidden('Profit'))
      totalRow['Profit'] = parseFloat(totalForSelectedRows.Profit.toFixed(2));
    return totalRow;
  }

  private CustomizeReport_V2(type, data) {
    let _let = this;
    let total: any;
    if (data) {
      total = this.getTotalRowData(data);
    }

    let jsonData = [];
    let sortedData = [];
    data.forEach(function (item) {
      let temp = {};
      if (_let.setHidden('DeviceTransactionNo'))
        temp["DeviceTransactionNo"] = (item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID;
      if (_let.setHidden('Partner'))
        temp["Partner"] = item.PartnerName;
      if (_let.setHidden('OrderNo'))
        temp["OrderNo"] = item.OrderNo;
      if (_let.setHidden('OrderRefrenceID'))
        temp["OrderReference"] = item.OrderRefrenceID;

      if (_let.service.tableAvailable)
        if (_let.setHidden('TableName'))
          temp["TableName"] = item.TableName;


      temp["Category"] = "";
      temp["Sub-Category"] = "";
      temp["Name"] = "";
      temp["RowType"] = "Order";
      temp["Quantity"] = 0;
      if (_let.setHidden('Cost'))
        temp["Cost"] = 0;
      if (_let.setHidden('TotalAmount'))
        temp["Sale"] = item.Sale;
      if (_let.setHidden('DiscountAmount'))
        temp["Discount"] = item.DiscountAmount;
      if (_let.setHidden('RefundAmount'))
        temp["Refund"] = item.RefundAmount;
      if (_let.setHidden('TaxAmount'))
        temp["TotalTax"] = item.TaxAmount;
      if (_let.setHidden('GrandTotal'))
        temp["GrandTotal"] = item.GrandTotal;
      if (_let.setHidden('Profit'))
        temp["Profit"] = parseFloat(item.Profit);
      if (_let.setHidden('MultiPayments'))
        temp["PaidAmount"] = item.TotalPayments;

      if (_let.setHidden('OrderType'))
        temp["Type"] = item.OrderType;

      temp["BarCode"] = "";

      if (_let.setHidden('Location'))
        temp["Location"] = item.Location;
      if (_let.setHidden('PaymentMode'))
        temp["PaymentMethod"] = item.PaymentMode;

      if (_let.setHidden('CheckoutDate'))
        temp["TrancationDateTime"] = item.CheckoutDate;// this.datePipe.transform(date; "yyyy-MM-dd");
      if (_let.setHidden('CheckoutBy'))
        temp["TrancationCeatedBy"] = item.CheckoutBy;


      if (item.selected != undefined)
        if (item.selected == true) {
          let duplicate = jsonData.filter(function (v, i) { return (v.OrderNo === item.OrderNo && v.POSLocation == item.Location && v.RowType === "Order" && v.DeviceTransactionNo === ((item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID)) })
          if (!duplicate || duplicate.length === 0) {
            if (item.TaxColumns?.length > 0) {
              for (var i = 0; i < item.TaxColumns.length; i++) {
                let amount = 0;
                for (var j = 0; j < item.Taxes.length; j++) {
                  if (item.Taxes[j]?.Name && item.Taxes[j]?.Name === item.TaxColumns[i].Name) {
                    amount = item.Taxes[j].Amount;
                  }
                }
                if (_let.setHidden(item.TaxColumns[i].Name))
                  temp[item.TaxColumns[i].Name] = amount;
                //total[item.TaxColumns[i].Name] = (total[item.TaxColumns[i].Name] || 0) + amount;
              }
            }
            jsonData.push(temp);
            item.Details.forEach(function (itemDetail) {
              let tempDetail = {};
              if (_let.setHidden('DeviceTransactionNo'))
                tempDetail["DeviceTransactionNo"] = (item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID;
              if (_let.setHidden('Partner'))
                tempDetail["Partner"] = item.PartnerName;
              if (_let.setHidden('OrderNo'))
                tempDetail["OrderNo"] = item.OrderNo;

              tempDetail["Category"] = itemDetail.CategoryName;
              tempDetail["Sub-Category"] = itemDetail.SubCategoryName;
              tempDetail["Name"] = itemDetail.Name;
              tempDetail["RowType"] = itemDetail.ItemType;
              tempDetail["Quantity"] = itemDetail.Quantity;
              if (_let.setHidden('Cost'))
                tempDetail["Cost"] = (parseFloat(itemDetail.Cost));

              if (_let.setHidden('TotalAmount'))
                tempDetail["Sale"] = parseFloat(itemDetail.Sale);
              if (_let.setHidden('DiscountAmount'))
                tempDetail["Discount"] = itemDetail.DiscountAmount;
              if (_let.setHidden('TaxAmount'))
                tempDetail["TotalTax"] = parseFloat(itemDetail.Tax);
              if (_let.setHidden('GrandTotal'))
                tempDetail["GrandTotal"] = parseFloat(itemDetail.GrandTotal);
              if (_let.setHidden('Profit'))
                tempDetail["Profit"] = parseFloat(itemDetail.Profit);
              if (_let.setHidden('MultiPayments'))
                tempDetail["PaidAmount"] = parseFloat(itemDetail.GrandTotal);
              if (_let.setHidden('OrderType'))
                tempDetail["Type"] = item.OrderType;

              tempDetail["BarCode"] = itemDetail.Barcode;

              if (_let.setHidden('Location'))
                tempDetail["Location"] = item.Location;

              if (_let.setHidden('PaymentMode'))
                tempDetail["PaymentMethod"] = "";
              
              if (_let.setHidden('CheckoutDate'))
                tempDetail["TrancationDateTime"] = item.CheckoutDate;// this.datePipe.transform(date, "yyyy-MM-dd"),
              if (_let.setHidden('CheckoutBy'))
                tempDetail["TrancationCeatedBy"] = item.CheckoutBy;

              (item.selected == true) ? jsonData.push(tempDetail) : "";
              //jsonData.push(tempDetail);
            });
          }
          else {
            duplicate[0].PaymentMethod = "Multi";
          }
        }

    });
    if (jsonData.length > 0) {
      sortedData = this.dataOperationsService.prepareExportData(jsonData, this.sortColumn, this.sortDirection);
      sortedData.push(total);
      this.excelService.exportAsExcelFile(sortedData, 'Export-SalesDetail', type);
    }
    else {
      this.alertService.error("Select orders to download.");
    }
  }


  private CustomizeReport(type, data) {

    let jsonData = [];

    data.forEach(function (item) {
      let temp = {
        "DeviceTransactionNo": (item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID,
        "Partner": item.PartnerName,
        "OrderNo": item.OrderNo,
        "OrderReference": item.OrderRefrenceID,
        "Category": "",
        "Sub-Category": "",
        "Name": "",
        "RowType": "Order",
        "Quantity": 0,
        "Cost": 0,
        "Sale": parseFloat(item.GrandTotal) - parseFloat(item.TaxAmount),
        "Discount": item.DiscountAmount,
        "Refund": item.RefundAmount,
        "Tax": item.TaxAmount,
        "GrandTotal": item.GrandTotal,
        "Profit": parseFloat(item.Profit),
        "Type": item.OrderType,
        "BarCode": "",
        "POSLocation": item.Location,
        "PaymentMethod": item.PaymentMode,
        "TrancationID": "",
        "TrancationDateTime": item.CheckoutDate,// this.datePipe.transform(date, "yyyy-MM-dd"),
        "TrancationCeatedBy": item.CheckoutBy
      };
      if (item.selected != undefined)
        if (item.selected == true) {
          jsonData.push(temp)
          item.Details.forEach(function (itemDetail) {
            let tempDetail = {
              "DeviceTransactionNo": (item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID,
              "Partner": item.PartnerName,
              "OrderNo": item.OrderNo,
              "Category": itemDetail.CategoryName,
              "Sub-Category": itemDetail.SubCategoryName,
              "Name": itemDetail.Name,
              "RowType": itemDetail.ItemType,
              "Quantity": itemDetail.Quantity,
              "Cost": (parseFloat(itemDetail.Cost)),
              "Sale": parseFloat(itemDetail.Quantity) * (parseFloat(itemDetail.PriceWithVAT)),
              "Discount": itemDetail.DiscountAmount,
              "Tax": parseFloat(itemDetail.Quantity) * (parseFloat(itemDetail.PriceWithVAT) - parseFloat(itemDetail.Price)),
              "GrandTotal": parseFloat(itemDetail.Quantity) * itemDetail.PriceWithVAT,
              "Profit": (parseFloat(itemDetail.Quantity) * parseFloat(itemDetail.Price)) - parseFloat(itemDetail.Cost),
              "Type": item.OrderType,
              "BarCode": itemDetail.Barcode,
              "POSLocation": item.Location,
              "PaymentMethod": item.PaymentMode,
              "TrancationID": "",
              "TrancationDateTime": item.CheckoutDate,// this.datePipe.transform(date, "yyyy-MM-dd"),
              "TrancationCeatedBy": item.CheckoutBy
            };
            (item.selected == true) ? jsonData.push(tempDetail) : "";
            //jsonData.push(tempDetail);
          });
        }

    });
    if (jsonData.length > 0) {
      this.excelService.exportAsExcelFile(jsonData, 'Export-SalesDetail', type);
    }
    else {
      this.alertService.error("Select orders to download.");
    }
  }

  ColumnList =
    [
      {
        name: "TRANSACTION", value: "DeviceTransactionNo", isChecked: true
      },
      {
        name: "Partner", value: "Partner", isChecked: true
      },
      {
        name: "Order No", value: "OrderNo", isChecked: true
      },
      {
        name: "Order Reference", value: "OrderRefrenceID", isChecked: true
      },
      {
        name: "Table Name", value: "TableName", isChecked: true
      },
      {
        name: "Price", value: "TotalAmount", isChecked: true
      },
      {
        name: "Cost", value: "Cost", isChecked: true
      },
      //{
      //  name: "Discount Percentage", value: "DiscountPercentage", isChecked: true
      //},
      {
        name: "Discount", value: "DiscountAmount", isChecked: true
      },
      {
        name: "Refund", value: "RefundAmount", isChecked: true
      },
      {
        name: "Tax", value: "TaxAmount", isChecked: true
      },
      {
        name: "Grand Total", value: "GrandTotal", isChecked: true
      },
      {
        name: "MultiPayments", value: "MultiPayments", isChecked: true
      },
      {
        name: "Profit", value: "Profit", isChecked: true
      },
      {
        name: "Location", value: "Location", isChecked: true
      },
      {
        name: "Time", value: "CheckoutDate", isChecked: true
      },
      {
        name: "User", value: "CheckoutBy", isChecked: true
      },
      {
        name: "Pay Type", value: "PaymentMode", isChecked: true
      },
      {
        name: "Order Type", value: "OrderType", isChecked: true
      }

    ]


  SelectedColumns: any;
  filters() {
    if (this.ColumnList.filter(e => e.isChecked === false)?.length > 0) {
      this.selectAll = false;
    }
    else {
      this.selectAll = true;
    }
    this.SelectedColumns = this.ColumnList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name });

    console.log(this.ColumnList);
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }

  filterLocations() {
    if (this.locations.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocations = false;
    }
    else {
      this.checkAllLocations = true;
    }
    let headerFilter = {
      locations: this.locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
    }
    this.service.headerFilter = headerFilter;
  }


  public selectAllLocationFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filterLocations();
  }

  setSelectedResult(val) {
    if (val == 'columns') {
      return this.ColumnList.filter(k => k.isChecked == true).length;
    }

  }

  setHidden(columnName) {
    return this.ColumnList.filter(k => k.isChecked == true && k.value == columnName).length;
  }
  ShowCustomTaxes(Taxes: SalesDetailsTaxes[], taxName: string) {
    let val = null;
    if (Taxes.length > 1) {
      this.isTobaccoTaxExist = true;
    }
    if (Taxes && taxName)
      val = Taxes.find(e => e.Name == taxName);
    return val?.Amount ?? 0;
  }
}
