
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { GroupModifier } from 'src/app/_models/Modifier';
import { ExcelService } from 'src/app/_services/excel.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { AddgroupmodifierComponent } from './addgroupmodifier/addgroupmodifier.component';
import { GroupModifierService } from 'src/app/_services/groupmodifier.service';
import { ActionConfirmationService } from '../../../_services/action-confirmation.service';
import { ModifierUplifterComponent } from '../modifier-uplifter/modifier-uplifter.component';
import { TrackEventsService } from '../../../_services/track-events.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-groupmodifier',
  templateUrl: './groupmodifier.component.html',
  styleUrls: ['./groupmodifier.component.css']
})
export class GroupmodifierComponent implements OnInit {

  data$: Observable<GroupModifier[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "groupmodifier";
  submit: boolean;
  excelData = [];
  StatusList = [];
  activeCount = 0;
  inActiveCount = 0;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: GroupModifierService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private excelService: ExcelService,
    private trackEvents: TrackEventsService,
    private translate: TranslateService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  getBrandData() {
    this.service.headerFilter = { status: [''] };
    this.service.getGroupModifier(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

      }
    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddgroupmodifierComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Group Modifier");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(modifier) {
    
    const modalRef = this.modalService.open(AddgroupmodifierComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Group Modifier");
    modalRef.componentInstance.SelectedID = modifier.groupID;
    modalRef.componentInstance.SelectedName = modifier.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Group Modifier has been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(id, rowVersion) {

    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Group Modifier has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  SetStatus(id, statusID, rowVersion) {
    this.service.SetStatus(parseInt(id), parseInt(statusID), rowVersion).subscribe((res: any) => {
      if (parseInt(statusID) === 1)
        this.alertService.success("Group Modifier has been Actived");
      else
        this.alertService.success("Group Modifier has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  import() {
    const modalRef = this.modalService.open(ModifierUplifterComponent, { size: 'xl', windowClass: 'small-modal' });
    modalRef.result.then((result) => {
    }, (r) => {
      this.getBrandData();
    });
  }

  public chkSelection($event, modifier) {
    this.service.groupmodifiers.forEach((item, index) => {
      if (item.groupID == modifier.groupID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {
          
          this.excelData.splice(index, 1);
        }
      }
    });
  }
  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-GroupModifier', type);
  }
  private getExportData() {
    let selected: GroupModifier[];
    let returnList = [];
    selected = [];
    this.service.groupmodifiers.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.groupmodifiers : selected;
    selected = selected.filter(function (modifier) {
      var data =
      {
        Name: modifier.name,
        ArabicName: modifier.alternateName,
        ModifiersItems: modifier.modifiers,
        Min: modifier.min,
        Max: modifier.max,
        Status: modifier.statusID == 1 ? 'Active' : 'Inactive'
      };
      returnList.push(data);
      return true;
    });
    return returnList;

  }
  public bulkSelection($event) {
    this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }
  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Group Modifier has been Deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
  checkSelection() {
    this.activeCount = this.service.groupmodifiers.filter(e => e.selected === true && e.statusID === 1).length;
    this.inActiveCount = this.service.groupmodifiers.filter(e => e.selected === true && e.statusID === 2).length;
    return this.activeCount + this.inActiveCount; 
  }
  updateStatus(statusId) {
    let ids = this.service.groupmodifiers.filter(e => e.selected === true).map(a => a.groupID).join(',');
    this.service.updateBulkStatus(ids, statusId).subscribe((res: any) => {
      this.alertService.success("Status updated");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  askForDelete(statusId) {
    let count = (this.activeCount + this.inActiveCount).toString() + ' ' + this.translate.instant('rows');
    this.confirmationDialogService.confirm(this.translate.instant('Delete') + ' ' + count, count.toString())
      .then((confirmed) => {
        if (confirmed) {
          this.updateStatus(statusId);
        }

      })
      .catch(() => { });
  }
}
