<div class="modal-header">
  <h6 class="modal-title fw-bold">{{title | translate }}</h6>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="fw-bold">
    {{'Are you sure you want to delete' | translate }} {{name }}{{'?' | translate}}
  </p>
  <p>
    {{'Once you delete the' | translate}} {{name}} {{'you will lose them and their data, and you will have to enter them again when you want' | translate}}
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="decline()">{{'Cancel' | translate }}</button>
  <button class="btn btn-danger btn-uninstall float-end bg-red" (click)="accept()">
    {{'Delete' | translate }} @if(name.includes('row')){ {{name}} }
  </button>
</div>
