import { ElementRef, Injectable } from '@angular/core';
import { SortColumn } from '../_directives/sortable.directive';

@Injectable({
  providedIn: 'root'
})
export class DataOperationsService {

  constructor() { }

  prepareExportData_v2(data: any[], column: SortColumn, direction: string, tableHeader: ElementRef ): any[] {
    const sortedData = this.sortExportedData(data, column, direction);

    const headerOrder = this.getSortableColumns(tableHeader);

    return sortedData.map(item => {
      return headerOrder.reduce((acc, key) => {
        acc[key] = item[key] || '';
        return acc;
      }, {});
    });
  }

  prepareExportData(data: any[], column: SortColumn, direction: string): any[] {
    const sortedData = this.sortExportedData(data, column, direction);

    const headerOrder = this.getDynamicHeaderOrder(data);

    return sortedData.map(item => {
      return headerOrder.reduce((acc, key) => {
        acc[key] = item[key] || '';
        return acc;
      }, {});
    });
  }

  getDynamicHeaderOrder(data: any[]): string[] {
    if (data.length === 0) {
      return [];
    }

    return Object.keys(data[0]);
  }

  private sortExportedData(data: any[], column: SortColumn, direction: string): any[] {
    if (direction === '' || column === '') {
      return data;
    } else {
      return [...data].sort((a, b) => {
        const res = `${a[column]}`.toString().localeCompare(`${b[column]}`.toString(), undefined, { numeric: true });

        return direction === 'asc' ? res : -res;
      });
    }
  }

  getSortableColumns(tableHeader: ElementRef): string[] {
    
    const thElements = tableHeader.nativeElement.querySelectorAll('th[sortable]');
    return Array.from(thElements).map((th: HTMLTableCellElement) => th.getAttribute('sortable')!);
  }
}
