import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { State } from '../_models/State';
import { Supplier } from '../_models/Supplier';


const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Supplier[], column: SortColumn, direction: string): Supplier[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: Supplier, term: string) {
  return data.name.toLowerCase().includes(term.toLowerCase())
}
interface SearchSupplierResult {
  data: Supplier[];
  total: number;
}

//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[],
  city: string[],
}


function matcheHeader(data: Supplier, filterValues: FilterHeaderState) {
  let status = false;
  let city = false;

  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }

  // City
  if (filterValues.city !== null && filterValues.city.length > 0) {
    for (var i = 0, len = filterValues.city.length; i < len; i++) {
      if (data.city.toLowerCase().toString().includes(filterValues.city[i].toLowerCase())) {
        city = true;
      }
    }
  }
  else {
    city = true;
  }

  if (city && status) {
    return data;
  }

}
//@@@ Header Filter Code End

@Injectable({
  providedIn: 'root'
})

export class SupplierService {

  constructor(private http: HttpClient) {
  }


  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<Supplier[]>([]);
  private _data$ = new BehaviorSubject<Supplier[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public suppliers: Supplier[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null,
    city: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchSupplierResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.suppliers, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@@ Header Filter Code End

  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  public getSupplier(brandId) {

    const url = `api/inventory/supplier/all/${brandId}`;
    this._data$.next(null);
    tap(() => this._loading$.next(true)),
      this.http.get<Supplier[]>(url).subscribe(res => {
        this.suppliers = res;

        this._data$.next(this.suppliers);
        this._allData$.next(this.suppliers);


        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });


        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

        //@@@ Header Filter Code End


      });
  }

  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }
  getById(id, brandId) {
    return this.http.get<any[]>(`api/inventory/supplier/${id}`);
  }

  getSuppliersddl(brandId) {
    return this.http.get<any[]>(`api/inventory/supplier/all/${brandId}`);
  }
  public getSuppliers(brandId) {

    const url = `api/inventory/supplier/all/${brandId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Supplier[]>(url).subscribe(res => {
        this.suppliers = res;
        this._data$.next(this.suppliers);
        this._allData$.next(this.suppliers);


        //this._search$.pipe(
        //  switchMap(() => this._search()),
        //  tap(() => this._loading$.next(false))
        //).subscribe(result => {
        //  this._data$.next(result.data);
        //  this._total$.next(result.total);
        //});

        //this._search$.next();

        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });


        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

        //@@@ Header Filter Code End


      });
  }
  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  insert(data) {

    return this.http.post(`api/inventory/supplier`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  update(updateData) {
    return this.http.put(`api/inventory/supplier`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  deactive(id, version) {
    return this.http.patch(`api/inventory/supplier/deactive/${id}/${version}`, null);
  }
  country() {
    return this.http.get<any[]>(`api/country/all`);
  }
  city(code) {

    return this.http.get<any[]>(`api/city/all/${code}`);
  }
  delete(id, version) {
    return this.http.delete<any[]>(`api/inventory/supplier/${id}/${version}`);
  }
  updateBulkStatus(ids, statusID) {
    let data = { ids: ids, statusID: statusID };
    return this.http.put(`api/inventory/supplier/updateBulkStatus`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }

  private _search(): Observable<SearchSupplierResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.suppliers, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }
}
