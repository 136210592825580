import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageSelectionComponent } from './image-selection/image-selection.component';
import { TrackEventsService } from '../_services/track-events.service';
import { AlertService } from '../_services/alert.service';
import * as CryptoJS from 'crypto-js';
import { BlobServiceClient, AnonymousCredential, newPipeline } from '@azure/storage-blob';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-image-upload-v2',
  templateUrl: './image-upload-v2.component.html',
  styleUrl: './image-upload-v2.component.css'
})
export class ImageUploadV2Component {
  imagepath: any = "";
  title = 'web1';
  pattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  localImagePath = null;
  imageBlob = null;
  breakCache = Math.floor((Math.random() * 100) + 1);
  constructor(private modalService: NgbModal,
    private alertService: AlertService) {

  }

  imageSelect() {

  }
  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size <= 2000000) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          let read = reader.result as string;
          let rea = event.target.files[0];
        };
        const modalRef = this.modalService.open(ImageSelectionComponent, { size: 'lg', windowClass: 'small-modal', animation: false });
        modalRef.componentInstance.event = event;
        modalRef.result.then((result) => {

          this.imageBlob = result.blob;
          this.localImagePath = result.safeURL.changingThisBreaksApplicationSecurity;
        });
      }
      else {
        this.alertService.error("File size is larger than 2 MB.");
      }
    }

    //this.upload();
  }
  async upload(imagePath: string | null) {
    var promise = new Promise((resolve, reject) => {
      if (this.localImagePath) {
        // generate account sas token
        const accountName = environment.accountName;
        const key = environment.key;
        const start = new Date(new Date().getTime() - (15 * 60 * 1000));
        const end = new Date(new Date().getTime() + (30 * 60 * 1000));
        const signedpermissions = 'rwdlac';
        const signedservice = 'b';
        const signedresourcetype = 'sco';
        const signedexpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
        const signedProtocol = 'https';
        const signedversion = '2018-03-28';

        const StringToSign =
          accountName + '\n' +
          signedpermissions + '\n' +
          signedservice + '\n' +
          signedresourcetype + '\n' +
          '\n' +
          signedexpiry + '\n' +
          '\n' +
          signedProtocol + '\n' +
          signedversion + '\n';
        // const crypto = require('crypto')
        // const sig = crypto.createHmac('sha256', Buffer.from(key, 'base64')).update(StringToSign, 'utf8').digest('base64');

        var str = CryptoJS.HmacSHA256(StringToSign, CryptoJS.enc.Base64.parse(key));
        var sig = CryptoJS.enc.Base64.stringify(str);


        const sasToken = `sv=${(signedversion)}&ss=${(signedservice)}&srt=${(signedresourcetype)}&sp=${(signedpermissions)}&se=${encodeURIComponent(signedexpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;
        const containerName = environment.containerName;

        const pipeline = newPipeline(new AnonymousCredential(), {
          retryOptions: { maxTries: 4 }, // Retry options
          userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" }, // Customized telemetry string
          keepAliveOptions: {
            // Keep alive is enabled by default, disable keep alive by setting false
            enable: false
          }
        });

        const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`,
          pipeline)
        const containerClient = blobServiceClient.getContainerClient(containerName)
        if (!containerClient.exists()) {
          console.log("the container does not exit")
          containerClient.create()

        }
        var d = new Date(); // Your date
        var dStart = new Date(1970, 1, 1);
        var dateDifference = ((d.getTime() - dStart.getTime()) * 10000).toString();
        if (imagePath && imagePath !== '' && !!this.pattern.test(imagePath)) {
          var name = imagePath.split('?')[0].split('/').pop();
          dateDifference = name.split('.')[0];
        }

        const client = containerClient.getBlockBlobClient(dateDifference + '.jpg');
        const response = client.uploadBrowserData(this.imageBlob, {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20, // 20 concurrency
          onProgress: (ev) => console.log(ev),
          blobHTTPHeaders: { blobContentType: '.jpg' }
        });

        this.imagepath = `https://${accountName}.blob.core.windows.net/` + client.containerName + '/' + client.name;

        resolve('Done');

      }
      else {
        this.imagepath = imagePath;
        resolve('Done');
      }
    });
    return promise;
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }

}
