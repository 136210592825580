<div class="date-range-picker" >
  <input class="form-control dpFromDate datepicker-calendar-icon text-center p-0 mt-1" readonly="readonly" autocomplete="off" id="dpDate"
         type="text"
         ngxDaterangepickerMd
         [closeOnAutoApply]="true"
         [autoApply]="true"
         [(ngModel)]="selected"
         [showCustomRangeLabel]="true"
         [alwaysShowCalendars]="true"
         [showRangeLabelOnInput]="true"
         [ranges]="ranges"
         [linkedCalendars]="true"
         [isInvalidDate]="isInvalidDate"
         [showClearButton]="false"
         placeholder="Select please..."
         (datesUpdated)="datesUpdated($event)"
         [opens]="'center'"
         [locale]="locale"
         role="button"
         [minDate]="minDate"
         [maxDate]="maxDate">

</div>
