import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { PurchaseorderService } from 'src/app/_services/purchaseorder.service';
import { UnitService } from 'src/app/_services/unit.service';
import { poDetails, taxes } from 'src/app/_models/Purchaseorder';
import { NgbdDatepickerAdapter } from 'src/app/datepicker/datepicker-adapter';

@Component({
  selector: 'app-addpurchaseorder',
  templateUrl: './addpurchaseorder.component.html',
  styleUrls: ['./addpurchaseorder.component.css']
})
export class AddpurchaseorderComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedSupplier = 0;
  @Input() SelectedLocation = 0;
  @Input() SelectedItems = [];
  submitted = false;
  conversionBoxOpen = false;
  purchaseorderForm: UntypedFormGroup;
  loading = false;
  loadingPurchaseorder = false;
  ButtonText = "Save";
  Locations = [];
  Suppliers = [];
  Items = [];
  Units = [];
  taxPercent = 15;
  arrItems: poDetails[] = [];
  arrtaxes: taxes[] = [];
  @ViewChild(NgbdDatepickerAdapter, { static: true }) dateComp;
  public poDetails = new poDetails();
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private ls: LocalStorageService,
    private alertService: AlertService,
    private unitService: UnitService,
    private purchaseorderService: PurchaseorderService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedPurchaseorder();

    this.loadLocations();
    this.loadUnits();
    this.refreshDetailObj();

    if (this.f.poid.value == 0)
      this.generatePO();

  }
  get f() { return this.purchaseorderForm.controls; }

  private createForm() {

    this.purchaseorderForm = this.formBuilder.group({
      poCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      poid: 0,
      supplierID: 0,
      subTotal: 0,
      taxAmount: 0,
      grandTotal: 0,
      expectedDate: [],
      date: [],
      locationID: 0,
      rowVersion: 0,
      poDetails: [],
      taxes: []
    });
  }

  private editForm(purchaseorder) {

    this.f.poCode.setValue(purchaseorder.poCode);
    this.f.date.setValue(purchaseorder.date);
    this.dateComp.setDate(purchaseorder.expectedDate);
    this.f.expectedDate.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day));
    this.f.referenceNo.setValue(purchaseorder.referenceNo);
    this.f.poDetails.setValue(purchaseorder.poDetails);
    this.arrItems = purchaseorder.poDetails;
    this.f.taxes.setValue(purchaseorder.taxes);
    this.arrtaxes = purchaseorder.taxes;
    this.taxPercent = this.arrtaxes[0]["percentage"];
    this.f.subTotal.setValue(purchaseorder.subTotal);
    this.f.taxAmount.setValue(purchaseorder.taxAmount);
    this.f.grandTotal.setValue(purchaseorder.grandTotal);
    this.f.notes.setValue(purchaseorder.notes);
    this.f.rowVersion.setValue(purchaseorder.rowVersion);
    this.f.statusID.setValue(purchaseorder.statusID);
    this.f.poid.setValue(purchaseorder.poid);
    this.f.locationID.setValue(purchaseorder.locationID);
    this.f.supplierID.setValue(purchaseorder.supplierID);
    this.f.locationID.disable();
  }

  setSelectedPurchaseorder() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingPurchaseorder = true;
      this.f.poid.setValue(this.SelectedID);
      this.purchaseorderService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingPurchaseorder = false;
      });
    }
  }

  onSubmit() {

    this.purchaseorderForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.arrItems.length == 0) {
      this.alertService.error("Items cannot be empty.");
      return;
    }
    if (this.purchaseorderForm.invalid) { return; }
    this.loading = true;

    this.f.statusID.setValue(this.f.statusID.value);
    this.f.expectedDate.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day + ", " + new Date().toTimeString()));
    this.f.date.setValue(new Date());
    this.f.poDetails.setValue(this.arrItems);
    this.f.taxes.setValue(this.arrtaxes);
    if (parseInt(this.f.poid.value) === 0) {

      //Insert purchaseorder
      this.purchaseorderService.insert(this.purchaseorderForm.value).subscribe(data => {
        this.alertService.success("Purchaseorder has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update purchaseorder
      this.purchaseorderService.update(this.purchaseorderForm.value).subscribe(data => {
        this.alertService.success("Purchaseorder has been updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }
  loadSuppliers() {
    this.purchaseorderService.getSuppliers(this.f.brandID.value).subscribe((res: any) => {
      this.Suppliers = res;
      if (this.Suppliers.length > 0) {

        if (this.f.poid.value == 0)
          this.f.supplierID.setValue(res[0].supplierID);

        
        if (this.SelectedSupplier !== 0 && this.SelectedLocation !== 0) {
          this.f.supplierID.setValue(this.SelectedSupplier);
          this.f.locationID.setValue(this.SelectedLocation);
          this.loadItems(this.SelectedLocation, this.SelectedSupplier);
        }
        else
        {
          this.loadItems(this.f.locationID.value, this.f.supplierID.value);
        }
      }
    });
  }

  loadLocations() {
    this.purchaseorderService.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
      if (this.Locations.length > 0) {

        if (this.f.poid.value == 0)
          this.f.locationID.setValue(res[0].locationID);

        this.loadSuppliers();
      }
    });
  }

  loadItems(locationID, supplierID) {
    this.purchaseorderService.getItems(locationID, supplierID, this.f.brandID.value).subscribe((res: any) => {
        this.Items = res;
      if (this.SelectedSupplier !== 0 && this.SelectedLocation !== 0 && this.SelectedItems.length > 0) {
        this.Items = this.Items.filter(item => this.SelectedItems.includes(item.supplyID));
      }
    });
  }

  loadUnits() {
    this.unitService.getUnits().subscribe((res: any) => {
      this.Units = res;
      this.f.unitID.setValue(res[0].unitID);
    });
  }
  close() {
    this.activeModal.dismiss();
  }
  selectedInvItem(obj) {
    this.conversionBoxOpen = true;
    this.poDetails.type = obj.type;
    this.poDetails.name = obj.name;
    this.poDetails.cost = obj.cost;
    var ele = this.Units.find(element => element.unit == obj.unit);
    if (ele) {
      this.poDetails.conversionUnitID = ele.unitID;
      this.poDetails.conversionUnit = ele.unit;
      this.poDetails.unitID = ele.unitID;
    }
  }
  deleteRow(obj) {

    const index = this.arrItems.indexOf(obj);
    this.arrItems.splice(index, 1);

    this.CalculateSummary();
  }

  addRow(obj) {
    if (obj.name != undefined && obj.name != '' && obj.name != null && obj.quantity > 0 && obj.conversionRate > 0) {
      if (!this.arrItems.find(element => element.supplyID == obj.supplyID)) {
        this.arrItems.push({
          supplyID: obj.supplyID,
          cost: obj.cost,
          totalCost: obj.quantity * obj.cost,
          name: obj.name,
          alternateName: obj.alternateName,
          type: obj.type,
          statusID: 600,
          quantity: (obj.quantity == null ? 1 : obj.quantity) * (obj.conversionRate == null ? 1 : obj.conversionRate),
          unitID: obj.unitID,
          conversionRate: obj.conversionRate == null ? 1 : obj.conversionRate,
          conversionUnitID: obj.conversionUnitID,
          conversionUnit: obj.conversionUnit,
          approvedQty: 0,
          receivedQty: 0,
          itemImage: null,
          minStockLevel: obj.minStockLevel,
          unit: obj.conversionUnit
        });
        this.CalculateSummary();
      }
      else {
        alert("Item already added in list")
      }
    }
    else {
      if (obj.name == undefined || obj.name == '' || obj.name == null) {
        this.alertService.error("Please select item.");
        return;
      }
      else if (obj.quantity <= 0) {
        this.alertService.error("Item quantity must be greater than 0.");
        return;
      }
      else if (obj.conversionRate <= 0) {
        this.alertService.error("Item conversion rate must be greater than 0.");
        return;
      }
    }
  }
  CalculateSummary() {

    this.f.subTotal.setValue(0);
    this.f.grandTotal.setValue(0);
    this.f.taxAmount.setValue(0);
    var subTotal = 0;
    if (this.arrItems.length > 0) {
      this.arrItems.forEach(element => {

        subTotal += Number(element["quantity"] * element["cost"]);
      });
      this.f.subTotal.setValue(subTotal);
      this.f.taxAmount.setValue((this.f.subTotal.value * Number(this.taxPercent)) / 100);
      this.f.grandTotal.setValue(this.f.taxAmount.value + this.f.subTotal.value);
    }

    this.arrtaxes = [];
    this.arrtaxes.push({ amount: this.f.taxAmount.value, poTax: "VAT", poTaxID: 1, percentage: this.taxPercent,type:null });
  }


  UpdateQty(newItem) {
    if (newItem.cost > 0) {
      let updateItem = this.arrItems.find(this.findIndexToUpdate, newItem.Cost);
      let index = this.arrItems.indexOf(updateItem);

      this.arrItems[index] = newItem;

      this.CalculateSummary();
    }
    if (newItem.cost <= 0) {
      this.alertService.error("Item rate must be greater than 0.");
      newItem.cost = newItem.cost * -1;
      return;
    }
  }

  findIndexToUpdate(newItem) {
    return newItem.cost === this;
  }

  generatePO() {
    this.purchaseorderService.generatePOno(this.f.brandID.value).subscribe((res: any) => {
      this.f.poCode.setValue(res.code);
    });
  }

  refreshDetailObj() {
    this.poDetails.cost = 0;
    this.poDetails.quantity = 1;
    this.poDetails.conversionRate = 1;
  }
  isApplyTax(obj) {
    if (obj.target.checked == true) {
      this.taxPercent = 15;
    }
    else {
      this.taxPercent = 0;
    }
    this.CalculateSummary();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      const search = item['name'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) !== -1);
    });

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      const search = item['SKU'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) !== -1);
    });


    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

  SearchItem(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.barcode.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.sku.toLocaleLowerCase().indexOf(term) > -1;
  }
}
