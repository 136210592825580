<main class="views container">
  <div class="page">
    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Location Summary' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header">
            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">
              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,data$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',data$)">Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body position-relative">
      <div class="preview-filter-data" dir="ltr">{{selectedReport?.dateRange}}</div>
      <div class="preview-filter-data" dir="ltr">{{selectedReport?.locations}}</div>
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">

        <div class="tile-body p-0 table-responsive hrzScroll salesOrderDetail">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th width="5%">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="allSelected" (change)="bulkSelection($event)" />
                </th>
                <th>
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" ngbDropdownToggle>
                      <span>{{'Location' | translate }}</span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                         class="dropdown-header-filter-body">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllLocations" (ngModelChange)="selectAllLocationFilters($event,locations)">
                        <label class="form-check-label font-blue" (click)="selectAllLocationFilters($event,locations)">
                          {{'All Locations' | translate}}
                        </label>
                      </div>
                      <div *ngFor="let location of locations">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input"
                                 [(ngModel)]="location.isChecked" (ngModelChange)="filterLocations()">
                          <label class="form-check-label" (click)="filterLocations()">
                            {{location.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th sortable="Day" (sort)="onSort($event)">{{'City' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="OrderCount" (sort)="onSort($event)">{{'Order Count' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="OrderAverage" (sort)="onSort($event)">{{'Order Average' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Cash" (sort)="onSort($event)">{{'Cash' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>

                @if((data$ | async); as data){
                @if(data[0]?.ReportVersion && data[0]?.ReportVersion == 'V2'){
                <th sortable="Mada" (sort)="onSort($event)">{{'Mada' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Visa" (sort)="onSort($event)">{{'Visa' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="MasterCard" (sort)="onSort($event)">{{'MasterCard' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="AmericanExpress" (sort)="onSort($event)">{{'AmericanExpress' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="OtherCard" (sort)="onSort($event)">{{'OtherCard' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                @for(option of data[0]?.Columns; track option.Column){
                <th>{{option.Column}} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                }
                }
                @else{
                <th sortable="Card" (sort)="onSort($event)">{{'Card' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>

                }


                }
                <th sortable="Aggregator" (sort)="onSort($event)">{{'Aggregator' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="BankTransfer" (sort)="onSort($event)">{{'Bank Transfer' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Discount" (sort)="onSort($event)">{{'Discount' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Complimentary" (sort)="onSort($event)">{{'Complimentary' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Void" (sort)="onSort($event)">{{'Void' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Hold" (sort)="onSort($event)">{{'Hold' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Refund" (sort)="onSort($event)">{{'Refund' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="GrandTotal" (sort)="onSort($event)">{{'Sales' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="GrandTotal" (sort)="onSort($event)">{{'Sales With Tax' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Tax" (sort)="onSort($event)">{{'Tax' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data$ | async ">
                <td>
                  <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" (change)="checkSelection(data$)" />
                </td>
                <td>{{item.LocationName}}</td>
                <td> {{item.City }} </td>
                <td> {{item.OrderCount }} </td>
                <td> {{item.OrderAverage | number:'1.2-2'}} </td>
                <td>  {{item.Cash | number:'1.2-2' }} </td>

                @if(item.ReportVersion && item.ReportVersion === 'V2'){
                <td>{{item.Mada | number:'1.2-2' }} </td>
                <td>{{item.Visa | number:'1.2-2' }} </td>
                <td>{{item.MasterCard | number:'1.2-2' }} </td>
                <td>{{item.AmericanExpress | number:'1.2-2' }} </td>
                <td>{{item.OtherCard | number:'1.2-2' }} </td>
                @for(agg of item.Aggregators; track agg.LocationName){
                <td>  {{agg.GrandTotal | number:'1.2-2' }} </td>
                }
                }
                @else{
                <td>{{item.Card | number:'1.2-2' }} </td>

                }

                <td>  {{item.Aggregator | number:'1.2-2' }} </td>
                <td>  {{item.BankTransfer === undefined ? 0.00 : item.BankTransfer | number:'1.2-2' }} </td>
                <td> {{item.Discount | number:'1.2-2' }} </td>
                <td> {{item.Complimentary | number:'1.2-2' }} </td>
                <td> {{item.Void | number:'1.2-2' }} </td>
                <td>  {{item.Hold | number:'1.2-2' }} </td>
                <td>{{item.Refund | number:'1.2-2' }} </td>
                <td> {{item.Sales | number:'1.2-2' }} </td>
                <td> {{item.GrandTotal | number:'1.2-2' }} </td>
                <td>{{item.Tax | number:'1.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-lg-12" style="margin-top:1%">
            {{'Showing' | translate }}
            <strong>{{(total$ | async)}}</strong>
            {{'results' | translate }}
          </div>
        </div>
      </section>
      <!--<div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <p class="pagination-count">
            Showing
            <strong>{{(service.pageSize * service.page)-9}}</strong> to
            <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> of
            <strong>{{(total$ | async)!}}</strong> results
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>-->
    </div>
  </div>
</main>

