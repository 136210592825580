
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { CategoryService } from 'src/app/_services/category.service';
import { AddcategoryComponent } from './addcategory/addcategory.component';
import { Category } from 'src/app/_models/Cateogry';
import { ExcelService } from 'src/app/_services/excel.service';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { CategoryUploaderComponent } from '../category-uploader/category-uploader.component';
import { TrackEventsService } from '../../_services/track-events.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  providers: [ExcelService]
})
export class CategoryComponent implements OnInit {
  data$: Observable<Category[]>;
  oldData: Category[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  submit: boolean;
  excelData = [];
  selctedLang = 'en';
  StatusList = [];
  breakCache = Math.floor((Math.random() * 100) + 1);
  activeCount = 0;
  inActiveCount = 0;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: CategoryService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService,
    private translate: TranslateService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {

  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Category',type);    
  }
  getBrandData() {
    this.breakCache = Math.floor((Math.random() * 100) + 1);
    this.service.headerFilter = { status: [''] };
    this.service.getCategory(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

      }
    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddcategoryComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Category");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(category) {
    
    const modalRef = this.modalService.open(AddcategoryComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Category");
    modalRef.componentInstance.SelectedID = category.categoryID;
    modalRef.componentInstance.SelectedName = category.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Category has been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(id, rowVersion) {

    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Category has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  SetStatus(id, statusID, rowVersion) {
    this.service.SetStatus(parseInt(id), parseInt(statusID), rowVersion).subscribe((res: any) => {
      if (parseInt(statusID) === 1)
        this.alertService.success("Category has been Actived");
      else
        this.alertService.success("Category has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }

  public bulkSelection($event) {
    this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, category) {
    this.service.categories.forEach((item, index) => {
      if (item.categoryID == category.categoryID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {
          
          this.excelData.splice(index, 1);
        }
      }
    });
  }
  
  private getExportData() {
    let selected: Category[];

    selected = [];
    this.service.categories.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.categories : selected;
    return selected;
  }

  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Category has been Deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }

  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
  import() {
    const modalRef = this.modalService.open(CategoryUploaderComponent, { size: 'xl', windowClass: 'small-modal' });
    modalRef.result.then((result) => {
    }, (r) => {
      this.getBrandData();
    });
  }

  checkSelection() {
    this.activeCount = this.service.categories.filter(e => e.selected === true && e.statusID ===1).length;
    this.inActiveCount = this.service.categories.filter(e => e.selected === true && e.statusID === 2).length;
    return this.activeCount + this.inActiveCount; 
  }
  updateStatus(statusId) {
    let ids = this.service.categories.filter(e => e.selected === true).map(a => a.categoryID).join(',');
    this.service.updateBulkStatus(ids, statusId).subscribe((res: any) => {
      this.alertService.success("Status updated");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  askForDelete(statusId) {
    let count = (this.activeCount + this.inActiveCount).toString() + ' ' + this.translate.instant('rows');
    this.confirmationDialogService.confirm(this.translate.instant('Delete')+' ' + count, count.toString() )
      .then((confirmed) => {
        if (confirmed) {
          this.updateStatus(statusId);
        }
        
      })
      .catch(() => { });
  }
}

