<div id="htmlData">
  <div class="modal-header ps-4 pe-4" style="height: 120px;background-color: #e6eaeb">
    <div class="container">
      <div class="row" >
        <div class="col-lg-12">
          <img class="img-responsive img-rounded" src="assets/images/logo-full.svg" alt="User picture" />
          <img class="img-responsive img-rounded float-end" style="border: 2px solid #FFF; width: 42px; height: 42px; border-radius: 10px; margin-top: -4px;" [src]="SelectedBrand?.BrandLogo" alt="User picture" />
        </div>
      </div>
      <div class="row pt-3" >
        <div class="col-lg-12">
          <h5 class="fw-bold">
            <span>{{"Summary Report" | translate }}</span>
            <span class="float-end">{{SelectedBrand?.BrandName}}</span>
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body sales-summary-report">
    <div class="container">
      <div class="row" >
          <div class="row" dir="ltr" style="max-height:50px; display: -webkit-box;">
              <div class="input-group" style="max-width: 246px;">
                <div class="form-control" style="margin: 6px auto;text-align: center;height: 34px;border-radius: 14px 0px 0px 14px">{{startdate}}</div>
                <div class="form-control" style="margin: 6px auto;text-align: center;height: 34px;border-radius: 0px 14px 14px 0px">{{enddate}}</div>
              </div>
              <div class="selected-location mt-1" style="    font-size: 15px !important;max-width:135px !important">
                {{selectedLocationName | translate}}
              </div>
            </div>
        <section>
          <div class="row stats-card" >
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <div class="summary-sales-box relative fixheight" style="height:900px !important">
                <div class="content sales-summary" style="padding-bottom:55px">
                  <div class="tabtitle">
                    {{'Total Sales' | translate }}
                  </div>
                  <div>
                    <span class="total-sales w-75 align-middle"> {{salesSummary?.totalSales | number:'1.2-2'}} </span>
                    <span class="badge grey-badge">{{brandCurrency | translate}}</span>
                  </div>
                  <hr />
                  <div class="accordion pb-2" id="accordionPanelsStayOpen" *ngIf="salesSummary?.holdOrders !=0 || salesSummary?.voidOrders !=0">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#Unfinished" aria-controls="Unfinished">
                          {{'Unfinished orders' | translate}}
                        </button>
                      </h2>
                      <div id="Unfinished" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                          <ul>
                            <li class="py-2">
                              <div class="d-flex justify-content-between">
                                <div class="title-left font-blue">{{'Hold Orders' | translate}}</div>
                                <div class="title-right font-blue">({{salesSummary?.holdOrders}}) {{salesSummary?.holdAmount | number:'1.2-2'}}</div>
                              </div>
                            </li>
                            <li class="py-2">
                              <div class="d-flex justify-content-between">
                                <div class="title-left font-red">{{'Void' | translate}}</div>
                                <div class="title-right font-red">({{salesSummary?.voidOrders}}) {{salesSummary?.voidAmount | number:'1.2-2'}}</div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion" id="accordionPanelsStayClose">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayClose-headingOne">
                        <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#paymentType" aria-controls="paymentType">
                          {{'Payment types' | translate}}
                        </button>
                      </h2>
                      <div id="paymentType" class="accordion-collapse collapse show" aria-labelledby="panelsStayClose-headingOne">
                        <div class="accordion-body">
                          <ul class="extra-padding-min">
                            <li class="py-2">
                              <div class="d-flex justify-content-between">
                                <div class="title-left">{{'Card Payments' | translate}}</div>
                                <div class="title-right">({{salesSummary?.cardPaymentsCount}}) {{salesSummary?.cardPayments | number:'1.2-2'}}</div>
                              </div>
                            </li>
                            <li class="py-2">
                              <div class="d-flex justify-content-between">
                                <div class="title-left">{{'Cash Payments' | translate}}</div>
                                <div class="title-right">({{salesSummary?.cashPaymentsCount}}) {{salesSummary?.cashPayments | number:'1.2-2'}}</div>
                              </div>
                            </li>
                            <li class="py-2">
                              <div class="d-flex justify-content-between">
                                <div class="title-left">{{'Wallet' | translate}}</div>
                                <div class="title-right">({{salesSummary?.walletPaymentsCount}}) {{salesSummary?.walletPayments | number:'1.2-2'}}</div>
                              </div>
                            </li>
                            <li class="py-2">
                              <div class="d-flex justify-content-between">
                                <div class="title-left">{{'Aggregator' | translate}}</div>
                                <div class="title-right">({{salesSummary?.aggregatorOrders}}) {{salesSummary?.aggregatorAmount | number:'1.2-2'}}</div>
                              </div>
                            </li>
                            <li class="py-2">
                              <div class="d-flex justify-content-between">
                                <div class="title-left">{{'Bank Transfer' | translate}}</div>
                                <div class="title-right">({{salesSummary?.bankTransferOrders}}) {{salesSummary?.bankTransferAmount | number:'1.2-2'}}</div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul>
                    <li>
                      <div class="d-flex justify-content-between">
                        <div class="title-left font-red">{{'Discounts' | translate}}</div>
                        <div class="title-right font-red">({{salesSummary?.discountedOrders}}) {{salesSummary?.discountedAmount | number:'1.2-2'}}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex justify-content-between">
                        <div class="title-left font-red">{{'Refunds' | translate}}</div>
                        <div class="title-right font-red">({{salesSummary?.refundedOrders}}) {{salesSummary?.refundedAmount | number:'1.2-2'}}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex justify-content-between">
                        <div class="title-left font-red">{{'Vouchers' | translate}}</div>
                        <div class="title-right font-red">({{salesSummary?.voucherOrders}}) {{salesSummary?.voucherAmount | number:'1.2-2'}}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex justify-content-between">
                        <div class="title-left font-red">{{'Complimentary' | translate}}</div>
                        <div class="title-right font-red">({{salesSummary?.complimentaryOrders}}) {{salesSummary?.complimentaryAmount | number:'1.2-2'}}</div>
                      </div>
                    </li>
                   
                   
                  </ul>
                </div>
                <div class="footer bg-grey">
                  <div class="d-flex justify-content-between">
                    <div class="title-left">{{'Sales After Deductions' | translate}}</div>
                    <div class="title-right bold">{{salesSummary?.grandTotal}} {{brandCurrency | translate}}</div>
                  </div>
                  <div class="d-flex row justify-content-between" >
                    <div class="title-left col-6">{{'Sales Tax' | translate}}</div>
                    <div class="col-6 title-right">
                      <span>
                        {{salesSummary?.taxes | number:'1.2-2'}}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex row justify-content-between pb-2" >
                    <div class="title-left col-6 bold">{{'Net Sales' | translate}}</div>
                    <div class="title-right col-6 bold">{{salesSummary?.netSales | number:'1.2-2'}} {{brandCurrency | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <div class="summary-sales-box fixheight relative" style="height:900px !important">
                <div class="content sales-summary ">
                  <div class="tabtitle">
                    {{'Inventory Alerts' | translate }}
                  </div>
                  <ul class="extra-padding-min inventoryalerts" style="height: 530px;overflow: scroll;">
                    <li *ngFor="let item of inventoryAlerts | slice:0:10">
                      <div class="d-flex justify-content-between">
                        <div class="title-left">
                          <div class="row" >
                            <div class="col-4">
                              <div class="image-container">
                                <img class="img-responsive" [src]="item.imageURL" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                              </div>
                            </div>
                            <div class="col-8">
                              <div>{{item.name}}</div>
                              <div class="subtitle">{{item.locationName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="title-right">
                          {{item.percentage}}%
                          <circle-progress [percent]="item.percentage"
                                           [radius]="12"
                                           [space]="-2"
                                           [outerStrokeWidth]="3"
                                           [innerStrokeWidth]="2"
                                           [outerStrokeColor]="'#D31E1E'"
                                           [innerStrokeColor]="'#CDD7E2'"
                                           [animation]="true"
                                           [animationDuration]="300"
                                           [showInnerStroke]=true
                                           [showTitle]=false
                                           [showSubtitle]=false
                                           [showUnits]=false
                                           [showBackground]=false
                                           [clockwise]=false
                                           [startFromZero]=false
                                           [outerStrokeGradient]=false
                                           outerStrokeLinecap=square></circle-progress>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="footer" [hidden]="true">
                  <div class="d-flex justify-content-center">
                    <div class="title-left btn-more">{{'See More' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <div class="summary-sales-box fixheight" style="height:900px !important">
                <div class="content sales-summary">
                  <div class="tabtitle">
                    {{'Card Types' | translate }}
                  </div>
                  <ul class="extra-padding">
                    <li *ngFor="let item of cardTypes">
                      <div class="d-flex justify-content-between">
                        <div class="title-left">
                          <div class="payment-type">
                            <img *ngIf="item.name === 'Mada'" src="../assets/images/Mada_ic.svg"
                                 loading="lazy" height="20" width="60" alt="">
                            <img *ngIf="item.name === 'Visa'" src="../assets/images/Visa_ic.svg"
                                 loading="lazy" height="14" width="46" alt="">
                            <img *ngIf="item.name === 'MasterCard'" src="../assets/images/Mastercard_ic.svg"
                                 loading="lazy" height="24" width="31" alt="">
                            <img *ngIf="item.name === 'AmericanExpress'" src="../assets/images/Amex_ic.svg"
                                 loading="lazy" height="18" width="50" alt="">
                            <img *ngIf="item.name === 'StcPay'" src="../assets/images/STCPay_ic.svg"
                                 loading="lazy" height="16" width="54" alt="">
                          </div>
                          <div class="payment-type" *ngIf="item.name === 'Aggregator' || item.name === 'Other' || item.name === 'Wallet' || item.name === 'Cash' || item.name === 'Multi'">
                            {{item.name | translate}}
                          </div>
                        </div>
                        <div class="title-right">{{item.percentage}} % ({{item.paidAmount}})</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row stats-card" >
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <div class="summary-sales-box  relative">
                <div class="content sales-summary extra-padding">
                  <div class="tabtitle">
                    {{'Refunds' | translate }}
                  </div>
                  <ul class="extra-padding">
                    <li *ngFor="let item of refundReason  | slice:0:10">
                      <div class="d-flex justify-content-between">
                        <div class="title-left">
                          {{item.reason | translate}}
                        </div>
                        <div class="title-right">{{item.percentage}}%({{item.refundAmount}})</div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="footer" [hidden]="true">
                  <div class="d-flex justify-content-center">
                    <div class="title-left btn-more">{{'See More' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <div class="summary-sales-box  relative">
                <div class="content sales-summary ">
                  <div class="tabtitle">
                    {{'Most Sold Items' | translate }}
                  </div>
                  <ul class="extra-padding-min cardtypes">
                    <li *ngFor="let item of mostSoldItems  | slice:0:10" class="section">
                      <div class="row align-items-center" >
                        <div class="col-2">
                          <div class="image-container"><img [src]="item.itemImage"></div>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                          <div class="scroll-text">
                            {{selctedLang=='en'?item.name:item.altName}}
                          </div>
                        </div>
                        <div class="col-4 text-end">
                          <div>{{item.percentage}}%</div>
                          <div>({{item.amount}})</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <div class="summary-sales-box  relative">
                <div class="content sales-summary extra-padding">
                  <div class="tabtitle">
                    {{'Most Selling Order Takers' | translate }}
                  </div>
                  <ul class="extra-padding">
                    <li *ngFor="let item of orderTakers  | slice:0:10">
                      <div class="d-flex justify-content-between">
                        <div class="title-left">
                          {{item.orderTaker}}
                        </div>
                        <div class="title-right">{{item.percentage}}%({{item.amount}})</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
