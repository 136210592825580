import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ModifierService } from 'src/app/_services/modifier.service';
import { UnitService } from 'src/app/_services/unit.service';
import { ModifierRecipes } from 'src/app/_models/Modifier';
import * as myGlobals from '../../../_helpers/globals';

@Component({
  selector: 'app-addmodifier',
  templateUrl: './addmodifier.component.html',
  styleUrls: ['./addmodifier.component.css']
})
export class AddmodifierComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  RecipesList = [];
  Units = [];
  Groups = [];
  SuppliersList = [];
  public arrRecipes: ModifierRecipes[] = [];
  GroupModifiersList = [];
  InventoryTypes: Array<any> = [
    { inventoryType: "Modifier", name: 'Unit' },
    { inventoryType: "", name: 'Recipe' }
  ];
  isRetailModifier: boolean = true;
  selectedSupplierIds: string[];
  submitted = false;
  modifierForm: UntypedFormGroup;
  loading = false;
  loadingModifier = false;
  ButtonText = "Save";
  selectedgroupModifierIds: string[];
  manualSKUChangeByUser = false;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private modifierService: ModifierService,
    private unitService: UnitService
  ) {
    this.createForm();
    this.loadGroupModifiers();
    this.loadRecipes();
    this.loadUnits();
    this.loadSuppliers();
    this.loadGroups();
  }

  ngOnInit() {
    this.setSelectedModifier();
    this.generateSKU();
  }

  get f() { return this.modifierForm.controls; }
  get enableSKU() {
    if (this.f.trackInventory.value && (!this.f.sku.value || this.f.sku.value === '' || this.manualSKUChangeByUser)) {
      return true;

    }
    else {
      return false;
    }
  }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.localStorageService);
  }
  private createForm() {

    this.modifierForm = this.formBuilder.group({
      Location: new UntypedFormControl({ value: "", disabled: true }, Validators.required),
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9]*(?:[ \-,_+.;:’×“”’’!—…&()%][a-zA-Z0-9]*)*[a-zA-Z0-9]$/)]],
      alternateName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[ء-ي0-9][ء-ي0-9]*(?:[ ؛،\-_+.:×“”’’!—…&()%][ء-ي0-9]*)*[ء-ي0-9]$/)]],
      statusID: [true, Validators.required],
      price: [0, Validators.required],
      sku: [''],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      unitID: [4, Validators.required],
      supplyID: 0,
      modifierID: 0,
      rowVersion: 0,
      trackInventory: [false],
      type: ['Modifier'],
      modifierRecipes: [],
      suppliers: [''],
      cost: [0],
      groupID: [0],
      barcode: [''],
      displayOrder: [0, [Validators.required, Validators.min(1)]],
    });
  }

  private editForm(modifier) {

    this.f.name.setValue(modifier.name);
    this.f.alternateName.setValue(modifier.alternateName);
    this.f.price.setValue(modifier.price);
    this.f.barcode.setValue(modifier.barcode);

    this.f.unitID.setValue(modifier.unitID);
    this.f.supplyID.setValue(modifier.supplyID);
    this.f.type.setValue(modifier.type);
    this.f.rowVersion.setValue(modifier.rowVersion);
    this.f.statusID.setValue(modifier.statusID === 1 ? true : false);
    this.f.modifierID.setValue(modifier.modifierID);
    this.f.displayOrder.setValue(modifier.displayOrder);
    this.f.trackInventory.setValue(modifier.trackInventory);

    if (modifier.supplyID !== null && modifier.type === "") { this.isRetailModifier = false; }
    if (modifier.supplyID !== null && modifier.type === "Modifier") {

      if (modifier.suppliers && modifier.suppliers !== "") {
        const stringToConvert = modifier.suppliers;
        this.selectedSupplierIds = stringToConvert.split(',').map(Number);
        this.f.suppliers.setValue(modifier.suppliers);
      }
      this.f.sku.setValue(modifier.sku);
      this.f.cost.setValue(modifier.cost);
      if (modifier.groupID && modifier.groupID !== 0)
        this.f.groupID.setValue(modifier.groupID);
    }

    this.arrRecipes = modifier.modifierRecipes;
    this.f.modifierRecipes.setValue(this.arrRecipes);
  }

  private loadGroupModifiers() {
    this.modifierService.getGroupModifiers(this.f.brandID.value).subscribe((res: any) => {
      this.GroupModifiersList = res;
    });
  }
  generateSKU() {
    if (this.enableSKU) {
      this.loading = true;
      this.manualSKUChangeByUser = false;
      this.modifierService.generateSKU(this.f.brandID.value).subscribe((res: any) => {
        this.f.sku.setValue(res.sku);
        this.loading = false;
      });
    }
  }

  setSelectedModifier() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loading = true;
      this.f.modifierID.setValue(this.SelectedID);
      this.modifierService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loading = false;
      });
    }
  }

  onSubmit() {
    this.f.name.setValue(this.f.name.value.trim());
    this.f.alternateName.setValue(this.f.alternateName.value.trim());

    if (this.f.type.value === "Modifier" && this.f.trackInventory.value === true &&
      (this.selectedSupplierIds === undefined || this.selectedSupplierIds.toString() === "" || !this.f.groupID.value || this.f.groupID.value === "")) {

      return;
    }
    this.modifierForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();
    if (this.modifierForm.invalid) { return; }
    this.loading = true;

    this.f.modifierRecipes.setValue(this.arrRecipes);
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    
    this.f.suppliers.setValue(this.selectedSupplierIds === undefined ? "" : this.selectedSupplierIds.toString());
    if (parseInt(this.f.modifierID.value) === 0) {

      //Insert modifier
      console.log(JSON.stringify(this.modifierForm.value));
      this.modifierService.insert(this.modifierForm.value).subscribe(data => {
        this.alertService.success("Modifier has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update modifier
      this.modifierService.update(this.modifierForm.value).subscribe(data => {
        this.alertService.success("Modifier has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }

  ChangeInventoryType() {
    this.isRetailModifier = this.f.type.value === "Modifier" ? true : false;
  }

  private loadRecipes() {
    this.modifierService.getRecipes(this.f.brandID.value).subscribe((res: any) => {
      this.RecipesList = res;
    });
  }

  loadSuppliers() {
    this.modifierService.getSuppliers(this.f.brandID.value).subscribe((res: any) => {
      this.SuppliersList = res;
    });
  }

  loadGroups() {
    this.modifierService.getGroup(this.f.brandID.value).subscribe((res: any) => {
      this.Groups = res;
      if (this.Groups.length > 0)
        this.f.groupID.setValue(this.Groups[0].groupID);
    });
  }

  private loadUnits() {
    this.unitService.getUnits().subscribe((res: any) => {
      this.Units = res;
      //this.f.unitID.setValue(this.Units[0].unitID);
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  deleteRow(obj) {

    const index = this.arrRecipes.indexOf(obj);
    this.arrRecipes.splice(index, 1);
  }

  addRow(obj) {
    this.arrRecipes.push({
      modifierRecipeID: 0,
      recipeID: obj.recipeID,
      modifierID: obj.modifierID,
      name: obj.name,
      quantity: obj.quantity === undefined ? 0 : obj.quantity
    })
  }

  UpdateQty(newItem) {
    const updateItem = this.arrRecipes.find(this.findIndexToUpdate, newItem.quantity);
    const index = this.arrRecipes.indexOf(updateItem);
    this.arrRecipes[index] = newItem;
  }

  findIndexToUpdate(newItem) {
    return newItem.quantity === this;
  }
}
