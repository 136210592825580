
import { Component, ViewChild, Input } from '@angular/core';
import { ChartOptions } from '../dashboard.component';
import { ChartComponent } from 'ng-apexcharts';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { DashboardService } from '../../_services/dashboard.service';
import { timer, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { SalesTodaySummary } from 'src/app/_models/Dashboard';
import { debug } from 'console';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, DecimalPipe } from '@angular/common';


@Component({
  selector: 'app-dailystats',
  templateUrl: './dailystats.component.html',
  styleUrls: ['./dailystats.component.css']
})
export class DailystatsComponent {
  @ViewChild('dailychart', { static: true }) chart: ChartComponent;
  public todaySummaryLineOptions: Partial<ChartOptions>;
  public loadingTodaySummary = true;
  currenttime: any = new Date();
  @Input() selectedBrand: any;
  @Input() selectedLocation: any;
  @Input() selectedDate: any;
  @Input() GraphData: SalesTodaySummary;
  @Input() Currency: string = '';
  @Input() Loading: boolean= true;
  constructor(private translate: TranslateService,
    public datepipe: DatePipe,
    public decimalPipe: DecimalPipe  ) {
    this.todaySummaryLineOptions = {
      series: [],
      chart: {
        height: 200,
        type: 'area',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        },
      },
      colors: ['#EDF1F5', '#91C7C4'],
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: true,
        curve: "straight",
        colors: ['#EDF1F5', '#056D69'],
        width: 2

      },
      grid: {
        show: false,
        row: {
          opacity: 0.5
        },
      },
      xaxis: {
        //categories: categories,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        
        labels: {
          show: false,
          style: {
            fontSize: '11px',
            fontFamily: 'Inter'
          }
        }
      },
      yaxis: {
        show: false,
        labels: {
          style: {
            fontSize: '15px',
            fontFamily: 'Inter-SemiBold'
          }
        }
      },

      legend: {
        show: false
      },
      apexNoData: {
        text: this.Loading ? "Loading..." : "No Data present in the graph!",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#000000",
          fontSize: '14px',
          fontFamily: "Helvetica"
        } 
      }
    };

  }

  ngOnInit() {

    this.currenttime = new Date().setSeconds(0);
  }
  ngOnChanges() {
    if (this.GraphData !== undefined && this.GraphData !== null) {
      this.loadTodaySalesSummary();
    }
  }
  private loadTodaySalesSummary() {
    this.loadingTodaySummary = true;

    let todaySeries: number[] = [];
    let lastWeekSeries: number[] = [];
    let categories: string[] = [];



    let last = this.GraphData.lastRecord;
    let today = this.GraphData.currentRecord;
    let period = this.GraphData.period;
    let curr = this.Currency;
    // const unique = [...new Set(data.map(item => this.translate.instant(item.day)))];
    let currentUnique = [];
    let lastUnique = [];

    this.GraphData.currentrGaphKeys.forEach(e => {
      if (e.keyValue !== null && e.keyValue !== undefined && e.keyValue !== '')
        currentUnique.push(this.translate.instant(e.keyValue));
    });
    this.GraphData.lastGraphKeys.forEach(e => {
      if (e.keyValue !== null && e.keyValue !== undefined && e.keyValue !== '')
        lastUnique.push(this.translate.instant(e.keyValue));
    });

    let current = this.datepipe.transform(period.fromDate, 'dd MMM, yyy') + ' - ' + this.datepipe.transform(period.toDate, 'dd MMM, yyy');
    let previous = this.datepipe.transform(period.lastFromDate, 'dd MMM, yyy') + ' - ' + this.datepipe.transform(period.lastToDate, 'dd MMM, yyy');
    let _this = this;
    for (let i = 0; i < today.length; i++) {
      todaySeries.push(today[i].sales);
      if (today[i].time)
        categories.push(today[i].time);
      else
        categories.push(today[i].day);

      lastWeekSeries.push(last[i].sales);
    }

    this.todaySummaryLineOptions = {
      
      series: [
        {
          name: previous,
          data: lastWeekSeries
        },
        {
          name: current,
          data: todaySeries
        },


      ],
      chart: {
        height: 150,
        type: 'area',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        },
      },
      colors: ['#EDF1F5', '#91C7C4'],
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: true,
        curve: "straight",
        colors: ['#EDF1F5', '#056D69'],
        width: 2

      },
      grid: {
        show: false,
        row: {
          opacity: 0.5
        },
      },
      xaxis: {
        tooltip: {
          enabled: false
        },
        categories: categories,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            fontSize: '11px',
            fontFamily: 'Inter'
          }
        }
      },
      yaxis: {
        show: false,

        labels: {
          style: {
            fontSize: '15px',
            fontFamily: 'Inter-SemiBold'
          }
        }
      },
      legend: {
        show: false
      },
      toolTip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          const month = w.globals.labels[dataPointIndex];
          const value1 = series[1][dataPointIndex];
          const value2 = series[0][dataPointIndex];
          const color = w.globals.colors[dataPointIndex];
          const circleColor = w.globals.stroke.colors[seriesIndex]; // Use stroke color for the circle
          const xLabel = w.globals.categoryLabels[dataPointIndex]; // Use stroke color for the circle

          let perc = 0;
          if (value2 > 0)
            perc = ((value1 - value2) / value2) * 100;

          return (
            '<div class="card">' +
            '<div class= "card-header" style="background-color:white">' + xLabel +
            ((perc > 0) ? '<ngb-highlight class="btn btn-sm btn-active paid move-to-end growth px-2 m-0">' :'<ngb-highlight class="btn btn-sm btn-inactive refund paid move-to-end growth px-2 m-0">') +
            ((perc > 0) ? '<img  src="/assets/images/icons/profit-icon.png" class="h15 m-0 d-inline px-1" alt="icon">' : '<img  src="/assets/images/icons/loss-icon.png" class="h15 m-0 d-inline px-1" alt="icon">') +
            _this.decimalPipe.transform(perc,'1.2-2') + ' % </ngb-highlight>' +
            '</div>' +
            '<div class="card-body"> ' +
            '<div class="order-badge series-1"></div>' +
            '<span class="series-1-value">' + value1 + '</span>' +
            '&emsp;<small class="series-1-text">' + current + '</small><br/>' +

            '<div class="order-badge series-2"></div>' +
            '<span class="series-2-value">' + value2 + '</span>' +
            '&emsp;<small class="series-2-text">' + previous + '</small>' +
            '</div></div>'


          );
        }
      },
       apexNoData: {
        text: this.Loading ? "Loading..." : "No Data present in the graph!",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#000000",
          fontSize: '14px',
          fontFamily: "Helvetica"
        }
      }

    };
    this.loadingTodaySummary = false;


  }


}
