<div class="mnt-header position-relative suppliers order-list product-list">


  <div class="row align-items-center g-2 mb-4 tCustom-header margin-top-minus-30" *ngIf="(service.suppliers?.length > 0)">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-1 hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Supplier' | translate }} </h5>
    </div>
    <div class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
      @if(checkSelection(); as count){
      @if(count > 1){
      <div class="col-md-auto row bulk-update align-items-center gy-2">
        <div class="col-md-auto">
          <a class="form-control text-center selected-location selected-items">
            <span style="display:block;width:auto">{{count}} {{'Selected' | translate}} </span>
          </a>
        </div>
        @if(inActiveCount>0 ){
        <div class="col-md-auto" *hasPermission="['Inventory','Inventory Setup','Supplier', 'Deactivate']">
          <button type="button" (click)="updateStatus(1)" class=" btn-primary cutom-button btn-activate">
            {{'Activate' | translate }}
          </button>
        </div>
        }
        @if(activeCount>0 ){
        <div class="col-md-auto" *hasPermission="['Inventory','Inventory Setup','Supplier', 'Deactivate']">
          <button type="button" (click)="updateStatus(2)" class=" btn-primary cutom-button btn-activate">
            {{'Deactivate' | translate }}
          </button>
        </div>
        }
        <div class="col-md-auto" *hasPermission="['Inventory','Inventory Setup','Supplier', 'Delete']">
          <button type="button" (click)="askForDelete(3)" class=" btn-primary cutom-button btn-delete fw-bold">
            <img src="/assets/images/icons/v2/Delete.svg" />
            <span style="margin: 0px 0px 0px 3px">{{'Delete' | translate }}</span>
          </button>
        </div>
      </div>
      }
      }
    </div>
    <div class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
      <div class="row justify-content-end g-2">
        <div class="col-auto">
          <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
        </div>
        <div class="col-auto">
          <div class="" *hasPermission="['Inventory','Inventory Setup','Supplier', 'Export']">
            <div ngbDropdown class="nav-item" placement="bottom">

              <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
              </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
                <button ngbDropdownItem (click)="export('csv')">CSV</button>
                <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto" *hasPermission="['Inventory','Inventory Setup','Supplier', 'Insert']">
          <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
            {{'+ Add Supplier' | translate }}
          </button>
        </div>
      </div>
    </div>

      </div>

      <div *ngIf="(service.suppliers?.length > 0)">
        <div class="position-relative">
          <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
            <div class="tile-body p-0 table-responsive text-nowrap">
              <table class="table table-striped text-start">
                <thead>
                  <tr class="table-header">
                    <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                    <th width="20%" sortable="name" (sort)="onSort($event)">{{'Suplier Name' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="20%" sortable="email" (sort)="onSort($event)">{{'Email' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="15%" sortable="contactNo" (sort)="onSort($event)">{{'Phone No#' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="10%">



                      <!-- City Filter -->
                      <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                        <button class="btn btn-outline-filter" id="ddlCityFilter" ngbDropdownToggle>
                          <span>{{SelectedCity | translate}} </span>
                          <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                        </button>
                        <div ngbDropdownMenu aria-labelledby="ddlCityFilter dropdown-header-filter-body"
                             class="dropdown-header-filter-body ddl-width-sm">
                          <div>
                            <div class="form-check" ngbDropdownToggle>
                              <input type="radio" class="form-check-input" [(ngModel)]="SelectedCity" value="All Cities"
                                     name="cityOption" id="dropdownCity_all" (change)="selectAllFilters($event,CityList)">
                              <label class="form-check-label font-blue" for="dropdownCity_all"
                                     (click)="selectAllFilters($event,CityList)">
                                {{'All Cities' | translate}}
                              </label>
                            </div>
                          </div>
                          <div *ngFor="let city of CityList; let i = index">
                            <div class="form-check" ngbDropdownToggle>
                              <input type="radio" class="form-check-input" name="cityOption" [value]="city.name"
                                     id="dropdownCity_{{i+1}}" [(ngModel)]="SelectedCity" (ngModelChange)="filters()">
                              <label class="form-check-label" for="dropdownCity_{{i+1}}" (click)="filters()">
                                {{city.name}}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </th>
                    <th width="15%" sortable="country" (sort)="onSort($event)">{{'Country' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="10%" class="text-center">

                      <!-- Status Filter -->
                      <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                        <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                          <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                          <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                        </button>
                        <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                                   name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                            <label class="form-check-label font-blue" for="dropdownStatus_all"
                                   (click)="selectAllFilters($event,StatusList)">
                              {{'All Status' | translate}}
                            </label>
                          </div>
                          <div *ngFor="let item of StatusList; let i = index">
                            <div class="form-check" ngbDropdownToggle>
                              <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                              <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                                <!-- {{item.name}} -->

                                <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                               class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                                </ngb-highlight>


                              </label>
                            </div>
                          </div>
                        </div>
                      </div>


                    </th>
                    <th width="5%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let supplier of data$ | async " editRow>
                    <td class="text-center">
                      <input type="checkbox" class="form-check-input" (change)="chkSelection($event,supplier)" [(ngModel)]="supplier.selected" />
                    </td>
                    <td (click)="Edit(supplier)" class="name-blue"> {{supplier.name}} </td>
                    <td (click)="Edit(supplier)"> {{supplier.email}} </td>
                    <td (click)="Edit(supplier)"> {{supplier.contactNo}} </td>
                    <td (click)="Edit(supplier)"> {{supplier.city}} </td>
                    <td (click)="Edit(supplier)"> {{supplier.country}} </td>
                    <td (click)="Edit(supplier)" class="text-center">
                      <ngb-highlight [ngClass]="{'btn-active': supplier.statusID === 1, 'btn-inactive':supplier.statusID !== 1 }" class="btn btn-sm" [result]="(supplier.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td>
                      <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                        <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                      </div>
                      <ng-template #popContent>
                        <p class="dropdown-heading-mini">{{'SUPPLIER' | translate }} </p>
                        <a *hasPermission="['Inventory','Inventory Setup','Supplier', 'Edit']" (click)="Edit(supplier)">{{'Edit' | translate }} </a>
                        <ng-container *ngIf="supplier.statusID === 1">
                          <a *hasPermission="['Inventory','Inventory Setup', 'Supplier', 'Deactivate']" (click)="SetStatus(supplier)">{{'Deactivate' | translate }}</a>
                        </ng-container>
                        <ng-container *ngIf="supplier.statusID === 2">
                          <a *hasPermission="['Inventory','Inventory Setup', 'Supplier', 'Deactivate']" (click)="SetStatus(supplier)">{{'Activate' | translate }}</a>
                        </ng-container>
                        <a *hasPermission="['Inventory','Inventory Setup','Supplier', 'Delete']" class="selected-inner-text color-danger" (click)="openConfirmationDialog(supplier.supplierID, supplier.rowVersion,supplier.name)">
                          {{'Delete' | translate }}
                          <img class="dots-list" src="assets/images/icons/v2/trash.svg" />
                        </a>

                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
        <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <p class="pagination-count">
              {{'Showing' | translate }}
              <strong>{{(service.pageSize * service.page)-9}}</strong>{{'to' | translate }}
              <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>{{'of' | translate }}
              <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
              <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
              <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
              <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
              <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
          </div>
        </div>


      </div>


      <div *ngIf="(service.suppliers?.length == 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Supplier yet' | translate }}</strong>
          <p class="text-center width-375">{{'Create New supplier.' | translate}}</p>
        </div>
        <div class="items-flex">
          <div class="create-btn mlmr-10">
            <div>
              <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
                {{'+ Create New Supplier' | translate }}
              </button>
            </div>
          </div>


        </div>
      </div>



      <div *ngIf="(service.suppliers?.length > 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Record Found' | translate }}</strong>
          <p class="text-center width-375">{{'Create supplier for item.' | translate}}</p>
        </div>
      </div>

    </div>
