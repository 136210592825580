@if(!Loading){
<div class="tile-body" #dailychart>

  <apx-chart [series]="todaySummaryLineOptions?.series"
             [chart]="todaySummaryLineOptions?.chart"
             [markers]="todaySummaryLineOptions?.markers"
             [colors]="todaySummaryLineOptions?.colors"
             [dataLabels]="todaySummaryLineOptions?.dataLabels"
             [stroke]="todaySummaryLineOptions?.stroke"
             [title]="todaySummaryLineOptions?.title"
             [grid]="todaySummaryLineOptions?.grid"
             [xaxis]="todaySummaryLineOptions?.xaxis"
             [yaxis]="todaySummaryLineOptions?.yaxis"
             [legend]="todaySummaryLineOptions?.legend"
             [tooltip]="todaySummaryLineOptions?.toolTip">

  </apx-chart>
</div>
<div style="width: 100%; display: block; margin-bottom: 25px;  margin-top: -10px;height: 21px;">
  <!--<div style="float: left; text-align: left;  width: 40%; font-size: 12px;">00:00</div>-->
  <div style="float: right; width: 40%;text-align: right;">
    <label class="tab-chart-time">{{currenttime | date: "hh:mm a"}}</label>
    <label class="tab-chart-status">{{'Now' | translate }}</label>
  </div>
</div>
}
@else{
<div>.......{{'Loading' | translate }}</div>
}
