<form [formGroup]="brandForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedItemName === '') ? 'New Brand' :SelectedItemName) | translate}}</h4>
    <button [disabled]="loading"  class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Brand',(SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8 mt-5">
          <div class="row">
            <div class="form-group col-lg-7 col-sm-7">
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="brandName">{{'Name in English' | translate }} </label>
                  <input type="text" class="form-control" id="brandName" placeholder="" formControlName="brandName">
                  <div *ngIf="f.brandName.invalid && (f.brandName.dirty || f.brandName.touched)" class="alert alert-danger">
                    <div *ngIf="f.brandName.errors.required">
                      {{'Name is required' | translate }} .
                    </div>
                    <div *ngIf="f.brandName.errors.pattern">
                      {{ 'English Brand Name Pattern' | translate }}.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="altBrandName">{{'Name in Arabic' | translate }} </label>
                  <input type="text" class="form-control" id="altBrandName" placeholder="" formControlName="altBrandName">
                  <div *ngIf="f.altBrandName.invalid && (f.altBrandName.dirty || f.altBrandName.touched)" class="alert alert-danger">
                    <div *ngIf="f.altBrandName.errors.required">
                      {{'Arabic Name is required' | translate }}.
                    </div>
                    <div *ngIf="f.altBrandName.errors.pattern">
                      {{ 'Arabic Brand Name Pattern' | translate }}.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="additionalId">{{'Additional ID' | translate }} </label>
                  <select class="custom-select form-control" formControlName="additionalID" (change)="additionalIDChange()">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of AdditionalIDOptions">
                        {{option.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="f.additionalID.value !== 0 && f.additionalID !== undefined">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="crn">{{getAdditionalOptionName(f.additionalID.value) | translate }} <span *ngIf="f.additionalID.value !== 0 && f.additionalID !== undefined">*</span> </label>
                  <input type="text" class="form-control" id="CRN" placeholder="" formControlName="CRN">
                  <div *ngIf="f.additionalID.value !== 0 && f.CRN.invalid && (f.CRN.dirty || f.CRN.touched)" class="alert alert-danger">
                    <div *ngIf="f.CRN.errors.required">
                      {{ getAdditionalOptionName(f.additionalID.value) + ' is required' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="industry">{{'Industry' | translate }} </label>
                  <select class="custom-select form-control" formControlName="industryID">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of Industries">
                      {{option.name}}
                    </option>
                  </select>
                  <div *ngIf="f.industryID.invalid && (f.industryID.dirty || f.industryID.touched)" class="alert alert-danger">
                    <div *ngIf="f.industryID.errors.required">
                      {{'Industry is required' | translate }} .
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="currency">{{'Currency' | translate }} </label>
                  <select class="custom-select form-control" formControlName="currency">
                    <option [ngValue]="option.id"
                            selected="option.id === id" *ngFor="let option of Currencies">
                      {{option.name}}
                    </option>
                  </select>
                  <div *ngIf="f.currency.invalid && (f.currency.dirty || f.currency.touched)" class="alert alert-danger">
                    <div *ngIf="f.currency.errors.required">
                      {{'Currency is required' | translate }}.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="industry">{{'Additional Language' | translate }} </label>
                  <select class="custom-select form-control" formControlName="language">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of Languages">
                      {{option.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="industry">{{'License' | translate }} </label>
                  <select class="custom-select form-control" formControlName="licenseID">
                    <option [ngValue]="option.licenseID" [disabled]=option.maped
                            selected="option.licenseID == licenseID" *ngFor="let option of Licenses">
                      {{option.licenseNo}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group col-lg-5 col-sm-5">
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <app-imageupload></app-imageupload>
                </div>
                <!-- <div class="form-group col-lg-12 col-sm-12">
                    <img src="https://marnpossastorage.blob.core.windows.net:443/marnpos-v3-uat/1637120946802870081_80d061c6-c31b-4b10-a31d-f502769ebaa1.png" class="file-upload-img" alt="Item image" width="180" height="180">
                    <button class="file-upload-btn">upload</button>
                </div> -->
              </div>
              <!--<div class="row">
                <div class="form-group col-lg-8">
                  <label for="Item Status">{{'Brand Status' | translate }} </label>
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" checked id="statusID" formControlName="statusID">
                    <label class="form-check-label" for="statusID"></label>
                  </div>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2"></div>
  </div>
</form>
