<div class="mnt-header position-relative">


  <div class="row align-items-center g-2 mb-4 tCustom-header margin-top-minus-20 align-items-center g-2" *ngIf="(service.subcategory?.length > 0)">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-2 hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Sub Category' | translate }} </h5>
    </div>
    <div class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-4">
      @if(checkSelection(); as count){
      @if(count > 1){
      <div class="col-md-auto row bulk-update align-items-center gy-2">
        <div class="col-md-auto">
          <a class="form-control text-center selected-location selected-items">
            <span style="display:block;width:auto">{{count}} {{'Selected' | translate}} </span>
          </a>
        </div>
        @if(inActiveCount>0 ){
        <div class="col-md-auto" *hasPermission="['Products','Categorisation','Sub Category', 'Deactivate']" >
          <button type="button" (click)="updateStatus(1)" class=" btn-primary cutom-button btn-activate">
            {{'Activate' | translate }}
          </button>
        </div>
        }
        @if(activeCount>0 ){
        <div class="col-md-auto" *hasPermission="['Products','Categorisation','Sub Category', 'Deactivate']" >
          <button type="button" (click)="updateStatus(2)" class=" btn-primary cutom-button btn-activate">
            {{'Deactivate' | translate }}
          </button>
        </div>
        }
        <div class="col-md-auto" *hasPermission="['Products','Categorisation','Sub Category', 'Delete']" >
          <button type="button" (click)="askForDelete(3)" class=" btn-primary cutom-button btn-delete fw-bold">
            <img src="/assets/images/icons/v2/Delete.svg" />
            <span style="margin: 0px 0px 0px 3px">{{'Delete' | translate }}</span>
          </button>
        </div>
      </div>
      }
      }
    </div>
    <div class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
      <div class="row justify-content-end g-2">
        <div class="col-auto">
          <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
        </div>
        <div class="col-auto">
          <div class="">
            <div ngbDropdown class="nav-item" placement="bottom">

              <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
              </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
                <button ngbDropdownItem (click)="export('csv')">CSV</button>
                <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="" *hasPermission="['Products','Categorisation','Sub Category','Import']">
            <div ngbDropdown class="nav-item" placement="bottom">
              <button class="profile btn export-btn btn-lg form-control" (click)="import()">
                <img src="assets/images/icons/v2/export.svg" class="mr-1 rotate-180" />&nbsp; <span>{{'Import' | translate }} </span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
            {{'+ Add Sub Category' | translate }}
          </button>
        </div>
      </div>
    </div>



  </div>


      <div *ngIf="(service.subcategory?.length > 0)">
        <div class="position-relative">
          <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">



            <div class="tile-body p-0 table-responsive text-nowrap">
              <table class="table table-striped text-start">
                <thead>
                  <tr class="table-header">
                    <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                    <th width="10%" class="text-center"><img src="/assets/images/icons/camera-icon.svg" alt=""></th>
                    <th width="20%" sortable="name" (sort)="onSort($event)">{{'Sub Category' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="15%" sortable="categoryName" (sort)="onSort($event)">{{'Category' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="20%" sortable="description" (sort)="onSort($event)">{{'Description' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="15%" sortable="displayOrder" (sort)="onSort($event)">{{'Display Order' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="10%">

                      <!-- Status Filter -->
                      <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                        <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                          <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                          <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                        </button>
                        <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                                   name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                            <label class="form-check-label font-blue" for="dropdownStatus_all"
                                   (click)="selectAllFilters($event,StatusList)">
                              {{'All Status' | translate}}
                            </label>
                          </div>
                          <div *ngFor="let item of StatusList; let i = index">
                            <div class="form-check" ngbDropdownToggle>
                              <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                              <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                                <!-- {{item.name}} -->


                                <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                               class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                                </ngb-highlight>


                              </label>
                            </div>
                          </div>
                        </div>
                      </div>


                    </th>
                    <th width="5%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subcategory of data$ | async " editRow>
                    <td class="text-center">
                      <input type="checkbox" class="form-check-input" [(ngModel)]="subcategory.selected" />
                    </td>
                    <td (click)="Edit(subcategory)" class="text-center">
                      <img *ngIf="subcategory.subImage !== ''" [src]="subcategory.subImage+'?refreshCache='+breakCache" class="table-img" alt="" />
                      <div *ngIf="subcategory.subImage === ''" class="image-replace"></div>
                    </td>
                    <td (click)="Edit(subcategory)" class="name-blue"> {{selctedLang=='en'?subcategory.name:subcategory.alternateName}}  </td>
                    <td (click)="Edit(subcategory)"> {{selctedLang=='en'?subcategory.categoryName:subcategory.alternateCategoryName}} </td>
                    <td (click)="Edit(subcategory)"> {{subcategory.description}} </td>
                    <td>{{subcategory.displayOrder}}</td>
                    <td (click)="Edit(subcategory)" class="text-center">
                      <ngb-highlight [ngClass]="{'btn-active': subcategory.statusID === 1, 'btn-inactive':subcategory.statusID !== 1 }" class="btn btn-sm" [result]="(subcategory.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td class="text-center">
                      <div class="doted-list-action text-center" [placement]="['left', 'right']" [ngbPopover]="popContent">
                        <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                      </div>
                      <ng-template #popContent>
                        <p class="dropdown-heading-mini">{{'SUB-CATEGORIES' | translate }}</p>
                        <a *hasPermission="['Products','Categorisation','Sub Category', 'Edit']" (click)="Edit(subcategory)">{{'Edit' | translate }}</a>
                        <ng-container *ngIf="subcategory.statusID === 1">
                          <a *hasPermission="['Products','Categorisation','Sub Category', 'Deactivate']" (click)="SetStatus(subcategory.subCategoryID,2, subcategory.rowVersion)">{{'Deactivate' | translate }}</a>
                        </ng-container>
                        <ng-container *ngIf="subcategory.statusID === 2">
                          <a *hasPermission="['Products','Categorisation','Sub Category', 'Deactivate']" (click)="SetStatus(subcategory.subCategoryID,1, subcategory.rowVersion)">{{'Activate' | translate }}</a>
                        </ng-container>
                        <a *hasPermission="['Products','Categorisation','Sub Category', 'Delete']" class="selected-inner-text color-danger" (click)="openConfirmationDialog(subcategory.subCategoryID, subcategory.rowVersion,selctedLang=='en'?subcategory.name:subcategory.alternateName)">
                          {{'Delete' | translate }}
                          <img class="dots-list" src="assets/images/icons/v2/trash.svg" />
                        </a>

                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
        <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">

          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
            <p class="pagination-count">
              <strong class="p-4">
                {{'Showing' | translate }} {{(service.pageSize * service.page)-9}} {{'of' | translate }} {{(total$ | async)!}} {{'results' | translate }}
              </strong>
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
              <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
              <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
              <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
              <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
          </div>

        </div>
      </div>




      <div *ngIf="(service.subcategory?.length == 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Product Sub Category yet' | translate }}</strong>
          <p class="text-center width-375">{{'Create sub-categories for category.' | translate}}</p>
        </div>
        <div class="items-flex">
          <div class="create-btn mlmr-10">
            <div>
              <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
                {{'+ Create New Sub Category' | translate }}
              </button>
            </div>
          </div>
          <div class="" *hasPermission="['Products','Categorisation','Sub Category','Import']">
            <div ngbDropdown class="nav-item" placement="bottom">
              <button class="profile btn export-btn btn-lg form-control" (click)="import()">
                <img src="assets/images/icons/v2/export.svg" class="mr-1 rotate-180" />&nbsp; <span>{{'Import' | translate }} </span>
              </button>
            </div>
          </div>

        </div>
      </div>



      <div *ngIf="(service.subcategory?.length > 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Record Found' | translate }}</strong>
          <p class="text-center width-375">{{'Create sub-categories for category.' | translate}}</p>
        </div>
      </div>


    </div>
