
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { SalesOrderType } from 'src/app/_models/GeneratedReport';
import { SalesOrderTypeReportService } from 'src/app/_services/sales-ordertype-report.service';
import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import { map, tap } from 'rxjs/operators';
import { SortColumn } from '../../../_directives/sortable.directive';


@Component({
  selector: 'app-salesordertype',
  templateUrl: './salesordertype.component.html',
  styleUrls: ['./salesordertype.component.css']
})
export class SalesordertypeComponent implements OnInit {
  data$: Observable<SalesOrderType[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  locations = [];
  checkAllLocations: boolean = false;
  submit: boolean;
  selectedReport: any;
  showLocationColumn$: Observable<boolean>;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  sortColumn: SortColumn;
  sortDirection: string;

  constructor(
    public service: SalesOrderTypeReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router:Router,
    private printService: PrintService,
    private alertService: AlertService) {


    this.loading$ = service.loading$;
    this.submit = false;


    this.selectedReport = history.state;
    console.log(this.selectedReport);
 
   
  }

  SalesOrderTypes : any;
  ngOnInit() 
  {
   setTimeout(() => {
   this.getReportData(this.selectedReport.dataURL);

   }, 1000);

  }

  getReportData(dataURL) {
    this.service.getSalesOrderTypeReport(dataURL);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    console.log(this.data$);

    this.showLocationColumn$ = this.data$.pipe(
      map(data => {
        let shouldShow: boolean = false; 
        if (data) {
          shouldShow = data.some(order => order.LocationName);
        }
        return shouldShow;
      })
    );

    this.service.allData$.subscribe(res => {
      this.locations = [];
      console.log(this.data$);
      if (res) {
        new Set(res.map(report => report.LocationName)).forEach(e => {
          this.locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if(this.locationSubscription!=undefined)
      this.locationSubscription.unsubscribe();
  
  }

  //shouldShowLocationColumn(): boolean {
  //  return this.data$.some(order => order.LocationID > 0 && order.LocationName);
  //}

  filterLocations() {
    if (this.locations.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocations = false;
    }
    else {
      this.checkAllLocations = true;
    }
    let headerFilter = {
      locations: this.locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
    }
    this.service.headerFilter = headerFilter;
  }


  public selectAllLocationFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filterLocations();
  }
}
