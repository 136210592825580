import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbdSortableHeader, SortColumn, SortEvent } from '../../../_directives/sortable.directive';
import { ProductMix } from '../../../_models/ProductMix';
import { AlertService } from '../../../_services/alert.service';
import { DataOperationsService } from '../../../_services/data-operations.service';
import { ExcelService } from '../../../_services/excel.service';
import { ProductMixService } from '../../../_services/product-mix.service';

@Component({
  selector: 'app-product-mix',
  templateUrl: './product-mix.component.html',
  styleUrls: ['./product-mix.component.css']
})
export class ProductMixComponent implements OnInit {
  data$: Observable<ProductMix[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  showLocationColumn$: Observable<boolean>;
  locations = [];
  checkAllLocations: boolean = false;
  submit: boolean;
  selectedReport: any;
  inventoryPurchase: string ='';
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  sortColumn: SortColumn;
  sortDirection: string;
  constructor(private excelService: ExcelService,
    public service: ProductMixService,
    private router: Router,
    private alertService: AlertService, private dataOperationService: DataOperationsService) {
    this.loading$ = service.loading$;
    this.submit = false;

    this.selectedReport = history.state;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
      if (!this.selectedReport?.filters.split('|')[2] === false) {
        this.inventoryPurchase = this.selectedReport?.filters.split('|')[2].trim();
      }
    }, 1000);
  }
  getReportData(dataURL) {
    this.service.getReport(dataURL);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    this.showLocationColumn$ = this.data$.pipe(
      //tap(data => console.log('Data received:', data)), // Debugging data
      map(data => {
        const shouldShow = data.some(order => order.LocationName);
        //console.log('Should show LocationName column:', shouldShow);
        return shouldShow;
      })
    );
    
    this.service.allData$.subscribe(res => {
      this.locations = []; 
      if (res) {
        new Set(res.map(report => report.LocationName)).forEach(e => {
          this.locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }
  export(type, data): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-ProductMix', type);
  }
  private getExportData() {
    var selected: any;

    selected = [];
    this.service.report.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });

    selected = selected.length == 0 ? this.service.report : selected;
    if (!selected[0].LocationName) {
      selected.forEach(d => {
        delete d['LocationName'];
        delete d['LocationID'];       
      });

    }

    selected = this.dataOperationService.prepareExportData(selected, this.sortColumn, this.sortDirection);
    return selected;
  }
  public bulkSelection($event) {
    this.data$.forEach(i => i?.forEach(e => e.selected = $event.target.checked));
  }
  filterLocations() {
    if (this.locations.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocations = false;
    }
    else {
      this.checkAllLocations = true;
    }
    let headerFilter = {
      locations: this.locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
    }
    this.service.headerFilter = headerFilter;
  }


  public selectAllLocationFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filterLocations();
  }
}
