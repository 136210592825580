<!-- <form [formGroup]="reportFilterForm" class="form" [routerLink]="[ '/restaurant/report/inventorylogs']"> -->

<form class="form" [formGroup]="reportFilterForm">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ReportType.title | translate}}</h4>
    <button [disabled]="loading" class="btn-primary cutom-button" (click)="generateReport()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container report-filter">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12" [hidden]="showSingleDate()">
              <label for="name">{{'Date Range' | translate }}</label>
              <ngbd-datepicker-range [minDate]="(ReportType.type === 'stockMovement'?{ year: 2024, month: 06, day: 28 }:{ year: 1950, month: 1, day: 1 })"></ngbd-datepicker-range>
            </div>
          </div>
          <div class="row" [hidden]="!showSingleDate()">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="name">{{'Date' | translate }}</label>
              <ngbd-datepicker-adapter [AcceptPreviousDate]="true" [AllowEmptyDate]="true" [AcceptFutureDate]="true"></ngbd-datepicker-adapter>
            </div>
          </div>
          <div class="row" *ngIf="false">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="time">{{'Time Period' | translate }}</label>
              <app-time-period-picker></app-time-period-picker>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Locations' | translate }}</label>
              <ng-select #select [items]="LocationsList" bindLabel="name" [closeOnSelect]="true" bindValue="locationID" [multiple]="true" placeholder="{{'Location' | translate }}" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedLocationIds" formControlName="location" (ngModelChange)="locationChange()">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('location')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row form-group tiltle-bar" *ngIf="ReportType.type=='salesOrderType' || ReportType.type=='productMix' || ReportType.type=='dailySales'">
            <div class="col-lg-8 col-sm-8 col-6">
              <label>{{'Group by location' | translate }}</label>
            </div>
            <div class="col-lg-4 col-sm-4 col-6 text-right">
              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" id="isGroupedByLocation" formControlName="isGroupedByLocation">
              </div>
            </div>
            <div class="container px-4">
              <div class="row">
                <div class="row align-items-center">
                  <div class="col-lg-12 col-sm-12 col-12 col-xl-12 col-xxl-12 p-0">
                    <p class="form-check-label" for="isGroupedByLocation" *ngIf="ReportType.type=='salesOrderType'">
                      {{'Display the order types separately for each location' | translate}}
                    </p>
                    <p class="form-check-label" for="isGroupedByLocation" *ngIf="ReportType.type=='productMix'">
                      {{'Display the products mix separately for each location' | translate}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='salesDetails' || ReportType.type=='partnerDailySummary'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Partners' | translate }}</label>
              <ng-select #select [items]="PartnerList" bindLabel="name" bindValue="id" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPartners" formControlName="partner">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('partner')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='salesDetails'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Tax' | translate }}</label>
              <ng-select #select [items]="TaxList" [bindLabel]="(selectedLang == 'en' ? 'name' : 'alternateName')" [closeOnSelect]="true" bindValue="id" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedTaxIds" formControlName="tax">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('tax')">
                      {{'All Taxes' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{(selectedLang == 'en' ? item.name : item.alternateName)}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='salesDetails' || ReportType.type=='salesOrderType' || ReportType.type=='salesTransactionType'  || ReportType.type=='deletedItems' || ReportType.type=='salesRefund' || ReportType.type=='salesDiscount'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Users' | translate }}</label>
              <ng-select #select [items]="UserList" bindLabel="name" bindValue="id" [multiple]="true" [closeOnSelect]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedUserIds" formControlName="user">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('user')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='salesDetails'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Tables' | translate }}</label>
              <ng-select #select [items]="tables" bindLabel="tableTitle" bindValue="tableID" [multiple]="true" [closeOnSelect]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedTablesIds" formControlName="tables">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('tables')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.tableTitle}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='salesDetails' || ReportType.type=='salesOrderType' || ReportType.type=='salesTransactionType'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Payment Type' | translate }}</label>
              <ng-select #select [items]="paymentTypes" bindLabel="name" bindValue="id" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPaymentTypes" formControlName="paymentType">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('paymentType')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row gap-3" *ngIf="ReportType.type=='salesDetails' || ReportType.type=='salesOrderType' || ReportType.type=='salesTransactionType'">
            <div class="form-group col">
              <label for="minPrice">{{'Min Price' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" placeholder="{{'Price' | translate }}" formControlName="minPrice">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">{{'SAR' | translate }}</span>
                </div>
              </div>
            </div>
            <div class="form-group col">
              <label for="maxPrice">{{'Max Price' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" placeholder="{{'Price' | translate }}" formControlName="maxPrice">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">{{'SAR' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='salesDetails' || ReportType.type=='salesOrderType' || ReportType.type=='salesTransactionType'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Terminal' | translate }}</label>
              <ng-select #select [items]="TerminalList" bindLabel="name" bindValue="id" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedTerminals" formControlName="terminal">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('terminal')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='stockMovement'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Supplier' | translate }}</label>
              <ng-select [items]="Suppliers" bindLabel="name" bindValue="supplierID" [multiple]="true" placeholder="{{'Supplier' | translate }}" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSupplierIds" (change)="loadItems()">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name | translate}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='stock' || ReportType.type=='stockMovement'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Stock Type' | translate }}</label>
              <ng-select [items]="StockTypes" bindLabel="name" bindValue="id" [multiple]="true" placeholder="{{'Type' | translate }}" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedStockTypeIds" (change)="loadItems()">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name | translate}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='stockMovement'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Stock Items' | translate }}</label>
              <ng-select [items]="StockItems" bindLabel="name" bindValue="supplyID" [multiple]="true" placeholder="{{'Item' | translate }}" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedItemIds">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name | translate}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='inventorylogs'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Action Type' | translate }}</label>
              <ng-select [items]="ActionTypes" bindLabel="name" bindValue="id" [multiple]="true" placeholder="{{'Type' | translate }}" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedActionTypes">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name | translate}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <!--<div class="row" *ngIf="ReportType.type=='inventorylogs'">
    <div class="form-group col-lg-12 col-sm-12">
      <label for="refrence">{{'Reference Note' | translate }}</label>
      <input type="text" class="form-control" id=refrence placeholder="" [(ngModel)]="refrence" [ngModelOptions]="{standalone: true}">
    </div>
  </div>-->
          <div class="row" *ngIf="ReportType.type=='salesRefund'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Reaspn">{{'Reason Type' | translate }}</label>
              <select class="custom-select form-control" formControlName="reason">
                <option value=null>{{'All Reasons' | translate }}</option>
                <option [value]="option.reasonID"
                        *ngFor="let option of RefundReasonList">
                  {{option.description}}
                </option>
              </select>
            </div>
          </div>

          <div class="row" *ngIf="ReportType.type=='salesRefund' || ReportType.type=='salesDetails'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="orderType">{{'Order Types' | translate }}</label>
              <ng-select #select [items]="orderTypes" bindLabel="name" [closeOnSelect]="true" bindValue="orderTypeID" [multiple]="true" placeholder="{{'Order Types' | translate }}" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedOrderTypesIds" formControlName="orderType">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('orderType')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="ReportType.type=='productMix'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="subCat">{{'Sub Categories' | translate }}</label>
              <ng-select #select [items]="subCatList" bindLabel="name" [closeOnSelect]="true" bindValue="subCategoryID" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSubCatIds" formControlName="subCat">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('subCat')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row form-group tiltle-bar" *ngIf="ReportType.type=='dailySales' || ReportType.type=='locationSalesSummary' || ReportType.type=='partnerDailySummary'">
            <div class="col-lg-8 col-sm-8 col-6">
              <label>{{'Custom Time period' | translate }}</label>
            </div>
            <div class="col-lg-4 col-sm-4 col-6 col-xl-4 text-right">
              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" id="openTimePeriod" formControlName="openTimePeriod">
                <label class="form-check-label" for="trackInventory"></label>
              </div>
            </div>
            <div class="container px-4" *ngIf="f.openTimePeriod.value === true">
              <div class="row">
                <div class="row align-items-center">
                  <div class="col-lg-4 col-sm-4 col-4 col-xl-4 col-xxl-4">
                    <span for="StartingTime">{{'Starting Time' | translate}}</span>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-6 col-xl-6 col-xxl-6">
                    <input type="time" class="form-control custom-timepicker" placeholder="" formControlName="startingTime">
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-4 col-sm-4 col-4 col-xl-4 col-xxl-4">
                    <span for="StartingTime">{{'Ending Time' | translate}}</span>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-6 col-xl-6 col-xxl-6">
                    <input type="time" class="form-control custom-timepicker" placeholder="" formControlName="endingTime">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="ReportType.type=='productMix'">
            <hr />
            <div class="col-8">
              <label for="inventory">{{'Use Inventory purchases cost in Item cost' | translate }}</label>
            </div>
            <div class="col-lg-4 col-sm-4 col-4 text-right">
              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" id="includeItemCost" formControlName="includeItemCost">
                <label class="form-check-label" for="includeItemCost"></label>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="ReportType.type=='partnerDailySummary'">
            <div class="row high-safety-mode pb-3">
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                <img class="" src="assets/images/icons/reports/Info.svg" alt="Info">
              </div>
              <div class="col">
                <p class="label-bold m-0 font-15">{{'Want Detailed Partner Orders Reports?' | translate }}</p>
                <small class="m-0 font-12">{{'For more Detailed Partner Orders Report, use Sales Details Report, and designate the partner of your preference.' | translate }}</small>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
