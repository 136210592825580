
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { InventoryStock } from 'src/app/_models/GeneratedReport';
import { InventoryStockReportService } from 'src/app/_services/inventorystock.report.service';
import { Router } from '@angular/router';
import { ExcelService } from '../../../_services/excel.service';
import { SortColumn } from '../../../_directives/sortable.directive';
import { DataOperationsService } from '../../../_services/data-operations.service';

@Component({
  selector: 'app-inventorystock',
  templateUrl: './inventorystock.component.html',
  styleUrls: ['./inventorystock.component.css']
})
export class InventorystockComponent implements OnInit {
  data$: Observable<InventoryStock[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;

  submit: boolean;
  selectedReport: any;

  group = [];
  location = [];
  type = [];
  checkAllGroup: boolean = false;
  checkAllLocation: boolean = false;
  checkAllType: boolean = false;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  sortColumn: SortColumn;
  sortDirection: string;
  constructor(
    public service: InventoryStockReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private excelService: ExcelService, private dataOperationService: DataOperationsService) {

    this.loading$ = service.loading$;
    this.submit = false;

    this.selectedReport = history.state;
    console.log(this.selectedReport);
  }

  ngOnInit() {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
    }, 1000);
  }

  getReportData(dataURL) {
    this.service.headerFilter = { group: [''], location: [''], type: ['']};
    this.service.getInventoryStockReport(dataURL);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    this.service._allData$.subscribe(res => {
      this.group = [];
      this.location = [];
      this.type = [];
      if (res) {
        new Set(res.map(e => e.Group)).forEach(e => {
          this.group.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(e => e.Location)).forEach(e => {
          this.location.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(e => e.Type)).forEach(e => {
          this.type.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();

  }

  export(type, data): void {



    if (data !== undefined && data !== null && data !== "") {
      this.excelService.exportAsExcelFile(this.getExportData(data), 'Export-Stock', type);
    }
    else
      (data !== undefined) ? (data.length === 0 ? this.alertService.error("Data is not available.") : "") : "";
  }


  private getExportData(data) {
    let selected: InventoryStock[];

    selected = [];
    data.source._value.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? data.source._value : selected;
    selected = this.dataOperationService.prepareExportData(selected, this.sortColumn, this.sortDirection)
    return selected;
  }
  filterRecords() {
    if (this.group.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllGroup = false;
    }
    else {
      this.checkAllGroup = true;
    }
    if (this.location.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocation = false;
    }
    else {
      this.checkAllLocation = true;
    }
    if (this.type.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllType = false;
    }
    else {
      this.checkAllType = true;
    }

    let headerFilter = {
      group: this.group.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      location: this.location.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      type: this.type.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name })
    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filterRecords();
  }

}
