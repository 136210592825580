import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { ReportService } from 'src/app/_services/report.service';
import { NgbdDatepickerRangePopup } from 'src/app/daterangepicker/datepicker-range-popup';
import { LocationService } from '../../_services/location.service';
import { Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TimePeriodPickerComponent } from '../../time-period-picker/time-period-picker.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbdDatepickerAdapter } from '../../datepicker/datepicker-adapter';
import { OrdertypeService } from '../../_services/ordertype.service';

@Component({
  selector: 'app-reportfilter',
  templateUrl: './reportfilter.component.html'
})
export class ReportfilterComponent implements OnInit {
  @Input() SelectedID = 0;

  submitted = false;
  reportFilterForm: UntypedFormGroup;
  loading = false;
  loadingReportfilter = false;
  ButtonText = "Generate Report";
  selectedLocationIds: string[];
  selectedUserIds: string[] = null;
  selectedStockTypeIds: string[];
  selectedPaymentTypes: string[] = null;
  selectedTerminals: string[] = null;
  selectedPartners: string[] = null;
  selectedTaxIds: string[];
  selectedSubCatIds: string[] = null;
  selectedActionTypes: string[] = null;
  selectedSupplierIds: string[] = null;
  selectedItemIds: string[] = null;
  selectedOrderTypesIds: string[] = null;
  selectedTablesIds: string[] = null;
  LocationsList = [];
  RefundReasonList = [];
  subCatList = [];
  DiscountList = [];
  PriceList = [];
  TerminalList = [];
  TaxList = [];
  PartnerList = [];
  paymentTypes = [];
  UserList = [];
  orderTypes = [];
  tables = [];
  StockTypes = [
    { name: this.translate.instant("Recipe"), id: "Recipe" },
    { name: this.translate.instant("Ingredient"), id: "Ingredient" },
    { name: this.translate.instant("Modifier"), id: "Modifier" },
    { name: this.translate.instant("Item"), id: "Item" }

  ];
  ActionTypes = [
    { name: this.translate.instant("Purchase"), id: "purchase" },
    { name: this.translate.instant("Purchase (Return)"), id: "purchase_return" },
    { name: this.translate.instant("Transfer (In)"), id: "transfer_in" },
    { name: this.translate.instant("Transfer (Out)"), id: "transfer_out" },
{ name: this.translate.instant("Inventory Count"), id: "inventory_count" }
  ];
  Suppliers = [];
  StockItems = [];
  selectedLang = 'en';
  @Input() public ReportType;
  enableSingleDate: boolean = false;
  @ViewChild(NgbdDatepickerRangePopup, { static: true }) dateComp;
  @ViewChild(TimePeriodPickerComponent, { static: true }) timeComp;
  @ViewChild(NgbdDatepickerAdapter) singleDate;
  refrence: any = null;
  startdate: any;
  enddate: any;
  private locationSubscription: Subscription;
  private selectedBrand;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private reportService: ReportService,
    private locationService: LocationService,
    private orderTypeService: OrdertypeService,
    private translate: TranslateService
  ) {
    this.createForm();
    this.loadLocations();
    this.selectedLang = this.localStorageService.getSelectedLanguage();

  }


  ngOnInit() {
    this.dateComp.fromDate = null;
    this.dateComp.toDate = null;
    if (this.ReportType.type === 'salesDetails' ||
      this.ReportType.type === 'salesOrderType' ||
      this.ReportType.type === 'salesTransactionType' ||
      this.ReportType.type === 'deletedItems' ||
      this.ReportType.type === 'salesRefund' ||
      this.ReportType.type === 'salesDiscount' ||
      this.ReportType.type === 'salesTax' ||
      this.ReportType.type === 'productMix' ||
      this.ReportType.type === 'partnerDailySummary' ||
      this.ReportType.type === 'stockMovement' ||
      this.ReportType.type === 'tobaccoTax'
    ) {
      let locations = this.localStorageService.getSelectedBrand().Locations.map(function (item) {
        return item['LocationID'];
      }).filter(Boolean).map(String).toString();

      //this.loadTerminals(locations);
      this.loadPaymentTypes();
      this.loadPriceRanges();
      //this.loadPartners();

      if (this.ReportType.type === 'salesRefund') { this.loadRefundReasons(); this.loadOrderTypes() }
      if (this.ReportType.type === 'salesDetails') { this.loadOrderTypes() }
      if (this.ReportType.type === 'salesTax' || this.ReportType.type === 'salesDetails') { this.loadtaxes(); }
      if (this.ReportType.type === 'productMix') { this.loadSubCategory(); }
      if (this.ReportType.type === 'stockMovement')
      {
        this.loadSuppliers();
        this.loadItems();
      }
    }
  }
  get f() { return this.reportFilterForm.controls; }

  private createForm() {
    this.reportFilterForm = this.formBuilder.group({
      minPrice: [],
      maxPrice: [],
      location: '',
      user: '',
      paymentType: '',
      terminal: '',
      partner: '',
      reason: null,
      tax: '',
      subCat: '',
      openTimePeriod: [true],
      startingTime: ['09:00'],
      endingTime: ['23:00'],
      includeItemCost: [false],
      isGroupedByLocation: [false],
      orderType: null,
      tables:''
    });
  }
  locationChange() {
    if (this.selectedLocationIds !== undefined && this.selectedLocationIds !== null) {
      this.loadUsers(this.selectedLocationIds);

      if (this.ReportType.type === 'partnerDailySummary' || this.ReportType.type === 'salesDetails') {
        this.loadPatnersForSpecificLocations(this.selectedLocationIds);
      }
      if (this.ReportType.type === 'salesTransactionType' || this.ReportType.type === 'salesOrderType' || this.ReportType.type === 'salesDetails') {
        this.loadTerminals(this.selectedLocationIds);
      }
    }
  }
  private loadPatnersForSpecificLocations(ids) {
    if (ids.length > 0) {
      this.reportService.getPartnersForLocationsddl(parseInt(this.localStorageService.getSelectedBrand().BrandID), ids).subscribe((res: any) => {
        this.PartnerList = res;
        this.selectedPartners = null;
      });
      this.loadTables(this.selectedLocationIds);
    }
    else {
      this.PartnerList = null;
      this.selectedPartners = null;
    }
  }
  generateReport() {
    this.loading = true;
    if (this.enableSingleDate) {
      if (!this.singleDate.dateModel) {
        this.alertService.error("Please choose the report date");
        this.loading = false;
        return;
      }
    }
    else {
      if (this.dateComp.fromDate == null || this.dateComp.toDate == null) {
        this.alertService.error("Please choose the report date");
        this.loading = false;
        return;
      }
    }
    if ((this.selectedLocationIds == undefined || this.selectedLocationIds == null || this.selectedLocationIds.length === 0) && this.ReportType.type !== 'productMix') {
      this.alertService.error("Please select locations");
      this.loading = false;
      return;
    }


    if (this.enableSingleDate) {
      this.startdate = this.singleDate.dateModel.year + "-" + this.singleDate.dateModel.month + "-" + (this.singleDate.dateModel.day-1);
      this.enddate = this.singleDate.dateModel.year + "-" + this.singleDate.dateModel.month + "-" + (this.singleDate.dateModel.day-1);
    }
    else {
      this.startdate = this.dateComp.fromDate.year + "-" + this.dateComp.fromDate.month + "-" + this.dateComp.fromDate.day;
      this.enddate = this.dateComp.toDate.year + "-" + this.dateComp.toDate.month + "-" + this.dateComp.toDate.day;
    }
    if (this.timeComp) {
      if (this.timeComp.inValid === true) {
        this.alertService.error("Invalid Period");
        this.loading = false;
        return;
      }
      else {
        this.startdate += "T" + this.timeComp.fromTime.hour.toString().padStart(2, '0') + ":" + this.timeComp.fromTime.minute.toString().padStart(2, '0') + ":" + this.timeComp.fromTime.second.toString().padStart(2, '0');
        this.enddate += "T" + this.timeComp.toTime.hour.toString().padStart(2, '0') + ":" + this.timeComp.toTime.minute.toString().padStart(2, '0') + ":" + this.timeComp.toTime.second.toString().padStart(2, '0');
      }
    }

    //this.selectedLocationIds;
    //this.selectedUserIds;
    //(this.refrence == "" ? null : this.refrence);
    this.selectedUserIds = (this.selectedUserIds && this.selectedUserIds.length > 0) ? this.selectedUserIds : null;
    this.selectedStockTypeIds = (this.selectedStockTypeIds && this.selectedStockTypeIds.length > 0) ? this.selectedStockTypeIds : null;
    this.selectedPaymentTypes = (this.selectedPaymentTypes && this.selectedPaymentTypes.length > 0) ? this.selectedPaymentTypes : null;
    this.selectedTerminals = (this.selectedTerminals && this.selectedTerminals.length > 0) ? this.selectedTerminals : null;
    this.selectedTaxIds = (this.selectedTaxIds && this.selectedTaxIds.length > 0) ? this.selectedTaxIds : null;

    if (this.ReportType.type == 'stock') {
      if (this.selectedStockTypeIds == undefined || this.selectedStockTypeIds == null) {
        this.alertService.error("Please select any stock type");
        this.loading = false;
      } else {
        this.generateInventoryStock();
      }


    }
    if (this.ReportType.type == 'inventorylogs') {
      this.generateInventoryLogs();
    }

    if (this.ReportType.type == 'salesDetails') {
      //if (this.selectedUserIds == undefined || this.selectedUserIds == null) {
      //  this.alertService.error("Please select any user");
      //   this.loading = false;
      this.generateSalesDetail();
      //  }
    }

    if (this.ReportType.type === 'salesOrderType') {
      this.generateSalesOrderType();

    }

    if (this.ReportType.type === 'salesTransactionType') {
      this.generateSalesTransactionType();

    }
    if (this.ReportType.type === 'deletedItems') {
      this.generateDeletedItemsReport();

    }
    if (this.ReportType.type === 'salesRefund') {
      this.generateRefundReport();

    }
    if (this.ReportType.type === 'salesDiscount') {
      this.generateDiscountReport();

    }
    if (this.ReportType.type === 'salesTax') {
      this.generateTaxReport();

    }
    if (this.ReportType.type === 'dailySales') {
      this.generateDailySalesReport();
    }
    if (this.ReportType.type === 'locationSalesSummary') {
      this.generateLocationSalesReport();
    }
    if (this.ReportType.type === 'productMix') {
      this.generateProducMixReport();
    }
    if (this.ReportType.type === 'partnerDailySummary') {
      this.generatePartnerSummaryReport();
    }
    if (this.ReportType.type === 'stockMovement') {
      this.generateStockMovementReport();
    }
    if (this.ReportType.type === 'tobaccoTax') {
      this.generateDetailTaxReport();
    }
    
  }



  generateInventoryLogs() {
    this.reportService.generateInventoryLogs(
      parseInt(this.localStorageService.getSelectedBrand().BrandID),
      this.selectedLocationIds,
      this.selectedUserIds,
      (this.refrence == "" ? null : this.refrence),
      this.startdate,
      this.enddate, (this.selectedActionTypes && this.selectedActionTypes.length > 0) ? this.selectedActionTypes : null).subscribe((res: any) => {
        this.alertService.pending(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }
  generateInventoryStock() {
    this.reportService.generateInventoryStock(
      parseInt(this.localStorageService.getSelectedBrand().BrandID),
      this.selectedLocationIds,
      this.selectedStockTypeIds,
      this.startdate,
      this.enddate).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }

  generateSalesDetail() {

    this.f.minPrice.setValue((this.f.minPrice.value == null || this.f.minPrice.value == undefined) ? 'null' : this.f.minPrice.value);
    this.f.maxPrice.setValue((this.f.maxPrice.value == null || this.f.maxPrice.value == undefined) ? 'null' : this.f.maxPrice.value);

    this.reportService.generateSalesDetail(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      this.selectedUserIds,
      this.selectedPaymentTypes,
      this.f.minPrice.value,
      this.f.maxPrice.value,
      this.selectedTerminals,
      (!this.selectedPartners || this.selectedPartners.length == 0) ? 'null' : this.selectedPartners,
      (!this.selectedTaxIds || this.selectedTaxIds.length == 0) ? 'null' : this.selectedTaxIds,
      (!this.selectedOrderTypesIds || this.selectedOrderTypesIds.length == 0) ? 'null' : this.selectedOrderTypesIds,
      (!this.selectedTablesIds || this.selectedTablesIds.length == 0) ? 'null' : this.selectedTablesIds,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.pending(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      });
  }



  generateSalesOrderType() {
    this.reportService.generateSalesOrderType(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      this.selectedUserIds,
      this.selectedPaymentTypes,
      this.f.minPrice.value,
      this.f.maxPrice.value,
      this.selectedTerminals,
      parseInt(this.localStorageService.getSelectedBrand().BrandID),
      this.f.isGroupedByLocation.value
    ).subscribe((res: any) => {
      this.alertService.success(res.message);
      this.loading = false;
      this.activeModal.dismiss();

    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });
  }

  generateSalesTransactionType() {
    this.reportService.generateSalesTransactionType(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      this.selectedUserIds,
      this.selectedPaymentTypes,
      this.f.minPrice.value,
      this.f.maxPrice.value,
      this.selectedTerminals,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }




  generateDeletedItemsReport() {
    this.reportService.generateDeletedItems(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      this.selectedUserIds,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }



  generateRefundReport() {
    this.reportService.generateRefunds(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      this.selectedUserIds,
      this.f.reason.value,
      (!this.selectedOrderTypesIds || this.selectedOrderTypesIds.length == 0) ? 'null' : this.selectedOrderTypesIds,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      })
  }

  generateDiscountReport() {
    this.reportService.generateDiscounts(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      this.selectedUserIds,
      null,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }


  generateTaxReport() {
    this.reportService.generateTaxReport(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      this.selectedTaxIds,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }

  generateDailySalesReport() {
    this.reportService.generateDailySalesReport(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      (this.f.openTimePeriod.value === true) ? this.f.startingTime.value : 'null',
      (this.f.openTimePeriod.value === true) ? this.f.endingTime.value : 'null',
      parseInt(this.localStorageService.getSelectedBrand().BrandID),
      this.f.isGroupedByLocation.value).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }

  generatePartnerSummaryReport() {
    this.reportService.generatePartnerSummaryReport(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      (!this.selectedPartners || this.selectedPartners.length == 0) ? 'null' : this.selectedPartners,
      (this.f.openTimePeriod.value === true) ? this.f.startingTime.value : 'null',
      (this.f.openTimePeriod.value === true) ? this.f.endingTime.value : 'null',
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }
  generateDetailTaxReport()
  {
    this.reportService.generateDetailTaxReport(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.pending(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }
  generateStockMovementReport() {
    this.reportService.generateStockMovementReport(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      (!this.selectedSupplierIds || this.selectedSupplierIds.length == 0) ? 'null' : this.selectedSupplierIds,
      (!this.selectedStockTypeIds || this.selectedStockTypeIds.length == 0) ? 'null' : this.selectedStockTypeIds ,
      (!this.selectedItemIds || this.selectedItemIds.length == 0) ? 'null' : this.selectedItemIds,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.pending(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }
  generateLocationSalesReport() {
    this.reportService.generateLocationSalesReport(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      (this.f.openTimePeriod.value === true) ? this.f.startingTime.value : 'null',
      (this.f.openTimePeriod.value === true) ? this.f.endingTime.value : 'null',
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.success(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }
  generateProducMixReport() {
    this.reportService.generateProductMixReport(
      this.startdate,
      this.enddate,
      this.selectedLocationIds,
      this.selectedSubCatIds,
      this.f.includeItemCost.value,
      this.f.isGroupedByLocation.value,
      parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
        this.alertService.pending(res.message);
        this.loading = false;
        this.activeModal.dismiss();

      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }

  close() {
    this.activeModal.dismiss();
  }
  private loadLocations() {
    this.reportService.getAllLocationsddl(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.LocationsList = res;
    });
  }

  private loadUsers(id) {
    if (id.length > 0) {
      this.reportService.getAllUsersddl(parseInt(this.localStorageService.getSelectedBrand().BrandID), id).subscribe((res: any) => {
        this.UserList = res;
        this.selectedUserIds = null;
      });
    }
    else {
      this.UserList = null;
      this.selectedUserIds = null;
    }
  }
  private loadPaymentTypes() {
    this.reportService.getAllPaymentTypesddl().subscribe((res: any) => {
      this.paymentTypes = res;
    });
  }
  private loadPriceRanges() {
    //this.reportservice.getalllocationsddl(parseint(this.localstorageservice.getselectedbrand().brandid)).subscribe((res: any) => {
    //  this.pricelist = res;
    //  console.log(this.pricelist);
    //});
  }
  private loadTerminals(id) {
    if (id.length > 0) {
      this.reportService.getAllTerminalsddl(parseInt(this.localStorageService.getSelectedBrand().BrandID), id).subscribe((res: any) => {
        this.TerminalList = res;
        this.selectedTerminals = null;
      });
    }
    else {
      this.TerminalList = null;
      this.selectedTerminals = null;
    }
  }

  private loadPartners() {
    this.reportService.getAllPartnersddl(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.PartnerList = res;
    });
  }

  private loadRefundReasons() {
    this.reportService.getAllRefundReasonsddl(parseInt(this.localStorageService.getSelectedBrand().BrandID), 1).subscribe((res: any) => {
      this.RefundReasonList = res;
    });
  }

  private loadOrderTypes() {
    this.reportService.getAllOrderTypes(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.orderTypes = res;
    });
  }

  private loadTables(locationIds) {
    if (locationIds.length > 0) {
      this.reportService.getAllTablesByLocations(locationIds).subscribe((res: any) => {
        this.tables = res;
      });
    }
    else {
      this.tables = [];
    }
  }

  private loadtaxes() {
    this.reportService.getAlltaxesddl(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.TaxList = res;
      if (this.ReportType.type == 'salesDetails')
        this.onSelectAll('tax');
    });
  }

  private loadSubCategory() {
    this.reportService.getAllSubCategory(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.subCatList = res;
    });
  }
  private loadSuppliers() {
    this.reportService.getSuppliers(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.Suppliers = res;
    });
  }
  private loadItems() {
    if(this.ReportType.type === 'stockMovement'){
      this.reportService.getItems(parseInt(this.localStorageService.getSelectedBrand().BrandID), (this.selectedSupplierIds && this.selectedSupplierIds.length > 0) ? this.selectedSupplierIds : null, (this.selectedStockTypeIds && this.selectedStockTypeIds.length > 0) ? this.selectedStockTypeIds : null).subscribe((res: any) => {
        this.selectedItemIds = [];
        this.StockItems = res;
      });
    }
  }

  public onSelectAll(control) {
    if (control == 'user') {
      const _selectedUsers = this.UserList.map(item => item.id);
      this.reportFilterForm.get('user').patchValue(_selectedUsers);
    }
    else if (control == 'paymentType') {
      const _selectedPayments = this.paymentTypes.map(item => item.id);
      this.reportFilterForm.get('paymentType').patchValue(_selectedPayments);
    }
    else if (control == 'terminal') {
      const _selectedTerminals = this.TerminalList.map(item => item.id);
      this.reportFilterForm.get('terminal').patchValue(_selectedTerminals);
    }
    else if (control == 'location') {
      const _selectedLocations = this.LocationsList.map(item => item.locationID);
      console.log(_selectedLocations);
      this.reportFilterForm.get('location').patchValue(_selectedLocations);
    }
    else if (control == 'tax') {
      const _selectedTaxes = this.TaxList.map(item => item.id);
      this.reportFilterForm.get('tax').patchValue(_selectedTaxes);
    }
    else if (control == 'subCat') {
      const _selectedSubCat = this.subCatList.map(item => item.subCategoryID);
      this.reportFilterForm.get('subCat').patchValue(_selectedSubCat);
    }
    else if (control == 'partner') {
      const _selectedPartner = this.PartnerList.map(item => item.id);
      this.reportFilterForm.get('partner').patchValue(_selectedPartner);
    }
    else if (control == 'orderType') {
      const _selectedOrderTypes = this.orderTypes.map(item => item.orderTypeID);
      console.log(_selectedOrderTypes);
      this.reportFilterForm.get('orderType').patchValue(_selectedOrderTypes);
    }
    else if (control == 'tables') {
      const _selectedTables = this.tables.map(item => item.tableID);
      console.log(_selectedTables);
      this.reportFilterForm.get('tables').patchValue(_selectedTables);
    }
  }
  showSingleDate(): boolean {
    if (this.ReportType.type === 'stock')
      this.enableSingleDate = true;

    return this.enableSingleDate;
  }

  closeSelect(select: NgSelectComponent) { select.close(); }
}
