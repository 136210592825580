import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { DeletedItems, InventoryLog } from 'src/app/_models/GeneratedReport';

import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import { ExcelService } from '../../../_services/excel.service';
import { Location } from '@angular/common';
import { DeletedItemReportService } from '../../../_services/deleteditems.report.service';
import { DataOperationsService } from '../../../_services/data-operations.service';
import { SortColumn } from '../../../_directives/sortable.directive';


@Component({
  selector: 'app-deleteditems',
  templateUrl: './deleteditems.component.html',
  styleUrls: ['./deleteditems.component.css']
})
export class DeleteditemsComponent implements OnInit {
  data$: Observable<DeletedItems[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;

  submit: boolean;
  selectedReport: any;
  selctedLang = 'en';

  sortColumn: SortColumn;
  sortDirection: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: DeletedItemReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private printService: PrintService,
    private alertService: AlertService, private excelService: ExcelService, private dataOperationService: DataOperationsService) {

    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.loading$ = service.loading$;
    this.submit = false;


    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    //this.selectedReport = { "generatedReportID": 270, "reportID": 1, "reportName": "Inventory Log", "dateRange": "2021-06-01 - 2021-07-31", "locations": "Al-Rayan,Al Salam,Test Location,Test Create,Test-MARN-1,test-22,test-2,raf,test3,testlocation,cafeimran,cafeimran,test12,test12,Ahsan's Location,Ahsan's Location,Location 44,Location 44,Location 44,test1,Malir,Karachi,Gulshan e iqbal,MarnNow,MarnNow,MarnNow,AL-haris,Test Location,Muhammad Shahzad,Muhammad Shahzad,Test 2,loc 32,loc 43,loc 44,loc 45,loc 46,test loc n48,new 49,test 50,test 50,nescafe,nescafeee", "generatedBy": "MARN POS", "generatedOn": "2021-08-02T23:10:41", "filters": "locations:1,69,70,71,89,90,91,92,93,96,97,98,100,101,102,103,104,105,106,109,113,114,115,116,117,118,120,123,124,125,126,143,144,145,146,147,152,154,155,157,158,159| users:null| reference:null", "dataURL": "https://marnpossastorage.blob.core.windows.net/marn-reports-v2/2021-08-02/log-20210802T201041654", "brandID": 1, "rowVersion": 20210802201041, "statusID": 1 };

    console.log(this.selectedReport);
    //this.getReportData(this.selectedReport.dataURL);




  }

  DeletedItems: any;
  ngOnInit() {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
    }, 1000);
  }



  getReportData(dataURL) {
    this.service.getDeletedItemReport(dataURL);

    this.data$ = this.service.allData$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;



    //this.printService.onDataReady();
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();

  }

  export(type, data): void {



    if (data !== undefined && data !== null && data !== "") {
      this.excelService.exportAsExcelFile(this.getExportData(data), 'Export-Void-Deleted-Items', type);
    }
    else
      (data !== undefined) ? (data.length === 0 ? this.alertService.error("Data is not available.") : "") : "";
  }


  private getExportData(data) {
    let selected: DeletedItems[];
    selected = [];

    selected = this.dataOperationService.prepareExportData(data.source._value, this.sortColumn, this.sortDirection) as DeletedItems[];

    selected.forEach(d => {
      if (d.selected) {
        selected.push(d);
        d.Details.forEach(dt => {
          selected.push({
            OfflineUniqueID: d.OfflineUniqueID,
            OrderID:d.OrderID,
            OrderNo: d.OrderNo,
            BusinessDate: d.BusinessDate,
            Location: d.Location,
            Type: 'Item',
            Details: null,
            TotalAmount: (dt.Quantity * dt.PriceWithVAT).toString(),
            TaxAmount: dt.Tax,
            GrandTotal: (dt.Quantity * dt.PriceWithVAT).toString(),
            selected: false,
            VoidItems: dt.Name,
            VoidArabicItems: dt.AltName
          }
          );
        });
      }
    });
    if (selected.length == 0) {
      data.source._value.forEach(d => {
          selected.push(d);
          d.Details.forEach(dt => {
            selected.push({
              OfflineUniqueID: d.OfflineUniqueID,
              OrderID:d.OrderID,
              OrderNo: d.OrderNo,
              BusinessDate: d.BusinessDate,
              Location: d.Location,
              Type: 'Item',
              Details: null,
              TotalAmount: (dt.Quantity * dt.PriceWithVAT).toString(),
              TaxAmount: dt.Tax,
              GrandTotal: (dt.Quantity * dt.PriceWithVAT).toString(),
              selected: false,
              VoidItems: dt.Name,
              VoidArabicItems: dt.AltName
            }
            );
          });
      });
    }
    return selected;
  }

  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

}
