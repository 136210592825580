import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, UserProfile } from '../_models/User';
import { ChatBotService } from './chat-bot.service';


declare const Intercom: any;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient,
    private chatBotService: ChatBotService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username, password, isRestaurant) {
    return this.http.post<any>(`api/Authenticate`, { username, password, isRestaurant })
      .pipe(map(user => {
        console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {

    //Intercom('shutdown');
    const user = this.currentUserValue;
    var _this = this;
    if (user && user.token) {
      // remove user from local storage and set current user to null
      this.http.get(`api/Authenticate/logout`).toPromise().then(function () {
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('selectedBrand');
        sessionStorage.removeItem('selectedLocation');
        //_this.chatBotService.RemoveChatBot();
        //this.currentUserSubject.next(null);
      })
    }
  }

  invalidateUser() {
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

  }

  forgotPassword(username, isRestaurant) {
    return this.http.post<any>(`api/Authenticate/forgotpassword`, { username, isRestaurant })
      .pipe(map(user => {
        console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  resetPassword(Password, token, usertype) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    let url = usertype == 1 ? "api/Authenticate/subuser/resetpassword" : "api/Authenticate/user/resetpassword";
    return this.http.post<any>(url, { Password }, { headers: reqHeader })
      .pipe(map(user => {
        console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  TempLogin(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'AuthData': data
    });
    return this.http.post<any>(`api/Authenticate/TempLogin`, {}, { headers: headers })
      .pipe(map(user => {
        console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }
  verifyPassword(Userid, Password, token, usertype) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    let url = usertype == 1 ? "api/Authenticate/subUser/verifypassword" : "api/Authenticate/user/verifypassword";
    return this.http.post<any>(url, { Userid, Password }, { headers: reqHeader })
      .pipe(map(user => {
        return user;
      }));
  }
  public getUserByID(userId, usertype) {
    let url = usertype == 1 ? `api/Authenticate/subUser/GetUserDetails/${userId}` : `api/Authenticate/user/GetUserDetails/${userId}`;
    return this.http.get<UserProfile>(url);
  }
  public updateUserProfile(data, usertype) {
    let url = usertype == 1 ? `api/Authenticate/subUser/SaveUserProfile` : `api/Authenticate/user/SaveUserProfile`;
    return this.http.put(url, data)
      .pipe(map(res => {
        return res;
      }));
  }
} 
