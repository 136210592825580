<form class="form">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()" [ngClass]="selctedLang == 'en' ? 'me-5' : 'ms-5'">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{'Order#' | translate }} {{orderData?.order.orderNo}}
    </h4>
    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn m-0 mx-2" (click)="openPDF()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{'Export PDF' | translate}}
    </button>
    <button [disabled]="loading" class="btn btn-link" (click)="openLink()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{'Link' | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container preview-order">
      <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4" [ngClass]="{'mt-5': ((orderData?.order.pinpad === false) && (orderData?.order.orderStatus !== 'Paid' && orderData?.order.orderStatus !== 'Partial Refund' && orderData?.order.orderStatus !== 'Refund'))}">
          <div class="row text-center mt-4 mb-2" *ngIf="orderData?.order.orderStatus === 'Paid' || orderData?.order.orderStatus === 'Partial Refund' || orderData?.order.orderStatus === 'Refund'">
            <h5 *ngIf="ZATCAConfig">
              {{invoiceLabel}}
            </h5>
          </div>
          <div class="row mb-3" *ngIf="orderData?.order.pinpad === true">
            <div class="col-lg-12 d-flex justify-content-center">
              <h5>
                <span class="badge bg-secondary">{{'PinPad' | translate}}</span>
              </h5>
            </div>
          </div>
          <div class="order-refrence" *ngIf="orderData?.order.orderRefrenceID !=null">
            <div class="row">
              <div class="col-lg-6"></div>
              <div class="col-lg-6">
                <label class="subtitle text-right">  {{'Refrence #'  | translate}} </label>
                <label class="btn-order-refrence">   {{orderData?.order.orderRefrenceID }}</label>
              </div>
            </div>
          </div>
          <div class="row order-header">
            <div class="col-lg-6">
              <label>{{'Location' | translate }}</label>
              <span>
                {{orderData?.order.location}}
              </span>
            </div>
            <div class="col-lg-6">
              <div class="move-to-end">
                <label>{{'Order Type' | translate }}</label>
                <span class="move-to-end">
                  {{orderData?.order.orderType}}
                </span>
              </div>
            </div>
          </div>
          <div class="row pt-5">
            <div class="col-lg-6">
              <label class="">{{'Order Taker' | translate}}</label>
              <label class="subtitle">{{orderData?.order.orderTaker}} </label>
            </div>
            <div class="col-lg-6">
              <div class="move-to-end">
                <label class="subtitle h-25 move-to-end">   {{orderData?.order.createdDate  | date:'shortTime'}} </label>
                <label class="subtitle move-to-end">   {{orderData?.order.createdDate | date}}</label>
              </div>
            </div>
          </div>
          <div class="order-status line-seprator">
            <div class="row">
              <div class="col-lg-6">
                <label>{{'Order State' | translate}}</label>
                <label class="subtitle pb-4">
                  <ngb-highlight *ngIf="orderData?.order.orderState === 'Waiting'" class="btn btn-sm btn-inactive m-0" result="{{orderData?.order.orderState | translate}}">
                  </ngb-highlight>
                  <ngb-highlight *ngIf="orderData?.order.orderState === 'In Kitchen' || orderData?.order.orderState === 'Preparing'" class="btn btn-sm btn-badge-yellow m-0" result="{{orderData?.order.orderState | translate}}">
                  </ngb-highlight>
                  <ngb-highlight *ngIf="orderData?.order.orderState === 'Ready'" class="btn btn-sm btn-badge-blue m-0" result="{{orderData?.order.orderState | translate}}">
                  </ngb-highlight>
                  <ngb-highlight *ngIf="orderData?.order.orderState === 'Served'" class="btn btn-sm btn-active m-0" result="{{orderData?.order.orderState | translate}}">
                  </ngb-highlight>
                  <ngb-highlight *ngIf="orderData?.order.orderState === 'On the Way'" class="btn btn-sm btn-active m-0" result="{{orderData?.order.orderState | translate}}">
                  </ngb-highlight>
                  <ngb-highlight *ngIf="orderData?.order.orderState === 'Delivered'" class="btn btn-sm btn-active m-0" result="{{orderData?.order.orderState | translate}}">
                  </ngb-highlight>
                </label>
              </div>
              <div class="col-lg-6">
                <div class="move-to-end">
                  <label> {{'Order Status' | translate}} </label>
                  <label class="pb-4 move-to-end">
                    <ngb-highlight *ngIf="orderData?.order.orderStatus === 'Hold'" class="btn btn-sm btn-badge-blue m-0" result="{{orderData?.order.orderStatus | translate}}">
                    </ngb-highlight>
                    <ngb-highlight *ngIf="orderData?.order.orderStatus === 'Partial Refund' || orderData?.order.orderStatus === 'Refund' || orderData?.order.orderStatus === 'Void' || orderData?.order.orderStatus === 'Refunded Order'" class="btn btn-sm btn-inactive m-0" result="{{orderData?.order.orderStatus | translate}}">
                    </ngb-highlight>
                    <ngb-highlight *ngIf="orderData?.order.orderStatus === 'Pending'" class="btn btn-sm btn-badge-yellow m-0" result="{{orderData?.order.orderStatus | translate}}">
                    </ngb-highlight>
                    <ngb-highlight *ngIf="orderData?.order.orderStatus === 'Paid'" class="btn btn-sm btn-active m-0" result="{{orderData?.order.orderStatus | translate}}">
                    </ngb-highlight>
                    <!--<ngb-highlight *ngIf="orderData?.order.orderStatus !== 'Hold' &&  orderData?.order.orderStatus !== 'Partial Refund' && orderData?.order.orderStatus !== 'Refund' && orderData?.order.orderStatus !== 'OrderCancelled' && orderData?.order.orderStatus !== 'Refunded Order' && orderData?.order.orderStatus !== 'Pending' && orderData?.order.orderStatus !== 'OrderCheckOut' " class="btn btn-sm btn-active" result="{{orderData?.order.orderStatus | translate}}">
          </ngb-highlight>-->
                  </label>
                </div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-lg-6">
                <label>{{'ETA' | translate}}</label>
                <label class="pb-4">
                  <ngb-highlight class="btn btn-sm btn-badge-grey m-0" result="{{orderData?.order.eta | translate}}" [term]=""></ngb-highlight>
                </label>
              </div>
              <div class="col-lg-6">
                <div class="move-to-end">
                  <label> {{'Promised Time' | translate}} </label>
                  <label class="pb-4 move-to-end">
                    <ngb-highlight class="btn btn-sm btn-badge-grey m-0" result="{{orderData?.order.promisedTime | translate}}" [term]=""></ngb-highlight>
                  </label>
                </div>
              </div>
            </div>
            <div class="row pt-3" *ngIf="checkZatcaCompliance && (orderData?.order.orderStatus === 'Paid' || orderData?.order.orderStatus === 'Partial Refund' || orderData?.order.orderStatus === 'Refund')">
              <div class="col-lg-8">
                <label>{{'ZATCA Status' | translate}}</label>
                <label class="subtitle pb-4">
                  <ngb-highlight *ngIf="orderData?.order.reportedToZATCA === 'Rejected'" class="btn btn-sm btn-inactive m-0" result="{{orderData?.order.reportedToZATCA | translate}}">
                  </ngb-highlight>
                  <ngb-highlight *ngIf="orderData?.order.reportedToZATCA === 'Warning'" class="btn btn-sm btn-badge-yellow m-0" result="{{ 'Accepted with Warning' | translate}}">
                  </ngb-highlight>
                  <ngb-highlight *ngIf="orderData?.order.reportedToZATCA === 'Pending'" class="btn btn-sm btn-badge-blue m-0" result="{{orderData?.order.reportedToZATCA | translate}}">
                  </ngb-highlight>
                  <ngb-highlight *ngIf="orderData?.order.reportedToZATCA === 'Reported'" class="btn btn-sm btn-active m-0" result="{{orderData?.order.reportedToZATCA | translate}}">
                  </ngb-highlight>
                </label>
              </div>
              <div class="col-lg-4">
                <div class="move-to-end">
                  <label> {{'' | translate}} </label>
                  <button class="resend-button move-to-end" *ngIf="(orderData?.order.reportedToZATCA === 'Rejected' || orderData?.order.reportedToZATCA === 'Pending')" (click)="PostInvoiceToZATCA()">Re-Send</button>
                </div>
              </div>
            </div>
            <div class="row mb-0 pt-1" *ngIf="checkZatcaCompliance && orderData?.order.reportedToZATCA === 'Warning'">
              <div class="col-lg-12">
                <div class="alert alert-warning d-flex flex-row mb-0">
                  <div class="me-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <g style="mix-blend-mode:multiply">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#FEF6D7" />
                      </g>
                      <path d="M19.9998 17V21M19.9998 25H20.0098M18.6151 11.8917L10.3902 26.0983C9.93398 26.8863 9.70588 27.2803 9.73959 27.6037C9.769 27.8857 9.91677 28.142 10.1461 28.3088C10.4091 28.5 10.8643 28.5 11.7749 28.5H28.2246C29.1352 28.5 29.5904 28.5 29.8534 28.3088C30.0827 28.142 30.2305 27.8857 30.2599 27.6037C30.2936 27.2803 30.0655 26.8863 29.6093 26.0983L21.3844 11.8917C20.9299 11.1065 20.7026 10.714 20.4061 10.5821C20.1474 10.4671 19.8521 10.4671 19.5935 10.5821C19.2969 10.714 19.0696 11.1065 18.6151 11.8917Z" stroke="#CE7700" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div>
                    <label class="bold text-black mb-0">{{'ZATCA Warning' | translate }}</label>
                    <p class="text-black font-12 mb-0">
                      {{'Your invoice is approved with warning(s). Please check your fatoora portal to check the warning(s)' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-0 pt-1" *ngIf="checkZatcaCompliance && orderData?.order.reportedToZATCA === 'Rejected'">
              <div class="col-lg-12">
                <div class="alert alert-danger d-flex flex-row mb-0">
                  <div class="me-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <g style="mix-blend-mode:multiply">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#FEE3E1" />
                      </g>
                      <g clip-path="url(#clip0_11972_332)">
                        <path d="M20 16V20M20 24H20.01M30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20Z" stroke="#CE281C" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_11972_332">
                          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <label class="bold text-black mb-0">{{'ZATCA Rejection' | translate }}</label>
                    <p class="text-black font-12 mb-0">
                      {{'Please check your Fatoora portal to see the rejection reason(s)' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-items">
            <div class="row order-header m-0" *hasPermission="['Orders','Order Refund']">
              <!--<div class="col-lg-12">
                <div class="d-flex">
                  <div class="p-2">  <label class=""> {{'Cart Items' | translate}} </label></div>
                  <div class="ml-auto p-2 text-right">
                    <button class="refund">{{'Refund' | translate}}</button>
                  </div>
                </div>
              </div>-->

              <div class="col-8"> <span> {{'Cart Items' | translate}} </span></div>
              <div class="col-4 text-right">
              <button class="refund" *ngIf="orderData?.order.orderStatus !== 'Hold' && orderData?.order.orderStatus !== 'Void' && fullRefunded !== true && !orderData?.order.partnerName && itemCount != compItemCount" (click)="refundOrder()">{{'Refund' | translate}}</button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 ">
                <div class="d-flex plr-0" *ngFor="let item of  orderData?.item" style=" margin-bottom: 4px;" [ngStyle]="{'background': item.isRefunded ? '#FFE7E6':item.isComplimentary ? '#FAECCF' : 'unset' }">
                  <div class="p-2">
                    <!--<img class="item-image" [src]="item.image" />-->
                    <img [src]="item.image" *ngIf="(item.image !== '' && item.image !== null)" class="item-image" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                    <img *ngIf="(item.image === '' || item.image === null)" src="https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG" class="item-image" alt="" />
                  </div>
                  <div class="p-2" style="width: 100%;">
                    <div style="padding-left:15px">
                      <div class="row items plr-0">
                        <div class="col-8">  <label class="item-title text-truncate">{{item.quantity}}x {{selctedLang=='en'?item.name:item.altName}}</label></div>
                        <div class="col-4 text-right">
                          <!--<label *ngIf="industry==2" class="item-price" [ngClass]="{'text-decoration-line-through': item.isComplimentary}">{{item.price*item.quantity | number: '1.1-2'}} </label>-->
                          <!--<label *ngIf="industry==1" class="item-price" [ngClass]="{'text-decoration-line-through': item.isComplimentary}">{{((item.price*item.quantity) + sumModifiers(item.itemDetails)) | number: '1.1-2'}}    </label>-->
                          <!-- Diaa asked to show modifier price with item in both industries
                                https://marnworkspace.slack.com/archives/D01BY2W8N31/p1674638126577529-->
                          <label class="item-price" [ngClass]="{'text-decoration-line-through': item.isComplimentary}">{{((item.priceWithQty) + sumModifiers(item.itemDetails)) | number: '1.2-2'}}    </label>
                          <img *ngIf="item.isComplimentary" src="../../../assets/images/icons/v2/complementary_Badge.svg" />
                        </div>
                        <div class="col-8" *ngIf="item.priceType === 3">
                          <label> ({{item.unitAmount | number: '1.2-2'}} {{item.unitName }})x {{(item.priceWithoutVAT*1.15) | number: '1.2-6'}}</label>
                        </div>
                      </div>
                      <div *ngIf="item.itemDetails !=null">
                        <div class="row modifiers" *ngFor="let mod of  item.itemDetails">
                          <div class="col-8">  <label class="modifier-title">{{mod.quantity}}x {{selctedLang=='en'?mod.name:mod.altName}}</label></div>
                          <div class="col-4 text-right">
                            <label class="modifier-price">+{{(mod.price) | number: '1.2-2'}} </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="p-2" style="background:#FAECCF" *ngIf="item.note !== null && item.note !== ''">
                      <span style="color:#A16C00">{{'Item Note' | translate }} : {{item.note}} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-customer line-seprator" *ngIf="orderData?.customer !=null">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="License">{{'Customer'  | translate }} (#{{orderData?.customer.customerID}})</label>
                  <div class="input-group mb-3">
                    <div class="input-group-append">
                      <span class="input-group-text" id="inputGroup-sizing-default mobile-code">+966</span>
                    </div>
                    <input type="url" class="form-control mobile-number" [disabled]=true aria-label="Default" value="{{orderData?.customer.mobile.replace('+966', '')}}" aria-describedby="inputGroup-sizing-default">
                  </div>
                </div>
                <div class="form-group">
                  <input class="form-control" [disabled]=true value={{orderData?.customer.customerName}} />
                </div>
                <div class="form-group" *ngIf="orderData?.customer.address">
                  <input class="form-control" [disabled]=true value={{orderData?.customer.address}} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="form-group" *ngIf="orderData?.order.comments !== null && orderData?.order.comments !== ''">
              <label for="Notes">{{'Notes'  | translate }} </label>
              <textarea class="form-control" rows="3" cols="3" style="height:unset" [disabled]=true value={{orderData?.order.comments}}> </textarea>
            </div>
          </div>
          <div class="order-payments line-seprator">
            <div *ngIf="orderData?.order.orderStatus !== 'Hold' && orderData?.order.orderStatus !== 'Pending' && orderData?.order.orderStatus !== 'Void'">
              <!--Discount section-->
              <div class="row order-discount px-1 mx-0 gy-2" *ngIf="!orderData?.discounts && orderData?.order.discountAmount > 0">
                <div class="col-lg-8">
                  <span class="btn btn-sm btn-active m-0">
                    {{'Discount' | translate}}
                  </span>
                </div>
                <div class="col-lg-8">  <label class=""> {{'Custom Discount' | translate}} ({{((orderData?.order.discountAmount/(orderData?.order.subTotal)) * 100) | number: '1.0-2'}}%)</label> </div>
                <div class="col-lg-4 text-right">  <label> {{orderData?.order.discountAmount | number: '1.2-2'}}</label> </div>
              </div>
              <div class="row order-discount px-1 mx-0 gy-2" *ngIf="orderData?.discounts?.length>0">
                <div class="col-lg-8">
                  <span class="btn btn-sm btn-active m-0">
                    {{'Discount' | translate}}
                  </span>
                </div>
                <ng-container *ngFor="let discount of orderData?.discounts">
                  <div class="col-lg-8">  <label class=""> {{selctedLang == 'en' ?discount.name:(discount.arabicName ? discount.arabicName:discount.name) | translate}} ({{discount.percentage | number: '1.0-2'}}%)</label> </div>
                  <div class="col-lg-4 text-right">  <label> {{discount.amount | number: '1.2-2'}}</label> </div>
                </ng-container>
              </div>
              <!--Discount section-->

              <div class="row pt-2">
                <div class="col-lg-8"> <label class=""> {{'Subtotal' | translate}} </label></div>
                <div class="col-lg-4 text-right"> <label> {{orderData?.order.subTotal | number: '1.2-2'}}</label></div>
              </div>
              <!--Tax section-->
              <div class="row pt-2" *ngIf="!orderData?.taxes">
                <div class="col-lg-8"> <label class=""> {{'VAT' | translate}} ({{VAT}}%) </label> </div>
                <div class="col-lg-4 text-right">   <label> {{orderData?.order.taxAmount | number: '1.2-2'}}</label></div>
              </div>
              <ng-container *ngIf="orderData?.taxes && orderData?.taxes?.length>0">
                <div class="row pt-2" *ngFor="let tax of orderData?.taxes">
                  <div class="col-lg-8"> <label class=""> {{selctedLang == 'en' ?tax.name:(tax.arabicName ? tax.arabicName:tax.name) | translate}} ({{tax.percentage}}%) </label> </div>
                  <div class="col-lg-4 text-right">   <label> {{tax.amount | number: '1.2-2'}}</label></div>
                </div>
              </ng-container>
              <!--Tax section-->

              <div class="row order-refund" *ngIf="orderData?.order.refundedAmount > 0">
                <div class="col-lg-8">   <label class="subtitle"> {{'Refunded Amount' | translate}}</label> </div>
                <div class="col-lg-4 text-right">  <label> {{orderData?.order.refundedAmount | number: '1.2-2'}}</label> </div>
              </div>
              <div class="row pt-4">
                <div class="col-lg-8"> <label class="large-title"> {{'Total' | translate }} </label></div>
                <div class="col-lg-4 text-right">  <label class="large-title"> {{orderData?.order.grandTotal | number: '1.2-2'}}</label> </div>
              </div>
            </div>
          </div>
          <h2 class="payment-header" *ngIf="orderData?.order.orderStatus !== 'Hold'  && orderData?.order.orderStatus !== 'Pending' && orderData?.order.orderStatus !== 'Void'">
            {{'PAYMENT' | translate}}
          </h2>
          <div class="order-payment-detail" *ngIf="orderData?.order.orderStatus !== 'Hold'  && orderData?.order.orderStatus !== 'Pending' && orderData?.order.orderStatus !== 'Void'">
            <div class="row" *ngFor="let payment of  orderData?.payment">
              <div class="col-lg-8">
                <div class="payment-type">
                  <img *ngIf="payment.paymentMode === 'Mada'" src="../assets/images/Mada_ic.svg"
                       loading="lazy" width="40" alt="">
                  <img *ngIf="payment.paymentMode === 'Visa'" src="../assets/images/Visa_ic.svg"
                       loading="lazy" width="33" alt="">
                  <img *ngIf="payment.paymentMode === 'MasterCard'" src="../assets/images/Mastercard_ic.svg"
                       loading="lazy" width="33" height="24" alt="">
                  <img *ngIf="payment.paymentMode === 'AmericanExpress'" src="../assets/images/Amex_ic.svg"
                       loading="lazy" width="42" alt="">
                  <img *ngIf="payment.paymentMode === 'StcPay'" src="../assets/images/STCPay_ic.svg"
                       loading="lazy" width="37" alt="">
                </div>
                <div class="payment-type" *ngIf="payment.paymentMode === 'BankTransfer' || payment.paymentMode === 'Aggregator' || payment.paymentMode === 'Other' || payment.paymentMode === 'Wallet' || payment.paymentMode === 'Cash' || payment.paymentMode === 'Multi' || payment.paymentMode === 'Card'">
                  {{payment.paymentMode | translate}}
                </div>
              </div>
              <div class="col-lg-4 text-right">
                <label> {{payment.amount | number: '1.2-2'}}</label>
              </div>
            </div>
          </div>
          <div class="order-barcode line-seprator" *ngIf="ZATCAConfig">
            <div class="d-flex justify-content-center">
              <qrcode [qrdata]="QRCode" [width]="300" [errorCorrectionLevel]="'L'" [allowEmptyString]="true"></qrcode>
            </div>
          </div>
        </div>
        <div class="col-sm-4"></div>
      </div>
    </div>
  </div>
</form>
<app-order-pdf *ngIf="export === true" [orderData]="orderData" (html)="receive($event)"></app-order-pdf>
<app-order-detail-pdf *ngIf="exportDetailed === true" [orderData]="orderData" (html)="receive($event)"></app-order-detail-pdf>
