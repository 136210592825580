<div class="modal-header">
  <h6 class="modal-title fw-bold">{{'Crop Image' | translate }}</h6>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form m-0">

  <div class="row px-2">
    <div class="form-group col-lg-12">
      <div class="status-activebar row shadow align-items-center">
        <div class="col-1 px-0">
          <svg width="50" height="45" viewBox="0 0 60 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g style="mix-blend-mode:multiply">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M27 53.6668C41.7276 53.6668 53.6666 41.7278 53.6666 27.0002C53.6666 12.2726 41.7276 0.333496 27 0.333496C12.2724 0.333496 0.333313 12.2726 0.333313 27.0002C0.333313 41.7278 12.2724 53.6668 27 53.6668Z" fill="#FAF5FF" />
            </g>
            <g style="mix-blend-mode:multiply">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M27 47C38.0457 47 47 38.0457 47 27C47 15.9543 38.0457 7 27 7C15.9543 7 7 15.9543 7 27C7 38.0457 15.9543 47 27 47Z" fill="#F3E8FF" />
            </g>
            <g clip-path="url(#clip0_11064_338)">
              <path d="M27 31V27M27 23H27.01M37 27C37 32.5228 32.5228 37 27 37C21.4772 37 17 32.5228 17 27C17 21.4772 21.4772 17 27 17C32.5228 17 37 21.4772 37 27Z" stroke="#9333EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_11064_338">
                <rect width="24" height="24" fill="white" transform="translate(15 15)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="col-11">
          <p class="inactive mb-0 text-transform-none"> {{'Align the product to be centered in the image' | translate}}.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row avatar-upload">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
      <!--<input type="file" #fileInput (change)="fileChangeEvent($event)" />-->
      @if(showCropper){
      <image-cropper [imageChangedEvent]="imageChangedEvent"
                     [maintainAspectRatio]="false"
                     [aspectRatio]="4 / 4"
                     format="png"
                     (imageCropped)="imageCropped($event)"
                     (imageLoaded)="imageLoaded($event)"
                     (cropperReady)="cropperReady()"
                     (loadImageFailed)="loadImageFailed()"
                     [cropperStaticWidth]="250"
                     [cropperStaticHeight]="250"
                     [allowMoveImage]="true"
                     [(transform)]="transform"
                     [disabled]="false"
                     [hideResizeSquares]="true"
                     [(cropper)]="cropper"></image-cropper>
      }
      @else{
      <div class="card w-100 avatar-preview" style="height:20rem;">
        </div>
        }
        <!--<img [src]="croppedImage" />-->

      </div>
    </div>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
      <div class="form-group my-2">
        <div style="display: flex; gap: 4px; align-items: center;">
          <span><img src="assets/images/image.svg" (click)="(reSize-0.1 >= minZoom && reSize-0.1 <= maxZoom) && sliderChange(reSize-0.1)" /></span>
          <input type="range" id="rangeInput" class="w-100"  [min]="minZoom" [max]="maxZoom" step=".1" [disabled]="!showCropper" [(ngModel)]="reSize" (ngModelChange)="sliderChange(reSize)">
          <span><img src="assets/images/image.svg" width="45" height="45" (click)="(reSize+0.1 >= minZoom && reSize+0.1 <= maxZoom) && sliderChange(reSize+0.1)"/></span>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer mt-0">
  <button class="btn btn-primary cutom-button custom-create-btn float-end" (click)="save()">
    {{'Save' | translate }}
  </button>
</div>
