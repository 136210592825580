<section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async) || WaitTillFetched}">

  <div class="tile-body p-0 table-responsive text-nowrap">
    <table class="table table-striped ">
      <thead>
        <tr class="table-header">
          <th sortable="location" (sort)="onSort($event)">{{'Location' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="orderCount" (sort)="onSort($event)">{{'Orders' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="sales" (sort)="onSort($event)">{{'Sales' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="salesAvg" (sort)="onSort($event)">{{'Order Avg' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="inventoryAlert" (sort)="onSort($event)">{{'Inventory' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="voidOrdersCount" (sort)="onSort($event)">{{'Void' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="refundOrdersCount" (sort)="onSort($event)">{{'Refunds' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="discountOrdersCount" (sort)="onSort($event)">{{'Discounts' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="lastOrder" (sort)="onSort($event)">{{'Last Order' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data$ | async ">
          <td sortable="location">
            <label class="fw-bold move-to-start">
              {{item.location }}
            </label>
            <br />
            <small class="move-to-start">
              {{item.city }}
              <span *ngIf="item.locationStatusID !== 1" class="badge btn-inactive px-2 py-1">{{'Inactive' | translate}}</span>
            </small>
          </td>
          <td sortable="orderCount">
            <label class="fw-bold">
              {{item.orderCount }}
            </label>
            @if(item.orderCountPercentage !== 0){
            <div class="growth">
              <img *ngIf="item.orderCountPercentage > 0" src="/assets/images/icons/profit-icon.png" class="h15 m-0 d-inline"
                   alt="icon">
              <img *ngIf="item.orderCountPercentage < 0" src="/assets/images/icons/loss-icon.png" class="h15 m-0 d-inline"
                   alt="icon">
              {{item.orderCountPercentage | number:'1.2-2'}}%
              <img src="../../../assets/images/Info.svg" class="m-0 d-inline" placement="top" [ngbTooltip]="OrderToolTip" tooltipClass="location-summary-tooltip" />
              <ng-template #OrderToolTip>
                <div class="card rounded-4">
                  <div class="card-header rounded-4" style="background-color:white">
                    <div class="row align-items-center">
                      <div class="col">
                        <label class="fw-bold font-15"> {{'Orders' | translate}}</label>
                        @if(item.orderCountPercentage>0){
                        <ngb-highlight class="btn btn-sm btn-active paid move-to-end growth px-2 m-0" result="{{item.orderCountPercentage | number:'1.2-2'}}{{'%'}}">
                        </ngb-highlight>
                        <img src="/assets/images/icons/profit-icon.png" class="h15 m-0 d-inline px-1 my-2 move-to-end" alt="icon" />
                        }
                        @else{

                        <ngb-highlight class="btn btn-sm btn-inactive refund paid move-to-end growth px-2 m-0" result="{{item.orderCountPercentage | number:'1.2-2'}}{{'%'}}">
                        </ngb-highlight>
                        <img src="/assets/images/icons/loss-icon.png" class="h15 m-0 d-inline px-1 my-2 move-to-end" alt="icon" />
                        }
                      </div>
                    </div>

                    <hr class="my-1" />

                    <div class="card-body p-0 px-1">
                      <div class="row align-items-center py-1">
                        <div class="col">
                          <div class="order-badge series-1"></div>
                          <span class="series-1-value">  {{item.orderCount}}  </span>
                          &emsp;
                          <label class="series-1-text move-to-end">  {{Period.fromDate | date:'dd MMM, yyy'}} - {{Period.toDate | date:'dd MMM, yyy'}} </label>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col">
                          <div class="order-badge series-2"></div>
                          <span class="series-2-value">  {{item.lastPeriodOrderCount}}  </span>
                          
                          <label class="series-2-text move-to-end" [ngClass]="selectedLang == 'en' ? 'ps-3' : 'pe-3'">   {{Period.lastFromDate | date:'dd MMM, yyy'}} - {{Period.lastToDate | date:'dd MMM, yyy'}}  </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </ng-template>
            </div>
            }
          </td>
          <td sortable="sales">
            <label class="fw-bold">
              {{item.sales | number:'1.2-2'}}
            </label>
            @if(item.salesPercentage !== 0){
            <div class="growth">
              <img *ngIf="item.salesPercentage > 0" src="/assets/images/icons/profit-icon.png" class="h15 m-0 d-inline"
                   alt="icon">
              <img *ngIf="item.salesPercentage < 0" src="/assets/images/icons/loss-icon.png" class="h15 m-0 d-inline"
                   alt="icon">
              {{item.salesPercentage | number:'1.2-2'}}%
              <img src="../../../assets/images/Info.svg" class="m-0 d-inline" placement="top" [ngbTooltip]="SalesToolTip" tooltipClass="location-summary-tooltip" />
              <ng-template #SalesToolTip>
                <div class="card rounded-4">
                  <div class="card-header rounded-4" style="background-color:white">
                    <div class="row align-items-center">
                      <div class="col">
                        <label class="fw-bold font-15"> {{'Sales' | translate}}</label>
                        @if(item.salesPercentage>0){
                        <ngb-highlight class="btn btn-sm btn-active paid move-to-end growth px-2 m-0" result="{{item.salesPercentage | number:'1.2-2'}}{{'%'}}">
                        </ngb-highlight>
                        <img src="/assets/images/icons/profit-icon.png" class="h15 m-0 d-inline px-1 my-2 move-to-end" alt="icon" />
                        }
                        @else{

                        <ngb-highlight class="btn btn-sm btn-inactive refund paid move-to-end growth px-2 m-0" result="{{item.salesPercentage | number:'1.2-2'}}{{'%'}}">
                        </ngb-highlight>
                        <img src="/assets/images/icons/loss-icon.png" class="h15 m-0 d-inline px-1 my-2 move-to-end" alt="icon" />
                        }
                      </div>
                    </div>

                    <hr class="my-1" />

                    <div class="card-body p-0 px-1">
                      <div class="row align-items-center py-1">
                        <div class="col">
                          <div class="order-badge series-1"></div>
                          <span class="series-1-value">  {{item.sales | number:'1.2-2'}} &nbsp;{{Currency}} </span>
                          &emsp;
                          <label class="series-1-text move-to-end">  {{Period.fromDate | date:'dd MMM, yyy'}} - {{Period.toDate | date:'dd MMM, yyy'}} </label>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col">
                          <div class="order-badge series-2"></div>
                          <span class="series-2-value">  {{item.lastPeriodSales | number:'1.2-2'}}&nbsp;{{Currency}}  </span>
                          
                          <label class="series-2-text move-to-end" [ngClass]="selectedLang == 'en' ? 'ps-3' : 'pe-3'">   {{Period.lastFromDate | date:'dd MMM, yyy'}} - {{Period.lastToDate | date:'dd MMM, yyy'}}  </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </ng-template>
            </div>
            }
          </td>
          <td sortable="salesAvg">
            <label class="fw-bold">
              {{item.salesAvg | number:'1.2-2'}}
            </label>
            @if(item.salesAvgPercentage !== 0){
            <div class="growth">
              <img *ngIf="item.salesAvgPercentage > 0" src="/assets/images/icons/profit-icon.png" class="h15 m-0 d-inline"
                   alt="icon">
              <img *ngIf="item.salesAvgPercentage < 0" src="/assets/images/icons/loss-icon.png" class="h15 m-0 d-inline"
                   alt="icon">
              {{item.salesAvgPercentage | number:'1.2-2'}}%
              <img src="../../../assets/images/Info.svg" class="m-0 d-inline" placement="top" [ngbTooltip]="SalesAvgToolTip" tooltipClass="location-summary-tooltip" />
              <ng-template #SalesAvgToolTip>
                <div class="card rounded-4">
                  <div class="card-header rounded-4" style="background-color:white">
                    <div class="row align-items-center">
                      <div class="col">
                        <label class="fw-bold font-15"> {{'Order Average' | translate}}</label>
                        @if(item.salesAvgPercentage>0){
                        <ngb-highlight class="btn btn-sm btn-active paid move-to-end growth px-2 m-0" result="{{item.salesAvgPercentage | number:'1.2-2'}}{{'%'}}">
                        </ngb-highlight>
                        <img src="/assets/images/icons/profit-icon.png" class="h15 m-0 d-inline px-1 my-2 move-to-end" alt="icon" />
                        }
                        @else{

                        <ngb-highlight class="btn btn-sm btn-inactive refund paid move-to-end growth px-2 m-0" result="{{item.salesAvgPercentage | number:'1.2-2'}}{{'%'}}">
                        </ngb-highlight>
                        <img src="/assets/images/icons/loss-icon.png" class="h15 m-0 d-inline px-1 my-2 move-to-end" alt="icon" />
                        }
                      </div>
                    </div>

                    <hr class="my-1" />

                    <div class="card-body p-0 px-1">
                      <div class="row align-items-center py-1">
                        <div class="col">
                          <div class="order-badge series-1"></div>
                          <span class="series-1-value">  {{item.salesAvg | number:'1.2-2'}}&nbsp;{{Currency}}  </span>
                          &emsp;
                          <label class="series-1-text move-to-end">  {{Period.fromDate | date:'dd MMM, yyy'}} - {{Period.toDate | date:'dd MMM, yyy'}} </label>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col">
                          <div class="order-badge series-2"></div>
                          <span class="series-2-value">  {{item.lastPeriodSalesAvg | number:'1.2-2'}}&nbsp;{{Currency}}  </span>
                          
                          <label class="series-2-text move-to-end " [ngClass]="selectedLang == 'en' ? 'ps-3' : 'pe-3'">   {{Period.lastFromDate | date:'dd MMM, yyy'}} - {{Period.lastToDate | date:'dd MMM, yyy'}}  </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </ng-template>
            </div>
            }
          </td>
          <td sortable="inventoryAlert">
            @if(item.inventoryAlert !== 0){
            <a class="fw-bold" (click)="openLocationInventory(item.locationID)">{{item.inventoryAlert }} &nbsp; {{'Alerts' | translate}}</a>
            }
            @else{
            <span>-</span>
            }
          </td>
          <td sortable="voidOrdersCount">
            <label class="fw-bold">{{item.voidOrdersCount }} ({{item.voidOrdersAmount | number:'1.2-2'}})</label>
          </td>
          <td sortable="refundOrdersCount"><label class="fw-bold">{{item.refundOrdersCount }} ({{item.refundOrdersAmount | number:'1.2-2'}})</label> </td>
          <td sortable="discountOrdersCount"><label class="fw-bold"> {{item.discountOrdersCount }} ({{item.refundOrdersAmount | number:'1.2-2'}})</label></td>
          <td sortable="lastOrder">
            @if(getTimeDiff(item.lastOrder); as day){
            <label class="fw-bold move-to-start ps-4">{{day.value}} {{day.text}}</label>
            <br />
            <small class="move-to-start ps-4">{{'Ago' | translate}} </small>
            }
            @else{
            <span>-</span>
            }
          </td>
        </tr>
      </tbody>
    </table>
  </div>


</section>
<!--<div class="row mt-10">
  <div class="col-lg-12 col-md-12  col-sm-12 col-xs-12">
    <div class="pagination-flex pt-0">
      <p class="pagination-count">
        {{'Showing' | translate }}
        <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
        <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
        <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
      </p>
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                      [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
        <ng-template ngbPaginationFirst>
          <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
        </ng-template>
        <ng-template ngbPaginationLast>
          <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
        </ng-template>
        <ng-template ngbPaginationPrevious class="page-pre">
          <img src="../../../assets/images/icons/backward.svg" />
        </ng-template>
        <ng-template ngbPaginationNext class="page-next"><img src="../../../assets/images/icons/forward.svg" /></ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>-->
