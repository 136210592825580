import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperComponent, ImageCroppedEvent, LoadedImage, ImageTransform, CropperPosition } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-selection',
  templateUrl: './image-selection.component.html',
  styleUrl: './image-selection.component.css',
  imports: [ImageCropperComponent, TranslateModule, FormsModule],
  standalone: true,
})
export class ImageSelectionComponent {

  @Input() event = null;

  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl = '';
  showCropper: boolean = false;
  imgObject = {
    safeURL: null,
    blob: null
  }
  reSize = 1;
  minZoom = 0.1;
  maxZoom = 2;
  cropper?: CropperPosition;
  transform: ImageTransform = {
    translateUnit: 'px',
    scale: 1,
    rotate: 0,
    flipH: false,
    flipV: false,
    translateH: 0,
    translateV: 0
  };
  constructor(
    private sanitizer: DomSanitizer,
    private activeModal: NgbActiveModal) {

  }

  ngOnInit() {
    this.imageChangedEvent = this.event;
    this.showCropper = true;
  }

  ngOnChanges() {
    this.imageChangedEvent = this.event;
    this.showCropper = true;
  }



  fileChangeEvent(event): void {
    this.imageChangedEvent = event;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.showCropper = true;
      };

    }
    else {
      this.showCropper = false;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.imgObject.safeURL = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.imgObject.blob = event.blob;
  }
  imageLoaded(image: LoadedImage) {

    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  sliderChange(val) {
    this.reSize = val;

    this.transform = {
      ...this.transform,
      scale: this.reSize
    };
  }


  public dismiss() {
    this.activeModal.dismiss();
  }
  save() {
    this.activeModal.close(this.imgObject);
  }
}
