<main class="views container">
  <div class="page">

    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Tax Report' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header">

            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">

              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,data$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',data$)">Excel</button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>



    <div class="page-body">

      <div class="preview-filter-data" dir="ltr">{{selectedReport.dateRange}}</div>
      <div class="preview-filter-data" dir="ltr">{{selectedReport.locations}}</div>

      <section class="tile" style="margin-top: 20px">

        <div class="tile-body p-0 table-responsive hrzScroll">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th width="5%">
                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                </th>
                <!--<th width="10%" sortable="BusinessDate" (sort)="onSort($event)">{{'Date' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>-->
                <th sortable="Location" (sort)="onSort($event)">{{'Location' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="TaxType" (sort)="onSort($event)">{{'Tax Type' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"/></th>
                <th sortable="TaxReceived" (sort)="onSort($event)">{{'Tax Received' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="TaxPaid" (sort)="onSort($event)">{{'Tax Paid' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>



              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data$ | async ">
                <td>
                  <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" />
                </td>
                <!--<td> {{item.BusinessDate | date}} </td>-->
                <td> {{item.Location }} </td>
                <td> {{item.TaxType}} </td>
                <td> {{item.TaxReceived | number:'1.2-2'}} </td>
                <td> {{item.TaxPaid | number:'1.2-2'}} </td>
              </tr>
              @if(service.taxReportTotals){
              <tr class="total-row">
                <td></td>
                <td>
                  <label class="total-title fw-bold font-15">{{ 'Total of' | translate }} <strong> {{ (total$ | async)! }}</strong> {{ 'rows' | translate }}</label>
                </td>
                <td></td>
                <td>{{service.taxReportTotals.TaxReceived | number: '1.2-2'}}</td>
                <td>{{service.taxReportTotals.TaxPaid | number: '1.2-2'}}</td>
              </tr>
              }
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-lg-12" style="margin-top:1%">
            {{'Showing' | translate }}
            <strong>{{(total$ | async)}}</strong>
            {{'results' | translate }}
          </div>
        </div>
      </section>

    </div>
  </div>
</main>
