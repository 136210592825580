<div class="group-gap mt-4">

  <div class="avatar-upload">
    <div class="avatar-edit">
      <input type='file' accept=".png, .jpg, .jpeg, .png" #fileInput (change)="onFileChange($event)" />
    </div>
    <div class="avatar-preview">
      <div *ngIf="localImagePath" id="imagePreviewlocal" [ngStyle]="{'background-image':'url(' + localImagePath +')'}" (click)="goToLink(localImagePath)" role="button">
      </div>
      <div *ngIf="!!this.pattern.test(imagepath) && localImagePath === null" id="imagePreview"
           [ngStyle]="{'background-image':'url(' + imagepath + '?refreshCache='+ breakCache+ ')'}" (click)="goToLink(imagepath)" role="button">
      </div>
    </div>
  </div>
</div>

<!-- Submit Button -->
<button type="button" class="file-upload-btn" (click)="fileInput.click()">{{'Upload Image' | translate }}</button>
