import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ModifierService } from 'src/app/_services/modifier.service';
import { UnitService } from 'src/app/_services/unit.service';
import { ModifierRecipes } from 'src/app/_models/Modifier';
import { GroupModifierService } from 'src/app/_services/groupmodifier.service';
import { maxValidator } from '../../../../CustomValidators/MaxValidator';
@Component({
  selector: 'app-addgroupmodifier',
  templateUrl: './addgroupmodifier.component.html',
  styleUrls: ['./addgroupmodifier.component.css']
})



export class AddgroupmodifierComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  ItemModifiersList = [];


  submitted = false;
  groupmodifierForm: UntypedFormGroup;
  loading = false;
  loadingModifier = false;
  ButtonText = "Save";
  selecteditemModifierIds: string[];
  selctedLang = 'en';
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private modifierService: ModifierService,
    private groupmodifierService: GroupModifierService,
    private unitService: UnitService
  ) {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.createForm();
    this.loadItemModifiers();

  }

  ngOnInit() {
    this.setSelectedModifier();

    //this.groupmodifierForm.get('multimaxEnabled')?.valueChanges.subscribe(enabled => {
    //  if (!enabled) {
    //    this.f.multimax.setValue(null); // Reset the multimax field when the switch is off
    //  }
    //});

    //this.f.multimax
    //  .setValidators([Validators.min(1), maxValidator(this.groupmodifierForm.get('max'))]);

    //this.groupmodifierForm.get('multimax').updateValueAndValidity();
    this.f.max.valueChanges.subscribe((newMaxValue) => {
      if (this.f.multimaxEnabled.value) {
        this.f.multimax.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(newMaxValue) // Update the max value dynamically
        ]);
        this.f.multimax.updateValueAndValidity();
      }
    });
  }

  get f() { return this.groupmodifierForm.controls; }

  private createForm() {

    this.groupmodifierForm = this.formBuilder.group({

      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9]*(?:[ \-,_+.;:’×“”’’!—…&()%][a-zA-Z0-9]*)*[a-zA-Z0-9]$/)]],
      alternateName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[ء-ي0-9][ء-ي0-9]*(?:[ ؛،\-_+.:×“”’’!—…&()%][ء-ي0-9]*)*[ء-ي0-9]$/)]],
      description: [''],
      statusID: [true, Validators.required],
      displayOrder: [0, [Validators.required, Validators.min(1)]],
      min: [0, Validators.required],
      max: [0, [Validators.required, Validators.min(1)]],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      groupID: 0,
      modifiers: [[], Validators.required],
      rowVersion: 0,
      multimax: [],
      multimaxEnabled: [false],  // Default: Switch is off
    });
  }

  private editForm(modifier) {

    this.f.name.setValue(modifier.name);
    this.f.alternateName.setValue(modifier.alternateName);
    this.f.description.setValue(modifier.description);

    this.f.displayOrder.setValue(modifier.displayOrder);
    this.f.min.setValue(modifier.min);
    this.f.max.setValue(modifier.max);
    this.f.rowVersion.setValue(modifier.rowVersion);
    this.f.statusID.setValue(modifier.statusID === 1 ? true : false);
    this.f.groupID.setValue(modifier.groupID);

    this.f.modifiers.setValue(modifier.modifiers);
    this.selecteditemModifierIds = modifier.modifiers.split(',').map(i => Number(i));
    this.f.multimax.setValue(modifier.multimax)
    this.f.multimaxEnabled.setValue(modifier.multimax > 0 ? true : false)

    if (this.f.multimaxEnabled.value)
      this.f.multimax.setValidators(Validators.required);

  }

  private loadItemModifiers() {
    this.groupmodifierService.getModifiers(this.f.brandID.value).subscribe((res: any) => {
      this.ItemModifiersList = res.filter(data => data.statusID == 1);
    });
  }


  setSelectedModifier() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingModifier = true;
      this.f.groupID.setValue(this.SelectedID);
      this.groupmodifierService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingModifier = false;
      });
    }
  }
  onMultimaxToggle() {
    const isEnabled = this.f.multimaxEnabled.value;
    if (!isEnabled) {

      this.f.multimax.setValue(null);
      this.f.multimax.clearValidators();
    }
    else {
      this.f.multimax.setValidators([Validators.required, Validators.min(1), Validators.max(this.f.max.value)]);
    }
    this.f.multimax.updateValueAndValidity();
  }



  onSubmit() {
    this.f.name.setValue(this.f.name.value.trim());
    this.f.alternateName.setValue(this.f.alternateName.value.trim());

    this.groupmodifierForm.markAllAsTouched();
    if (this.f.min.value > this.f.max.value) {
      this.alertService.error("Minimum should be less than Maximum.");
      this.loading = false;
    } else if (this.selecteditemModifierIds.length < this.f.max.value) {
      this.alertService.error("Maximum should be less than or equal to Modifiers Items.");
      this.loading = false;
    } else {



      // this.groupmodifierForm.markAllAsTouched();
      this.submitted = true;
      // reset alerts on submits
      this.alertService.clear();
      this.f.modifiers.setValue(this.selecteditemModifierIds.toString());

      console.log(this.groupmodifierForm.value)

      if (this.groupmodifierForm.invalid) { return; }
      this.loading = true;



      // this.f.modifierRecipes.setValue(this.arrRecipes);
      this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);

      if (parseInt(this.f.groupID.value) === 0) {

        //Insert modifier
        console.log(JSON.stringify(this.groupmodifierForm.value));
        this.groupmodifierService.insert(this.groupmodifierForm.value).subscribe(data => {
          this.alertService.success("Group Modifier has been created");
          this.loading = false;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        });

      } else {
        //Update modifier
        this.groupmodifierService.update(this.groupmodifierForm.value).subscribe(data => {
          this.alertService.success("Group Modifier has been Updated");
          this.loading = false;
          this.SelectedID = 0;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
          this.activeModal.dismiss();
        });
      }

    }
  }


  close() {
    this.activeModal.dismiss();
  }

  findIndexToUpdate(newItem) {
    return newItem.quantity === this;
  }
}
